import { Col, Pagination, Row } from "antd";
import Cookies from "js-cookie";
import React from "react";

import conditional from "../utils/conditional";
import { addCommas } from "../utils/Utils";

export default function Paginate(props) {
    const {
        save = "",
        bytes_count = 0,
        paginate = { current: 1, pagesize: 20, offset: 0 },
        setPaginate = () => { },
        show_pagination = true,
        pageSizeOptions= [10, 20, 50],
        precomponent = null,
        component = null,
        showSizeChanger = true,
        count = 0,
        hide_single = false,
        max_offset = 0
    } = props;

    const drawShowing = () => {
        let end = paginate.current * paginate.pagesize;
        let start = (paginate.current * paginate.pagesize) - (paginate.pagesize - 1);
        if (end > count) {
            end = count;
        }
        if (count === 0) {
            return "0 ";
        }
        return addCommas(start) + " to " + addCommas(end);
    }

    const pageChange = (_page, _limit) => {
        let _offset = (_page - 1) * _limit;
        if (_limit !== paginate.pagesize) {
            _offset = 0;
            _page = 1;
            if (save) {
                Cookies.set(save, _limit, {expires: 365});
            }
        }
        setPaginate({
            pagesize: _limit,
            offset: _offset,
            current: _page
        })
    }

    if ((hide_single && paginate.pagesize > count)) {
        return (<></>);
    }

    return (
        <>
            <div className="paginate" style={{}}>
                <Row>
                    <Col span={12}>
                        <div style={{ "padding": "10px 20px" }}>
                            {(precomponent && precomponent)}
                            Showing <em style={{ "fontWeight": "500" }}>{drawShowing()}</em> of <em style={{ "fontWeight": "500" }}>{addCommas(count)}</em> results
                            <conditional.true value={bytes_count !== 0}>
                                <span style={{"paddingLeft": "5px"}}>({(bytes_count / 1024).toFixed(0)} KB)</span>
                            </conditional.true>
                            {(component && component)}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ "paddingRight": "20px", "textAlign": "right" }}>
                            <div className="shim" /><div className="shim" />
                            <conditional.true value={(show_pagination)} >
                                <Pagination pageSizeOptions={pageSizeOptions} size="small" showSizeChanger={showSizeChanger} current={paginate.current} pageSize={paginate.pagesize}  total={(max_offset && max_offset < count) ? max_offset : count}  onChange={(_page, _limit) => pageChange(_page, _limit)} />
                            </conditional.true>
                            <div className="shim" /><div className="shim" />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}