import { CloseOutlined, FullscreenOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Carousel, Image, Modal, Space } from "antd";
import React, { useRef, useState } from "react";

import moreImages from "../../media/More_Images_v3.png"
import nocover from "../../media/no-cover.png";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";

export default function TitleImage(props) {
    const {
        cover_image_key = "",
        isbn = "",
        eisbn = "",
        has_interiors = [],
        cover_image_cache = 0,
        highlight = false,
        title = "",
        subtitle = "",
        authors = [],
        parseAuthors = () => {},
        onMouseDown = false
    } = props;
    const [visible, setVisible] = useState(false);
    const [interiors, setInteriors] = useState([]);
    const [opened, setOpened] = useState(false);
    const [hasCover, setHasCover] = useState(1);

    const image_path = "https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache + (cover_image_key && "&b2b=" + cover_image_key);

    const [c, setC] = useState(0);
    const media = useRef()
    const nav = useRef()

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const getInteriors = () => {
        if (has_interiors) {
            apiCall("title/getItem", { isbn: isbn }, (_status, _result) => {
                if (_status) {
                    setInteriors(_result.interiors);
                    setOpened(true);
                }
            })
        }
    }

    const open = () => {
        setVisible(true);
        if (!opened) {
            getInteriors();
        }
    }

    const getInteriorPath = (_int, _cb = "", _b2b = "") => {
        _int = _int.toString();
        let _path = "https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&imgp=";
        if (_b2b) {
            _path += _int
            _path += "&b2b=" + _b2b;
        } else {
            _path += _int
        }
        _path = _path + "&cb=" + _cb;
        return _path;
    }

    const close = () => {
        setVisible(false)
    }

    function drawImage() {
        if (highlight) {
            return {
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px"
            }
        }
        return {
            borderStyle: "none",
        }
    }

    const drawFallbackImage = () => {
        return (
            <div style={{ "backgroundImage": `url(${nocover})`, "backgroundSize": "contain" }}>
                <div className="card-fallback-summary">
                    <div><strong>{title}</strong></div>
                    <div className="shim" /><div className="shim" />
                    {(subtitle && <>
                        <div style={{ "lineHeight": "14px" }}><small>{subtitle}</small></div>
                        <div className="shim" /><div className="shim" />
                    </>)}
                    <div>{parseAuthors(authors, "#000")}</div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Modal destroyOnClose maskStyle={{ "background-color": "rgb(0,0,0,.8)" }} width={"100%"} onCancel={() => close()} zIndex={9999} visible={visible}
                modalRender={() => <>
                    <div style={{ "position": "absolute", "top": "0px", "right": "0px", "marginTop": "-80px" }}>
                        <Button type="text"><CloseOutlined style={{ "color": "#fff", "cursor": "pointer" }} /></Button>
                    </div>
                    <div>
                        <Carousel effect="fade" afterChange={(e) => setC(e)} touchMove={true} draggable={true} asNavFor={nav.current} ref={media} dots={false} style={{ "height": "calc(100vh - 150px)" }}>
                            {(hasCover &&
                                <div onClick={() => close()} style={{ "maxHeight": "100px" }} key={"main"}>
                                    <div style={{ "textAlign": "center" }}>
                                        <div style={{ "width": "100%" }}>
                                            <div style={{ "float": "left", "marginTop": "10%" }}>
                                                {((interiors.length > 0) && <div className="galleryButton" onClick={(e) => { e.stopPropagation(); media.current.prev() }}><LeftCircleOutlined /></div>)}
                                            </div>
                                            <div style={{ "float": "right", "marginTop": "10%", "marginRight": "30px" }}>
                                                {((interiors.length > 0) && <div className="galleryButton" onClick={(e) => { e.stopPropagation(); media.current.next() }}><RightCircleOutlined /></div>)}
                                            </div>
                                            <div style={{ "color": "#fff", "margin": "0 auto", "textAlign": "center", "height": "20px", "position": "relative", "zIndex": ((c === 0)) ? "9999" : "0" }} >
                                                {((c === 0) &&
                                                    <div style={{ "display": "inline", "cursor": "pointer" }} onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        window.open(image_path, "_blank");
                                                    }}>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="shim" /><div className="shim" />
                                            <img key={"init"} onClick={(e) => { e.stopPropagation(); }} style={{ "margin": "0 auto", "maxHeight": "calc(100vh - 250px)" }} src={image_path} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {interiors.map((interior, index) => {
                                return (<div onClick={() => close()} style={{ "maxHeight": "100px" }} key={index}>
                                    <div style={{ "color": "#fff", "margin": "0 auto", "textAlign": "center", "height": "20px", "position": "relative", "zIndex": ((c === (index + 1))) ? "9999" : "0" }} >
                                        {(c === (index + 1)) &&
                                            <div style={{ "display": "inline", "cursor": "pointer" }} onClick={(e) => {
                                                e.stopPropagation(); 
                                                window.open(getInteriorPath(interior.key, interior.cb, interior.b2b));
                                            }}></div>
                                        }
                                    </div>
                                    <div className="shim" /><div className="shim" />
                                    <div style={{ "textAlign": "center" }}>
                                        <div style={{ "float": "left", "marginTop": "10%" }}>
                                            {((interiors.length > 0) && <div className="galleryButton" onClick={(e) => { e.stopPropagation(); media.current.prev() }}><LeftCircleOutlined /></div>)}
                                        </div>
                                        <div style={{ "float": "right", "marginTop": "10%", "marginRight": "30px" }}>
                                            {((interiors.length > 0) && <div className="galleryButton" onClick={(e) => { e.stopPropagation(); media.current.next() }}><RightCircleOutlined /></div>)}
                                        </div>
                                        <img onClick={(e) => e.stopPropagation()} style={{ "margin": "0 auto", "maxHeight": "calc(100vh - 250px)" }} src={getInteriorPath(interior.key, interior.cb, interior.b2b)} />
                                    </div>
                                </div>)
                            })}
                        </Carousel>
                        <conditional.true value={interiors.length > 0}>
                            <div style={{ "margin": "0 auto", "width": ((interiors.length + hasCover) * 50) + "px", "marginTop": "-50px" }}>
                                <Carousel touchMove={true} draggable={true} ref={nav} slidesToShow={interiors.length + hasCover} dots={false} style={{ "height": "30px", "width": ((interiors.length + hasCover) * 50) + "px" }}>
                                    {(hasCover &&
                                        <div onClick={() => close()} style={{ "maxHeight": "40px" }} key={"main"}>
                                            <img key={"init"} onClick={(e) => {
                                                media.current.goTo(0);
                                                e.stopPropagation()
                                            }} style={{ "margin": "0 auto", "maxWidth": "40px", "cursor": "pointer" }} src={image_path} />
                                        </div>
                                    )}
                                    {interiors.map((interior, index) => {
                                        return (<div onClick={() => close()} style={{ "maxHeight": "40px" }} key={index}>
                                            <img onClick={(e) => {
                                                media.current.goTo(index + 1);
                                                e.stopPropagation()
                                            }} style={{ "margin": "0 auto", "maxWidth": "40px", "cursor": "pointer" }} src={getInteriorPath(interior.key, interior.cb, interior.b2b)} />
                                        </div>)
                                    })}
                                </Carousel>
                            </div>
                        </conditional.true>
                    </div>
                </>}
            ></Modal >
            <conditional.true value={hasCover}>
                <Image
                    onMouseDown={(e) => {
                        if(onMouseDown && e.button > 0){
                            onMouseDown(e); 
                        }
                    }}
                    preview={{
                        visible: false, mask: <><Space size={0} style={{ "textAlign": "center" }} direction="vertical"><small><FullscreenOutlined style={{ "fontSize": "25px" }} /></small>
                            <small style={{ "fontSize": "20px" }}>See {(has_interiors) ? " all images" : "cover image"}</small>
                            <div className="shim" />
                            <div
                                style={{ "position": "absolute", "bottom": "5px", "left": "35px" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(image_path, "_blank");
                                }}></div>
                        </Space></>
                    }}
                    style={drawImage()}
                    onError={() => setHasCover(0)}
                    fallback={nocover}
                    className="shadow-large"
                    width={140}
                    src={(eisbn) ? image_path : ""}
                    onClick={() => (eisbn) ? open(true) : () => { }}
                />
            </conditional.true>
            <conditional.true value={!hasCover}>
                {drawFallbackImage()}
            </conditional.true>
            <conditional.true value={(has_interiors)}>
                <div style={{ "backgroundColor": "#1b1b1b" }}>
                    <div style={{
                        "top": "0px",
                        "position": "absolute",
                        "color": "#fff",
                        "padding": "3px 1px",
                        "lineHeight": "12px"
                    }}>
                        <Image
                            style={
                                {
                                    "width": 16,
                                    "height": 16,
                                    "opacity": "0.8",
                                    "imageRendering": "pixelated"
                                }
                            }
                            src={moreImages}
                            preview={false} />
                    </div>
                </div>
            </conditional.true>
        </>
    )
}