import { Col, Divider, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from "../utils/Api";
import Conditions from "./Conditions";
import ItemHistory from "./ItemHistory";
import PreviousRender from "./title/PreviousRender";
import Title from "./title/Title";
import TitleExtras from "./title/TitleExtras";

// Layout for components based on view
// Add marker for where to draw subcomponents

export default function ItemLayouts(props) {
    const { 
        data = false,
        vendor = {}, 
        cardDisplayClassName = "cardgrid",
        display, 
        isbn = "", 
        drawComponent = () => { },
        catalogueID = 0,
        selectMultiple = false,
        toggleMultiSelect = () => {},
        effectiveDates = [],
        suppliers = [],
        agencies = [],
        categories = [],
        selectedRowKeys = [],
    } = props;
    const [dataSet, setDataSet] = useState(data);
    const [highlight, setHighlight] = useState(data.highlight);
    // if there is no data, fetch it. 
    const loadData = () => {
        if (dataSet) {
            return;
        }
        let args = {
            isbn: isbn,
        }
        if(catalogueID !== ""){
            args.list_def_id = catalogueID
        }
        apiCall("title/getItem", args, (_status, _result) => {
            if (_status) {
                setDataSet(_result);
            }
        })
    }

    useEffect(loadData, [])

    function drawBorder(){
        return {
            borderStyle: "solid",
            borderColor: "#ff0099",
            borderWidth: "3px",
            marginTop: "-10px",
            backgroundColor: "#ff0099",
        }
    }

    function topRowSequenceNum(){
        let text = ""
        if(highlight === true){
            text = "#" + data.sequence_num + " - Highlighted";
            return (
                <Divider style={drawBorder()} orientation="left" plain><small className="c" style={{ "fontWeight": "400", "fontSize": "16px" }}>{text}</small></Divider> 
            )
        }else{
            text = "#" + data.sequence_num;
            return (
                <Divider style={{"marginTop": "0px"}} orientation="left" plain><small className="c" style={{ "fontWeight": "400", "fontSize": "16px" }}>{text}</small></Divider>
            )
        }
    }

    if (!dataSet) {
        return (<Spin spinning style={{ "height": "320px" }}>&nbsp;</Spin>);
    }

    // eslint-disable-next-line default-case
    switch (display) {
        case "single":
            return (<div>
                <div style={{ "maxWidth": "570px" }}><Title vendor={vendor} drawComponent={drawComponent} isbn={(dataSet) ? data.isbn : isbn} data={(dataSet) ? dataSet : {}} display="summary" /></div>
                </div>
            )
        case "calendar": 
            return (
                <div key={(dataSet) ? dataSet.isbn : isbn}>
                    <Row gutter={18}>
                        <Col flex={"auto"}>
                            <div style={{ "maxWidth": "570px" }}><Title vendor={vendor} drawComponent={drawComponent} isbn={(dataSet) ? data.isbn : isbn} data={(dataSet) ? dataSet : {}} display="summary" /></div>
                        </Col>
                        <Col flex={"608px"}>
                            <PreviousRender isbn={(dataSet) ? dataSet.isbn : isbn} />
                        </Col>
                    </Row>
                </div>
            )
        case "cards":
            return (
                <div key={dataSet[0].isbn}>
                    <div className={cardDisplayClassName}>
                        {dataSet.map((item, index) => {
                            return (
                                <div key={dataSet.isbn}>
                                    <Title
                                        selectedRowKeys={selectedRowKeys}
                                        cardDisplayClassName={cardDisplayClassName}
                                        vendor={vendor} 
                                        drawComponent={drawComponent} 
                                        data={item} 
                                        display="card" 
                                        selectMultiple={selectMultiple} 
                                        toggleMultiSelect={toggleMultiSelect}
                                        highlight={highlight}
                                        setHighlight={setHighlight}
                                        effectiveDates={effectiveDates}
                                        agencies={agencies}
                                        suppliers={suppliers}
                                        categories={categories}
                                        catalogueID={catalogueID}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            )
        case "details2":
            return (
                <div key={(dataSet) ? dataSet.isbn : isbn}>
                    {highlight != null && topRowSequenceNum()}
                    <Row gutter={18}>
                        <Col flex={"auto"}>
                            <div style={{ "maxWidth": "570px" }}>
                                <Title
                                    selectedRowKeys={selectedRowKeys}
                                    drawComponent={drawComponent} 
                                    isbn={(dataSet) ? data.isbn : isbn} 
                                    data={(dataSet) ? dataSet : {}} 
                                    display="summary"
                                    vendor={vendor}
                                    catalogueID={catalogueID}
                                    highlight={highlight}
                                    setHighlight={setHighlight}
                                    effectiveDates={effectiveDates}
                                    agencies={agencies}
                                    suppliers={suppliers}
                                    categories={categories}
                                />
                            </div>
                        </Col>
                        <Col style={{"fontFamily": "Averta", "maxWidth": "608px"}} flex={"608px"}>
                            <Conditions 
                                sideColumn={true}
                                conditions={dataSet.sales ? dataSet.sales : dataSet.conditions} 
                                data={dataSet} 
                                isbn={(dataSet) ? dataSet.isbn : isbn} 
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TitleExtras 
                                data={dataSet} 
                                isbn={(dataSet) ? dataSet.isbn : isbn} 
                                conditions={dataSet.sales ? dataSet.sales : dataSet.conditions}
                                dataSet={dataSet}
                            />
                        </Col>
                    </Row>
                </div>
            )
        case "details":
            return (
                <div key={(dataSet) ? dataSet.isbn : isbn}>
                    <Row gutter={18}>
                        <Col span={12}>
                            <Title vendor={vendor} drawComponent={drawComponent} isbn={(dataSet) ? "" : isbn} data={(dataSet) ? dataSet : {}} display="summary" />
                        </Col>
                        <Col span={12}>
                            <ItemHistory drawComponent={drawComponent} data={dataSet} isbn={(dataSet) ? data.isbn : isbn} />
                        </Col>
                    </Row>
                </div>
            )
        case "previous":
        case "next":
            return (
                <div key={dataSet.isbn}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Title vendor={vendor} drawComponent={drawComponent} data={dataSet} display="summary" />
                            <div className="shim" /><div className="shim" /><div className="shim" />
                            <ItemHistory drawComponent={drawComponent} isbn={dataSet.isbn} />
                        </Col>
                    </Row>
                    <Divider dashed />
                </div>
            )
    }
    return (<>Item Renderer</>);
}