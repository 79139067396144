import { Button, Divider, message, Space, Typography } from "antd";

import { apiCall } from "../../../utils/Api";
import MultiSelectListEditTable from "./MultiSelectListEditTable";

export default function MultiSelectHighlightToggle(props){
    const {
        catalogueID,
        close = () => {},
        highlight = false,
        setResults = () => {},
        data = [], 
        results = [],
    } = props

    const {
        isbns_full_data = [],
        selectedRowKeys = [],
    } = data

    function highlightStatusChange(){
        apiCall((highlight) ?  "titlelist/highlight" : "titlelist/unhighlight", {list_id: catalogueID, isbns_json: JSON.stringify(selectedRowKeys)}, (_status, _result) => {
            if(_status){
                message.success("Highlights changes were saved successfully.");
                let _rows = results.rows.map((row) => {
                    if(selectedRowKeys.find(rowkey => rowkey === row.isbn)){
                        if(highlight && !row.highlight){
                            row.highlight = !row.highlight;
                        }else if(!highlight && row.highlight){
                            row.highlight = !row.highlight;
                        }
                    }
                    return row;
                })
                setResults({...results, "rows": _rows});
            }
        });
        close()
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>{highlight ? "Highlight" : "Unhighlight"}</Typography.Title>
                <MultiSelectListEditTable isbns_full_data={isbns_full_data} show_highlight={false} />
                <Divider style={{ "margin": "15px 0px" }} />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Cancel</Button>
                        <Button 
                            type="primary" 
                            onClick={highlightStatusChange}
                            >{highlight ? "Highlight Choices" : "Unhighlight Choices"}
                        </Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>        
    )
}