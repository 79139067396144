
import React, { useState } from "react";
import { message, Button, Modal, Input, Typography, Divider, Space } from "antd";
import { apiCall } from "../utils/Api";
import { CopyOutlined } from '@ant-design/icons';
import { addCommas, pluralize } from "../utils/Utils";
export default function CopyISBNs(props) {

    const { searchFilters = {} } = props;
    const [isbns, setIsbns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [copied, setCopied] = useState(false);

    const fetchISBNs = () => {
        let _filters = { ...searchFilters }
        _filters["isbn_only"] = true;
        _filters["l"] = 10000;
        setLoading(true);
        apiCall("browse/get", _filters, (_status, _result) => {
            if (_status) {
                setIsbns(_result.rows);
                setVisible(true);
                setLoading(false);
            } else {
                message.error(_result.error);
            }
        });
    }

    const copyToClipboard = (_isbns) => {
        if (!navigator.clipboard) {
            message.error("Can't copy");
            return;
        }

        navigator.clipboard.writeText(_isbns);
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        }, 2000);
    }

    const close = () => {
        setVisible(false);
        setIsbns([]);
    }

    const drawModal = () => {
        return (
            <>
                <Modal destroyOnClose footer={null} onCancel={() => close()} visible={visible}>
                    <Typography.Title level={5}>Copy {(isbns.length) ? <>{addCommas(isbns.length)}{pluralize(isbns.length," ISBN", "s")}</> : ""}</Typography.Title>
                    <Divider style={{"marginTop" : "15px", "marginBottom" : "10px"}} />
                    <small style={{"opacity" : "0.6"}}>Only the first 10,000 ISBNs can be copied.</small>
                    <div className="shim" /><div className="shim" />
                    <Input.TextArea style={{"fontFamily" : "ApercuMono"}} rows={8} defaultValue={isbns.join("\n")}></Input.TextArea>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => close()}>Cancel</Button>
                            <Button onClick={() => copyToClipboard(isbns.join("\n"))} type={(copied) ? "primary" : "default"}> 
                            {(copied) ? <>{(isbns.length) ? <>{addCommas(isbns.length)}{pluralize(isbns.length," ISBN", "s")}</> : ""} copied to clipboard!</> : <>Copy {(isbns.length) ? <>{addCommas(isbns.length)}{pluralize(isbns.length," ISBN", "s")}</> : ""} &nbsp;to clipboard</>}
                            </Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Modal>
            </>
        )
    }

    return (
        <>
            {drawModal()}
            <Button size="small" loading={loading} onClick={() => fetchISBNs()} type="primary"><small><CopyOutlined />&nbsp;Copy ISBNs</small></Button>
        </>
    )
}