import { FolderFilled, FolderOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Radio, Space, Typography } from "antd";
import React, { useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { disabledColor, highlightColor } from "../../utils/Utils";

export default function ListFolder(props) {

    const { 
        list_id = "", 
        folder = "", 
        icon = false,
        list = [],
    } = props;
    // 12 chars
    const [selectedFolder, setSelectedFolder] = useState(folder);
    const [visible, setVisible] = useState(false)
    const [folders, setFolders] = useState([]);
    const [newFolder, setNewFolder] = useState("");

    const fetchFolders = () => {
        if (folders.length > 0) {
            setVisible(!visible);
        }
        apiCall("titlelist/getFolders", {}, (_status, _result) => {
            if (_status) {
                setFolders(_result);
                setVisible(true);
            }
        })
    }

    const updateItem = (_list) => {
        props.getLists();
    }

    const saveChangeFolder = (use_new_folder = false) => {
        let send_folder = use_new_folder ? newFolder : selectedFolder;
        setVisible(false);
        apiCall("titlelist/changeFolder", { list_id: list_id, folder: send_folder }, (_status, _result) => {
            if (_status) {
                setSelectedFolder(use_new_folder ? newFolder : selectedFolder);
                updateItem(_result);
                setVisible(false);
                setNewFolder("");
            }
        })
    }

    const updateFolderList = (e) => {
        let temp_folders = [...folders];
        temp_folders.push(newFolder);
        setFolders(temp_folders);
        saveChangeFolder(true);
    }

    return (<>
        <Modal
            width={500}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => saveChangeFolder()}
            okText="Save"
            onVisibleChange={(e) => setVisible(false)}
        >
            <Typography.Title style={{ "lineHeight": "17px" }} level={5}>Assign {list.name} to a folder</Typography.Title>
            <Divider className='c' style={{ "margin": "10px 0px" }}/>
            <Radio.Group onChange={(e) => setSelectedFolder(e.target.value)} value={selectedFolder}>
                <Space direction="vertical">
                    {folders.map((itm) => {
                        return (<Radio value={itm}>{itm}</Radio>)
                    })}
                </Space>
            </Radio.Group>
            <Divider className='c' dashed style={{ "margin": "10px 0px" }}>or</Divider>
            <Typography.Title style={{ "lineHeight": "14px" }} level={5}><small>Create a new folder</small></Typography.Title>
            {/* <Input onChange={(e) => {updateFolderList(e)}} /> */}
            <Space>
                <Input
                    defaultValue=""
                    style={{
                        width: 300,
                    }}
                    onChange={(e) => {setNewFolder(e.target.value)}}
                />
                <Button onClick={(e) => updateFolderList(e)}>Add new folder</Button>
            </Space>
        </Modal>

        <conditional.true value={(icon)}>
            <Button onClick={() => fetchFolders()} size="small" type="link" icon={(!visible) ? <FolderOutlined style={{ "color": disabledColor }} /> : <FolderFilled style={{ "color": highlightColor }} />} />
        </conditional.true>
        <conditional.true value={(!icon)}>
            <Button onClick={() => fetchFolders()} size="small"><small>{selectedFolder}</small></Button>
        </conditional.true>
    </>)

{/* <Typography.Title style={{ "lineHeight": "17px" }} level={5}>Assign {list.name} to a folder</Typography.Title>
            <Divider className='c' style={{ "margin": "10px 0px" }}/>
            <Radio.Group onChange={(e) => setNewFolder(e.target.value)} defaultValue={selectedFolder} value={newFolder}>
                <Space direction="vertical">
                    {folders.map((itm) => {
                        return (<Radio value={itm}>{itm}</Radio>)
                    })}
                    <Radio value={"new"}>
                        New Folder: 
                        <Input
                            style={{
                                width: 100,
                                marginLeft: 10,
                            }}
                        />
                    </Radio>
                </Space>
            </Radio.Group> 
             <Divider className='c' dashed style={{ "margin": "10px 0px" }}>or</Divider>
            <Typography.Title style={{ "lineHeight": "14px" }} level={5}><small>Create a new folder</small></Typography.Title>
            <Input onChange={(e) => {updateFolderList(e)}} /> */}

// return (<>
//     <Modal
//         width={350}
//         // footer={false}
//         visible={visible}
//         onCancel={() => setVisible(false)}
//         onOk={() => saveChangeFolder()}
//         okText="Save"
//         onVisibleChange={(e) => setVisible(false)}
//     >
//         <Typography.Title style={{ "lineHeight": "17px" }} level={5}>Choose a folder</Typography.Title>
//         <Divider className='c' style={{ "margin": "10px 0px" }}/>
//         <Radio.Group onChange={(e) => console.log(e.target.value)} defaultValue={selectedFolder} value={newFolder}>
//             {/* <Radio.Group onChange={(e) => setNewFolder(e.target.value)} value={selectedFolder}> */}
//             <Space direction="vertical">
//                 {folders.map((itm) => {
//                     return (<Radio value={itm}>{itm}</Radio>)
//                 })}
//             </Space>
//         </Radio.Group>
//         <Divider className='c' dashed style={{ "margin": "10px 0px" }}>or</Divider>
//         <Typography.Title style={{ "lineHeight": "14px" }} level={5}><small>Create a new folder</small></Typography.Title>
//         <Input onChange={(e) => {setNewFolder(e.target.value)}} />
//     </Modal>

//     <conditional.true value={(icon)}>
//         <Button onClick={() => fetchFolders()} size="small" type="link" icon={(!visible) ? <FolderOutlined style={{ "color": disabledColor }} /> : <FolderFilled style={{ "color": highlightColor }} />} />
//     </conditional.true>
//     <conditional.true value={(!icon)}>
//         <Button onClick={() => fetchFolders()} size="small"><small>{selectedFolder}</small></Button>
//     </conditional.true>
// </>)
}