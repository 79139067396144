import React from "react";
import { Space, Card, Layout, Typography, Divider, Input, Button, Table } from "antd";
import { PlusOutlined } from '@ant-design/icons';
export default function URLShortcuts() {

    return (
        <>
            <Layout style={{ "backgroundColor": "#fff" }}>
                <Layout.Content style={{ "borderLeft": "1px solid #eee", "padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={4}>URL Shortcuts</Typography.Title>
                        <Divider />
                        <Typography.Paragraph type="secondary">Create and share more consumer friendly URLs for pages and links on your site. Type your desired shortcut in the <strong>Keyword</strong> field, paste the URL you want that shortcut to link to in the <strong>Original URL field</strong>, then click <strong>Create</strong>. The <strong>New URL</strong> field will have a user friendly link that you can share. Click the new URL and copy it, or simply click the <strong>Share</strong> button to copy the link to your clipboard.</Typography.Paragraph>
                        <Typography.Paragraph type="secondary"><strong>Note:</strong> Keywords should not contain spaces or symbols <strong>( @ # $ % ^ &amp; * )</strong></Typography.Paragraph>
                        <Card style={{ "maxWidth": "500px" }} >
                            <Typography.Title level={5}>Your Domain: staging.bookmanager.com/test000/</Typography.Title>
                            <br />
                            <Space size="large">
                                <Input placeholder="Keyword"></Input>
                                <PlusOutlined />
                                <Input placeholder="Original URL"></Input>
                                <Button type="primary">Create</Button>
                            </Space>
                        </Card>
                        <Divider />
                        <Table size="small" pagination={false}
                            columns={[
                                { title: 'Share', dataIndex: 'copy', key: 'copy', width: 80 },
                                { title: 'Keyword', dataIndex: 'keyword', key: 'keyword', width: 140, ellipsis: true },
                                { title: 'Original URL', dataIndex: 'original_url', key: 'original_url', ellipsis: true },
                                { title: 'New URL', dataIndex: 'new_url', key: 'new_url', ellipsis: true },
                                { dataIndex: 'delete', key: 'delete', width: 80 },
                            ]}
                            dataSource={[
                                { key: '1', delete: <Button type="primary" danger size="small"><small>Delete</small></Button>, keyword: 'urlshortcutpath', original_url: "https://staging.bookmanager.com/test000/?q=h.siteedit.urlshortcuts", new_url: "https://staging.bookmanager.com/test000/urlshortcutpath", copy: <Button type="primary" size="small"><small>Copy</small></Button> },
                                { key: '1', delete: <Button type="primary" danger size="small"><small>Delete</small></Button>, keyword: 'urlshortcutpath', original_url: "https://staging.bookmanager.com/test000/?q=h.siteedit.urlshortcuts", new_url: "https://staging.bookmanager.com/test000/urlshortcutpath", copy: <Button type="primary" size="small"><small>Copy</small></Button> }
                            ]}
                        >
                        </Table>
                        <Divider />
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}