import { CloseOutlined, EyeInvisibleOutlined, GlobalOutlined, TeamOutlined, UserAddOutlined, UserOutlined, EyeOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Divider, Image, Row, Space, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from 'react';

import { apiCall, apiDownload } from '../../utils/Api';
import conditional from "../../utils/conditional";
import Html from "../../utils/Html";
import { addCommas, bestsellerColor, disabledColor, parseAuthors, parseAuthorsPlain, ucfirst } from "../../utils/Utils";
import Bookmark from '../Bookmark';
import BusinessContactInfo from '../BusinessContactInfo';
import ItemLayouts from "../ItemLayouts";
import Overlay from "../Overlay";
import Renderer from "../Renderer";
import Slider from "../Slider";
import SliderItem from "../SliderItem";
import ListAccountShare from "./ListAccountShare";
import ListArchive from "./ListArchive";
import ListCommentManagement from "./ListCommentManagement";
import ListCreateEdit from "./ListCreateEdit";
import ListCreateExcel from "./ListCreateExcel";
import ListCreatePdf from "./ListCreatePdf";
import ListDelete from "./ListDelete";
import ListFav from "./ListFav";
import ListFolder from "./ListFolder";
import ListNotes from "./ListNotes";
import ListReportCard from './ListReportCard';
import ListsCopy from "./ListsCopy";
import ListShare from "./ListShare";
import ListTrash from "./ListTrash";
import Shared from './Shared';
import ListQuickPick from './ListQuickPick';


export const onlyMe = (_is_private) => {
    if(!_is_private){return(<></>)}
    return (<div><Tag style={{ "fontSize": "10px", "fontWeight": "normal", "lineHeight": "15px", "padding": "0 5px" }} color="#c08be3">Only visible to me</Tag></div>)
}

export default function ListItem(props) {
    const {
        is_company = false,
        is_catalogue = false,
        getCatalogues = () => { },
        drawComponent = () => { },
        results = {},
        setResults = () => { },
        data = {},
        selectedRowKeys = [],
        toggleMultiSelect = () => { },
        presetSANLists = [],
        scroll = false
    } = props;

    const {
        list_id = "",
        actual_id = 0,
        is_dynamic = false,
        owner_name = "",
        has_image = false,
        category = "",
        effective_type = "",
        effective_string = "",
        note = "",
        note_posted = "",
        name = "",
        date_updated = null,
        date_created = null,
        date_start = null,
        date_posted = null,
        date_expired = null,
        description = "",
        image_cache_key = false,
        folder = "",
        isbns = [],
        isbns_count = 0,
        site_content = false,
        shared_status = "none",
        stores_shared_count = 0,
        user_name = "Not provided",
        can_edit = false,
        has_linked_pdf = false,
        can_delete = false,
        is_private = false,
        created_by = "",
        last_edited_by = "",
    } = data;

    const [showAccounts, setShowAccounts] = useState(false);
    const [accountsString, setAccountsString] = useState("");
    const [shared, setShared] = useState({})
    const [loading, setLoading] = useState(false);

    const vendor = data.vendor !== null ? data.vendor : { acct: "", name: "", id: 0 };
    const agency = data.agency !== null ? data.agency : { acct: "", name: "", id: 0 };

    const ref = useRef(); 

    const linkOut = (_id) => {
        window.open("/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }

    const scrollIntoViewWithOffset = (selector, offset) => {
        if(!scroll){
            return; 
        }
        window.scrollTo({
            behavior: 'smooth',
            top:
                selector.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset,
        })
    }

    useEffect(() => {

        scrollIntoViewWithOffset(ref.current, 280) 
      
    }, [])
    

    const ListImage = (props) => {
        const { img = {} } = props;
        const [imgError, setImageError] = useState(false);

        let image_path = "https://cdn1.bookmanager.com/i/slider_23.php?b=" + img.eisbn + "&cb=" + img.cover_image_cache;
        if (img?.cover_image_key) {
            image_path = image_path + "&b2b=" + img.cover_image_key;
        }

        return (
            <div style={{ "minWidth": "110px" }}>
                {(imgError && <>
                    <div style={{ "height": "150px", "width": "110px" }} className="card-fallback-small">
                        <div style={{ "fontSize": "12px", "whiteSpace": "normal", "maxWidth": "100px" }}>{img.title}</div>
                        <div className="shim" /><div className="shim" />
                        <div style={{ "fontSize": "13px", "whiteSpace": "normal", "maxWidth": "100px", "opacity" : "0.8"}}><small><em>{parseAuthorsPlain(img.authors)}</em></small></div>
                    </div>
                </>)}
                {(!imgError && <>
                    <img className="shadow-large" style={drawImage(img.highlight)} onError={(e) => setImageError(true)} src={image_path} />
                </>)}
            </div>
        )
    }

    function getSharedWithAccounts(reload_catalogues = false) {
        let return_value = "";
        apiCall("titlelist/getShareableStores", { list_id: list_id }, (_status, _result) => {
            if (_status === "success") {
                const just_linked_accounts = _result.rows.filter((row) => row.is_shared === true)
                return_value = just_linked_accounts.map((row, index) => {
                    if (index === 0) {
                        return row.name;
                    }
                    return " " + row.name;
                })
                setShared(_result);
                setAccountsString(return_value.toString());
            }
        })
        if (reload_catalogues) {
            getCatalogues(true);
        }
    }

    function drawImage(highlight) {
        if (highlight) {
            return {
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",
                maxHeight: "150px",
            }
        }
        return {
            maxHeight: "150px",
        }
    }

    function drawCardBorder(highlight) {
        if (highlight) {
            return {
                top: "-15px",
                lineHeight: "12px",
                padding: "1px 2px",
                backgroundColor: "#ff0099",
            }
        }
        return {
            top: "-15px",
            lineHeight: "12px",
            padding: "1px 2px",
        }
    }

    function shareWithAccounts() {
        return (
            <Button
                type='primary'
                size='small'>
                <Overlay
                    width={1000}
                    component={
                        <ListAccountShare
                            list={data}
                            getCatalogues={getCatalogues}
                            list_id={list_id}
                            presetSANLists={presetSANLists}
                            actual_id={actual_id}
                            list_name={name}
                            setShowAccounts={setShowAccounts}
                            getSharedWithAccounts={getSharedWithAccounts}
                        />
                    }><small>Add/Remove Public View</small>
                </Overlay>
            </Button>
        )
    }

    function reportCardView() {
        return (
            <Tag
                className="tag-btn cataloguebtn"
                color={disabledColor}>
                <Overlay
                    width={1000}
                    component={
                        <ListReportCard
                            actual_id={actual_id}
                            list_id={list_id}
                            list_name={name} />
                    }>Report Card
                </Overlay>
            </Tag>
        )
    }

    const downloadPDF = () => {
        apiDownload({ list_id: list_id }, "titlelist/getLinkedPdf")
    }

    function drawTitle() {
        return (
            <div>
                <conditional.true value={has_linked_pdf}>
                    <Image
                        preview={false}
                        style={{ "cursor": "pointer", "marginRight": "10px", "marginTop": "7px" }}
                        width="30px"
                        height="30px"
                        src="https://bookmanager.com/i/pdf.png"
                        onClick={() => downloadPDF()}
                    />
                </conditional.true>
                <a onMouseDown={(e) => {
                    if (e.button > 0) {
                        linkOut(list_id)
                    }
                }} onClick={() => linkOut(list_id)}>
                    {name}
                    <small style={{ "fontWeight": "normal" }} className="c" >  - {addCommas(isbns_count)} titles  {(is_dynamic && <><small><em>(Dynamic)</em></small></>)}
                    </small>
                </a>
                <div className='shim' />
                <div style={{ "fontSize": "14px" }}><small>
                    <Space>
                        {(created_by && <div><span style={{"opacity" : "0.6"}}>Created by:</span> {created_by}</div>)}
                        {(last_edited_by && <div><span style={{"opacity" : "0.6"}}>Last edited by::</span> {last_edited_by}</div>)}
                    </Space>
                </small>
                </div>
                {onlyMe(is_private)}
            </div >
        )
    }

    function drawEffectivePeriod() {
        if (effective_string !== "" || effective_type !== "none") {
            return (
                <span style={{ "color": disabledColor, "fontSize": "14px", "display": "block" }}>
                    <em>
                        <span style={{ "fontWeight": "normal" }}>{ucfirst(effective_type)}: </span>{effective_string} &nbsp;
                    </em>
                </span>
            )
        }
    }

    function drawSharedAccountsButton() {
        return (
            <div style={{ "color": disabledColor, "fontSize": "14px", "display": "block", "height": "25px" }}>
                {(!is_catalogue && sharedIcon())}
                {(shared_status !== "everyone" &&
                    <em>
                        <Tooltip title="Click to show the store names">
                            <Button style={{ "marginLeft": "-8px", "marginBottom": "-5px" }} type='link' size='small' onClick={() => sharedAccountsButtonFunction()}>
                                Shared Accounts {stores_shared_count > 0 ? "(" + stores_shared_count + ")" : "(0)"}
                            </Button>
                        </Tooltip>
                    </em>
                )}
                {(shared_status === "everyone" &&
                    <span style={{ "fontWeight": "400" }}>
                        Shared Publicly
                    </span>
                )}
            </div>
        )
    }

    function sharedAccountsButtonFunction() {
        setShowAccounts(!showAccounts)
        getSharedWithAccounts();
    }

    function showAccountsString() {
        return (
            <>
                <div style={{ "paddingBottom": "10px" }}>
                    {accountsString}
                </div>
            </>
        )
    }

    function displayDescription() {
        let temp_description_text = description
        if (!description.includes("<br />")) {
            temp_description_text = description.replaceAll("\n", "<br />");
        }
        return temp_description_text;
    }

    function checkIfSelected() {
        let is_selected = false;
        if (selectedRowKeys.some((row) => row === list_id)) {
            is_selected = true;
        }
        return is_selected;
    }

    function sharedIcon() {
        if (shared_status === "none") {
            return (
                <Tooltip title="Not shared with anyone">
                    <EyeInvisibleOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#FF0000" }} />
                </Tooltip>
            )
        } else if (shared_status === "some_my") {
            return (
                <Tooltip title="Shared with some of your accounts">
                    <UserAddOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#C2B30C" }} />
                </Tooltip>
            )
        } else if (shared_status === "some") {
            return (
                <Tooltip title="Shared with some stores">
                    <GlobalOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#C2B30C" }} />
                </Tooltip>
            )
        } else if (shared_status === "one_my") {
            return (
                <Tooltip title="Shared with one of your accounts">
                    <UserOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#D16004" }} />
                </Tooltip>
            )
        } else if (shared_status === "all") {
            return (
                <Tooltip title="Shared with all of your accounts">
                    <TeamOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#00FF00" }} />
                </Tooltip>
            )
        } else if (shared_status === "everyone") {
            return (
                <Tooltip title="Shared publicly with everyone">
                    <GlobalOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#00FF00" }} />
                </Tooltip>
            )
        }
    }

    return (
        <>
        
            {(!loading &&
                <div>
                     <div ref={ref}></div>
                    <div className="hideHover" style={{ "paddingTop": "15px" }}>
                        <div style={{ "float": "right", "textAlign": "right" }}>

                            <div> <Space><small>Quick Pick</small><ListQuickPick list={data} list_id={data?.list_id} />
                            </Space></div>
                            <div className="shim" /><div className="shim" />
                            <Typography.Paragraph style={{ "fontSize": "12px" }}>
                                <Space size={0}>

                                    {(!is_catalogue && shareWithAccounts())}
                                    <Bookmark list_id={list_id} link />

                                    <ListFav getLists={props.getLists} list={data} results={results} setResults={setResults} />
                                    <ListArchive list={data} results={results} setResults={setResults} />
                                    <ListTrash list={data} results={results} setResults={setResults} />
                                    {/* <ListFolder getLists={props.getLists} folder={folder} list_id={list_id} isbns={isbns} icon={true} ></ListFolder> */}


                                </Space>
                            </Typography.Paragraph>
                        </div>

                        {(!is_catalogue && <div style={{ "marginLeft": "-85px" }}>
                            Select
                            <Checkbox checked={checkIfSelected()} style={{ "paddingLeft": "5px" }} onChange={(e) => toggleMultiSelect(list_id, e.target.checked)}></Checkbox>
                        </div>)}
                        {(is_catalogue && <div style={{ "marginTop": "20px" }}></div>)}


                        <Typography.Title style={{ "lineHeight": "17px", "marginTop": "-20px" }} level={4}> {drawTitle()}
                            <br clear="both" />
                            <span style={{ "color": bestsellerColor, "fontSize": "14px", "display": "block", "fontWeight": "normal" }}><em>List: {actual_id} </em>&nbsp;</span>
                            {((!is_catalogue && !is_company) &&
                                <span style={{ "float": "right", "marginTop": "-20px" }}>
                                    <ListFolder list={data} getLists={props.getLists} folder={folder} list_id={list_id} isbns={isbns} />

                                </span>
                            )}

                            <span className="c" style={{ "fontSize": "14px", "display": "block" }}>
                                <Overlay width={700} component={<div style={{ "padding": "20px" }}><strong>Contact</strong><Divider dashed style={{ "margin": "10px 0px" }} /><div className="shim" /><div style={{ "margin": "-8px" }}><BusinessContactInfo id={vendor.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{vendor.name}</a></Overlay>
                            </span>

                            {((agency.id !== vendor.id) && <span className="c" style={{ "fontSize": "14px", "display": "block" }}>
                                <Overlay width={700} component={<div style={{ "padding": "20px" }}><strong>Contact</strong><Divider dashed style={{ "margin": "10px 0px" }} /><div className="shim" /><div style={{ "margin": "-8px" }}><BusinessContactInfo id={agency.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{agency.name}</a></Overlay>
                            </span>)}

                            <Space>
                                {(category && <span style={{ "color": disabledColor, "fontSize": "14px", "display": "block" }}><em><span style={{ "fontWeight": "normal" }}>Category:</span> {category} &nbsp;</em></span>)}
                                {drawEffectivePeriod()}
                            </Space>
                            <br />
                            <Space align="end">
                                {((shared_status && !is_catalogue) && drawSharedAccountsButton())}
                                {((shared_status && !is_catalogue) && <Shared shared={shared} />)}
                            </Space>
                        </Typography.Title>
                        <conditional.true value={showAccounts}>
                            {showAccountsString()}
                        </conditional.true>
                        <div className="shim" />
                        {/* Max 9 wide */}
                        <div style={{ "paddingBottom": "10px", "maxHeight": "170px", "width": "1196px" }}>
                            <Slider style={{ "width": "100%" }}>
                                <Space>
                                    {(has_image && <SliderItem action={() => linkOut(list_id)} key={"498395"}>
                                        <img className="shadow-large inner-dashed-border" style={{ "maxHeight": "150px" }} onError={(e) => e.target.style.display = 'none'} src={"https://bookmanager.com/tl_img?def_id=" + actual_id + "&cache_key=" + image_cache_key} />
                                    </SliderItem>)}
                                    {/* change the count below to make sure it shows the View All Button properly */}
                                    {isbns.slice(0, 8).map(img => {
                                        return (
                                            <>
                                                <conditional.true value={img.highlight}>
                                                    <div style={drawCardBorder(img.highlight)}>
                                                        <small className="c" style={img.highlight ? { "color": "#FFFFFF" } : null}><strong>{img.highlight ? "Highlighted" : ""}</strong></small>
                                                    </div>
                                                    <SliderItem key={img.isbn} isbn={img.isbn} action={() => drawComponent(
                                                        <div key={img.isbn}>
                                                            <div style={{ "padding": "0 0px" }}>
                                                                <div style={{ "marginTop": "0px", "borderTop": "1px solid" }} className="closebar bc">
                                                                    <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                                                                    <br clear="both" />
                                                                </div>
                                                            </div>
                                                            <div className="shim" /><div className="shim" />
                                                            <Renderer><ItemLayouts display="details2" isbn={img.isbn} /></Renderer>
                                                            <div className="shim" /><div className="shim" />
                                                        </div>)}>
                                                        <img className="shadow-large" style={drawImage(false)} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/slider_im.php?b=" + img.eisbn} />
                                                    </SliderItem>
                                                </conditional.true>
                                                <conditional.true value={!img.highlight}>
                                                    <SliderItem key={img.isbn} isbn={img.isbn} action={() => drawComponent(<div style={{ "cursor": "pointer" }} key={img.isbn}>
                                                        <div style={{ "padding": "0 0px" }}>
                                                            <div style={{ "marginTop": "0px", "borderTop": "1px solid" }} className="closebar bc">
                                                                <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                                                                <br clear="both" />
                                                            </div>
                                                        </div>
                                                        <div className="shim" /><div className="shim" />
                                                        <Renderer><ItemLayouts actual_id={actual_id} display="details2" isbn={img.isbn} /></Renderer>
                                                        <div className="shim" /><div className="shim" />
                                                    </div>)}>
                                                        <ListImage img={img} />
                                                    </SliderItem>
                                                </conditional.true>
                                            </>
                                        )
                                    })}
                                    {/* change me if you change the above isbns.slice number */}
                                    {((isbns_count > 8) && <div onClick={() => linkOut(list_id)} style={{ "textAlign": "center", "width": "100px" }}><small>View all <br />{isbns_count} titles</small></div>)}
                                </Space>
                            </Slider>
                        </div>
                    </div>
                    <div className="shim" />
                    <Row>
                        <Col span={12}>
                            <conditional.true value={(description && !site_content)}>
                                <div
                                    className='description'
                                    style={{ "paddingBottom": "20px" }}>
                                    <span
                                        style={{ "color": disabledColor, "fontSize": "12px", "lineHeight": "14px", "display": "block" }}>
                                        <Html html={displayDescription()} />
                                        {/* {description} */}
                                        {/* {displayDescription()} */}
                                    </span>
                                    <div className="shim" />
                                </div>
                            </conditional.true>
                            <conditional.true value={(note)}>
                                <div style={{ "maxWidth": "500px" }}>
                                    <Alert style={{ "padding": "5px 10px" }} description={<div style={{ "marginTop": "-10px" }}><small>{note}</small></div>} message={<div><small><div style={{ "float": "right" }}>{(note_posted) ? <small>{moment(note_posted * 1000).format("MMM D, YYYY")}</small> : ""}</div> Private notes:</small></div>} type="warning" />
                                    <br />
                                </div>
                            </conditional.true>
                        </Col>
                        <Col span={12}>
                            <div style={{ "fontSize": "14px", "float": "right", "textAlign": "right" }}>
                                <div style={{
                                    "color": disabledColor,
                                    "fontWeight": "normal"
                                }}>
                                    Managed by: {owner_name}
                                </div>
                                {(date_posted !== false &&
                                    <div style={{ "fontWeight": "normal" }}>
                                        Posted: {moment(date_posted * 1000).format("MMMM D YYYY h:mma")}
                                    </div>
                                )}
                                <div style={{ "fontWeight": "normal" }}>
                                    <conditional.true value={date_updated > date_posted && date_posted !== false}>
                                        <Tooltip placement='right' title="This catalogue has been updated after it was posted.">
                                            <span style={{ "color": bestsellerColor, "fontWeight": "normal" }}>
                                                Updated by {user_name}: {moment(date_updated * 1000).format("MMMM D YYYY h:mma")}
                                            </span>
                                        </Tooltip>
                                    </conditional.true>
                                    <conditional.true value={date_updated <= date_posted || date_posted === false}>
                                        <span style={{ "fontWeight": "normal" }}>
                                            Updated by {user_name}: {moment(date_updated * 1000).format("MMMM D YYYY h:mma")}
                                        </span>
                                    </conditional.true>
                                </div>
                                <div
                                    style={{
                                        "color": disabledColor,
                                        "fontWeight": "normal"
                                    }}>
                                    Created: {moment(date_created * 1000).format("MMMM D YYYY h:mma")}
                                </div>
                                {((date_expired !== false && date_expired !== null) &&
                                    <div
                                        style={{
                                            "color": bestsellerColor,
                                            "fontWeight": "normal"
                                        }}>
                                        Expires: {moment(date_expired * 1000).format("MMMM D YYYY h:mma")}
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div className="shim" />
                    <div className="shim" />
                    <Space size={0} style={{ "paddingBottom": "15px" }}>
                        {((!is_catalogue && can_edit) &&
                            <Tag
                                className="tag-btn cataloguebtn"
                                color={disabledColor}>
                                <Overlay
                                    maskClosable={false}
                                    width={900}
                                    component={
                                        <ListCreateEdit
                                            getCatalogues={getCatalogues}
                                            results={results}
                                            setResults={setResults}
                                            data={data}
                                        />}>Edit</Overlay>
                            </Tag>)}
                        <Tag
                            className="tag-btn cataloguebtn"
                            color={disabledColor}>
                            <Overlay
                                width={450}
                                component={
                                    <ListsCopy
                                        results={results}
                                        setResults={setResults}
                                        list_id={list_id}
                                        name={name} />
                                }>Copy
                            </Overlay>
                        </Tag>
                        {((!is_catalogue && can_delete) && <ListDelete
                            name={name}
                            results={results}
                            setResults={setResults}
                            list_id={list_id} >
                            <Tag
                                className="tag-btn cataloguebtn"
                                color={disabledColor}>Delete
                            </Tag>
                        </ListDelete>)}
                        <Tag
                            className="tag-btn cataloguebtn"
                            color={disabledColor}>
                            <Overlay
                                width={450}
                                component={
                                    <ListNotes
                                        results={results}
                                        owner_name={owner_name}
                                        setResults={setResults}
                                        note={note}
                                        list_id={list_id} />
                                }>My Notes
                            </Overlay>
                        </Tag>
                        <Tag
                            className="tag-btn cataloguebtn"
                            color={disabledColor}>
                            <Overlay
                                component={
                                    <ListCreateExcel
                                        list_id={list_id} />
                                } width={540}>Excel
                            </Overlay>
                        </Tag>
                        <Tag
                            className="tag-btn cataloguebtn"
                            color={disabledColor}>
                            <Overlay
                                component={
                                    <ListCreatePdf
                                        is_catalogue={true}
                                        count={isbns.length}
                                        list_id={list_id} />}
                                width={500}>PDF
                            </Overlay>
                        </Tag>
                        <Tooltip title={<small>Open a new tab to with a printable version. Max 500 titles.</small>}>
                            <Tag
                                onClick={() => window.open("/print/list/" + list_id, "_blank")}
                                className="tag-btn cataloguebtn"
                                color={disabledColor}>Print</Tag>
                        </Tooltip>
                        <Tag
                            className="tag-btn cataloguebtn"
                            color={disabledColor}>
                            <Overlay
                                width={650}
                                component={
                                    <ListShare
                                        list_id={list_id} />
                                }>Share Link
                            </Overlay>
                        </Tag>
                        {((!is_catalogue) && <Tag
                            className="tag-btn cataloguebtn"
                            color={disabledColor}>
                            <Overlay
                                maskClosable={false}
                                width={900}
                                component={
                                    <ListCommentManagement
                                        date_expired={date_expired}
                                        actual_id={actual_id}
                                        isbns={isbns}
                                        list_name={name}
                                    />
                                }
                            >Import Catalogue Comments
                            </Overlay>
                        </Tag>)}
                        {!is_catalogue && reportCardView()}
                    </Space>
                </div>
            )}
        </>
    );
}