import { Divider, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

export default function ValidMissingData(props) {

    const {
        vendor = "",
        loading = false,
        getDrilldown = () => { },
        isbnValues = {},
        dataValues = {},
        validPageData = [],
    } = props;

    const [validEANMissingData, setValidEANMissingData] = useState();
    const [otherMissingData, setOtherMissingData] = useState();

    //getDrilldown(grouping_row, group, drilldown)
    //grouping_row: y/n, the date, or the prefix
    //group:        valid (none), prefix, or pubdate
    //drilldown:    the specific oo_isbn, oh_isbn, etc.

    const determineDrilldownValue = (type, value) => {
        if (value === "_notitle") {
            return type.concat("_notit").toLowerCase();
        }
        return type.concat(value).toLowerCase();
    }

    const columns = [
        {
            title:
                <Tooltip title="Individual ISBN types">
                    <small></small>
                </Tooltip>,
            width: '150px',
            dataIndex: 'isbntype',
            key: 'isbntype'
        },
        {
            title:
                <Tooltip title="Total ISBNs in the ISBN type, not the total of ISBNs missing some data">
                    <small>Count</small>
                </Tooltip>,
            dataIndex: 'count',
            key: 'count',
            render: (e, f) => {
                return <span
                    className="isbnCount"
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_isbns"), f.count)}>{f.count}</span>
            }
        },
    ]
    if (dataValues.no_title) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing a title">
                    <small>No Title</small>
                </Tooltip>,
            dataIndex: 'notitle',
            key: 'notitle',
            render: (e, f) => {
                return <span
                    className="noTitle"
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_notitle"), f.notitle)}>{f.notitle}</span>
            }
        })
    }
    if (dataValues.no_img) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing an image. Does not include ISBNs that don't have interior images">
                    <small>No Img</small>
                </Tooltip>,
            dataIndex: 'noimg', key: 'noimg', render: (e, f) => {
                return <span
                    className="noImg"
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_noimg"), f.noimg)}>{f.noimg}</span>
            }
        })
    }
    if (dataValues.no_bio) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing an author biography">
                    <small>No Bio</small>
                </Tooltip>,
            dataIndex: 'nobio', key: 'nobio', render: (e, f) => {
                return <span
                    className="noBio"
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nobio"), f.nobio)}>{f.nobio}</span>
            }
        })
    }
    if (dataValues.no_desc) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing a description">
                    <small>No Desc</small>
                </Tooltip>,
            dataIndex: 'nodesc', key: 'nodesc', render: (e, f) => {
                return <span
                    className="noDesc"
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nodesc"), f.nodesc)}>{f.nodesc}</span>
            }
        })
    }


    if (dataValues.no_interiors) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing a interiors">
                    <small>No Interiors</small>
                </Tooltip>,
            dataIndex: 'nointerior', key: 'nointerior', render: (e, f) => {
                return <span
                    className="noInteriors" 
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nointerior"), f.nointerior)}>{f.nointerior}</span>
            }
        })
    }

    if (dataValues.no_digitals) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing digitals">
                    <small>No DRCs/ARCs</small>
                </Tooltip>,
            dataIndex: 'nodigital', key: 'nodigital', render: (e, f) => {
                return <span
                    className="noDRC" 
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nodigital"), f.nodigital)}>{f.nodigital}</span>
            }
        })
    }

    if (dataValues.no_annotations) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing annotations">
                    <small>No Reviews</small>
                </Tooltip>,
            dataIndex: 'noreview', key: 'noreview', render: (e, f) => {
                return <span
                    className="noReviews" 
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_noreview"), f.noreview)}>{f.noreview}</span>
            }
        })
    }

    if (dataValues.no_related_isbns) {
        columns.push({
            title:
                <Tooltip title="ISBNs missing related">
                    <small>No Comparables</small>
                </Tooltip>,
            dataIndex: 'nocomparable', key: 'nocomparable', render: (e, f) => {
                return <span
                    className="noComparables" 
                    style={{ "cursor": "pointer" }}
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nocomparable"), f.nocomparable)}>{f.nocomparable}</span>
            }
        })
    }


    const prepMissingDataForDisplay = (_missing = false) => {
        let valid_temp_array = [];
        let invalid_temp_array = [];
        if (validPageData.length > 0) {
            if (isbnValues.ito_isbns) {
                valid_temp_array.unshift(
                    {
                        isbntype: "ITO",
                        valid_ean: "Y",
                        count: validPageData[0].ito_isbns,
                        notitle: validPageData[0].ito_notit,
                        nodesc: validPageData[0].ito_nodesc,
                        nobio: validPageData[0].ito_nobio,
                        noimg: validPageData[0].ito_noimg,

                        nointerior: validPageData[0].ito_nointerior,
                        nodigital: validPageData[0].ito_nodigital,
                        noreview: validPageData[0].ito_noreview,
                        nocomparable: validPageData[0].ito_nocomparable,

                    },
                )
            }
            if (isbnValues.oo_isbns) {
                valid_temp_array.unshift(
                    {
                        isbntype: "OO",
                        valid_ean: "Y",
                        count: validPageData[0].oo_isbns,
                        notitle: validPageData[0].oo_notit,
                        nodesc: validPageData[0].oo_nodesc,
                        nobio: validPageData[0].oo_nobio,
                        noimg: validPageData[0].oo_noimg,

                        nointerior: validPageData[0].oo_nointerior,
                        nodigital: validPageData[0].oo_nodigital,
                        noreview: validPageData[0].oo_noreview,
                        nocomparable: validPageData[0].oo_nocomparable,
                    },
                )
            }
            if (isbnValues.oh_isbns) {
                valid_temp_array.unshift(
                    {
                        isbntype: "OH",
                        valid_ean: "Y",
                        count: validPageData[0].oh_isbns,
                        notitle: validPageData[0].oh_notit,
                        nodesc: validPageData[0].oh_nodesc,
                        nobio: validPageData[0].oh_nobio,
                        noimg: validPageData[0].oh_noimg,

                        nointerior: validPageData[0].oh_nointerior,
                        nodigital: validPageData[0].oh_nodigital,
                        noreview: validPageData[0].oh_noreview,
                        nocomparable: validPageData[0].oh_nocomparable,
                    },
                )
            }
            if (isbnValues.nyr_isbns) {
                valid_temp_array.unshift(
                    {
                        isbntype: "NYR",
                        valid_ean: "Y",
                        count: validPageData[0].nyr_isbns,
                        notitle: validPageData[0].nyr_notit,
                        nodesc: validPageData[0].nyr_nodesc,
                        nobio: validPageData[0].nyr_nobio,
                        noimg: validPageData[0].nyr_noimg,

                        nointerior: validPageData[0].nyr_nointerior,
                        nodigital: validPageData[0].nyr_nodigital,
                        noreview: validPageData[0].nyr_noreview,
                        nocomparable: validPageData[0].nyr_nocomparable,
                    },
                )
            }
            if (isbnValues.rank_isbns) {
                valid_temp_array.unshift(
                    {
                        isbntype: "Ranked",
                        valid_ean: "Y",
                        count: validPageData[0].rank_isbns,
                        notitle: validPageData[0].rank_notit,
                        nodesc: validPageData[0].rank_nodesc,
                        nobio: validPageData[0].rank_nobio,
                        noimg: validPageData[0].rank_noimg,

                        nointerior: validPageData[0].rank_nointerior,
                        nodigital: validPageData[0].rank_nodigital,
                        noreview: validPageData[0].rank_noreview,
                        nocomparable: validPageData[0].rank_nocomparable,
                    },
                )
            }
            if (isbnValues.all_isbns) {
                valid_temp_array.unshift(
                    {
                        isbntype: "All",
                        valid_ean: "Y",
                        count: validPageData[0].all_isbns,
                        notitle: validPageData[0].all_notit,
                        nodesc: validPageData[0].all_nodesc,
                        nobio: validPageData[0].all_nobio,
                        noimg: validPageData[0].all_noimg,

                        nointerior: validPageData[0].all_nointerior,
                        nodigital: validPageData[0].all_nodigital,
                        noreview: validPageData[0].all_noreview,
                        nocomparable: validPageData[0].all_nocomparable,
                    },
                )
            }

            if (validPageData.length > 1) {
                if (isbnValues.ito_isbns) {
                    invalid_temp_array.unshift(
                        {
                            isbntype: "ITO",
                            valid_ean: "N",
                            count: validPageData[1].ito_isbns,
                            notitle: validPageData[1].ito_notit,
                            nodesc: validPageData[1].ito_nodesc,
                            nobio: validPageData[1].ito_nobio,
                            noimg: validPageData[1].ito_noimg,

                            nointerior: validPageData[1].ito_nointerior,
                            nodigital: validPageData[1].ito_nodigital,
                            noreview: validPageData[1].ito_noreview,
                            nocomparable: validPageData[1].ito_nocomparable,
                        },
                    )
                }
                if (isbnValues.oo_isbns) {
                    invalid_temp_array.unshift(
                        {
                            isbntype: "OO",
                            valid_ean: "N",
                            count: validPageData[1].oo_isbns,
                            notitle: validPageData[1].oo_notit,
                            nodesc: validPageData[1].oo_nodesc,
                            nobio: validPageData[1].oo_nobio,
                            noimg: validPageData[1].oo_noimg,

                            nointerior: validPageData[1].oo_nointerior,
                            nodigital: validPageData[1].oo_nodigital,
                            noreview: validPageData[1].oo_noreview,
                            nocomparable: validPageData[1].oo_nocomparable,
                        },
                    )
                }
                if (isbnValues.oh_isbns) {
                    invalid_temp_array.unshift(
                        {
                            isbntype: "OH",
                            valid_ean: "N",
                            count: validPageData[1].oh_isbns,
                            notitle: validPageData[1].oh_notit,
                            nodesc: validPageData[1].oh_nodesc,
                            nobio: validPageData[1].oh_nobio,
                            noimg: validPageData[1].oh_noimg,

                            nointerior: validPageData[1].oh_nointerior,
                            nodigital: validPageData[1].oh_nodigital,
                            noreview: validPageData[1].oh_noreview,
                            nocomparable: validPageData[1].oh_nocomparable,
                        },
                    )
                }
                if (isbnValues.nyr_isbns) {
                    invalid_temp_array.unshift(
                        {
                            isbntype: "NYR",
                            valid_ean: "N",
                            count: validPageData[1].nyr_isbns,
                            notitle: validPageData[1].nyr_notit,
                            nodesc: validPageData[1].nyr_nodesc,
                            nobio: validPageData[1].nyr_nobio,
                            noimg: validPageData[1].nyr_noimg,

                            nointerior: validPageData[1].nyr_nointerior,
                            nodigital: validPageData[1].nyr_nodigital,
                            noreview: validPageData[1].nyr_noreview,
                            nocomparable: validPageData[1].nyr_nocomparable,
                        },
                    )
                }
                if (isbnValues.rank_isbns) {
                    invalid_temp_array.unshift(
                        {
                            isbntype: "Ranked",
                            valid_ean: "N",
                            count: validPageData[1].rank_isbns,
                            notitle: validPageData[1].rank_notit,
                            nodesc: validPageData[1].rank_nodesc,
                            nobio: validPageData[1].rank_nobio,
                            noimg: validPageData[1].rank_noimg,

                            nointerior: validPageData[1].rank_nointerior,
                            nodigital: validPageData[1].rank_nodigital,
                            noreview: validPageData[1].rank_noreview,
                            nocomparable: validPageData[1].rank_nocomparable,
                        },
                    )
                }
                if (isbnValues.all_isbns) {
                    invalid_temp_array.unshift(
                        {
                            isbntype: "All",
                            valid_ean: "N",
                            count: validPageData[1].all_isbns,
                            notitle: validPageData[1].all_notit,
                            nodesc: validPageData[1].all_nodesc,
                            nobio: validPageData[1].all_nobio,
                            noimg: validPageData[1].all_noimg,

                            nointerior: validPageData[1].all_nointerior,
                            nodigital: validPageData[1].all_nodigital,
                            noreview: validPageData[1].all_noreview,
                            nocomparable: validPageData[1].all_nocomparable,
                        },
                    )
                }
            }

            if (_missing) {
                return invalid_temp_array;
            } else {
                return valid_temp_array;
            }
        }
    }


    return (
        <>
            <div style={{ "padding": "0px 0px" }}>
                <div style={{ "margin": "0 0px" }}>
                    <div className="shim" /><div className="shim" />
                    <Typography.Title style={{ "padding": "5px" }} level={5}>
                        Valid EANs/ISBNs Analysis {(vendor && <small><em>({vendor})</em></small>)}
                    </Typography.Title>
                    <Table
                        size="small"
                        pagination={false}
                        bordered
                        loading={loading}
                        className="missingDataTable"
                        columns={columns}
                        dataSource={prepMissingDataForDisplay()}
                    />
                </div>
                {(validPageData.length > 1 && <div style={{ "margin": "0 0px" }}>
                    <br /><br />
                    <Typography.Title style={{ "padding": "5px" }} level={5}>
                        Invalid EANs/ISBNs  Analysis
                    </Typography.Title>
                    <Table
                        size="small"
                        pagination={false}
                        loading={loading}
                        columns={columns}
                        className="missingDataTable"
                        dataSource={prepMissingDataForDisplay(true)}
                    />
                </div>)}
                <div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        </>
    )
}