import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Modal, Row, Space, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";

import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import Html from "../utils/Html";
import { adjustColor } from "../utils/Utils";
import CommentOptions from "./CommentOptions";

const colors = {
    "personal": "#d1b3e5",
    "webstore": "#f47ddc",
    "clients": "#fa9050",
    "booksellers": "#90EE90",
    "staff": "#ffef00",
    "visitors": "#70e2ff",
    "everyone": "#def1f6",
    "unknown": "#bad4e1",
}

export default function Comment(props) {
    const {
        isbn = "",
        opennew = false,
        callback = () => { },
        specific_title_in_list_id = 0,
    } = props;

    const [data, setData] = useState(props.data);
    const [isnew, setIsNew] = useState(opennew);
    const [editOptions, setEditOptions] = useState({});
    const [modalVisible, setModalVisible] = useState();
    const deleteComment = (_comment_id) => {
        apiCall("comment/delete", {comment_id : _comment_id}, (_status, _result) => {
            if(_status){
                message.success("Comment deleted.");
                callback();
            } else {
                message.error(_result.error);
            }
            setModalVisible(false);
        })
    }

    const saveComment = (f) => {
        let fields ={
            comment: data.text,
            shared_with: editOptions.current_shared_with,
            expiry_type: editOptions.expiry_type,
            visible_anywhere: editOptions.visible_anywhere,
            as_author: editOptions.current_author,
        }
        if (isbn) {
            fields.isbn = isbn;
        }
        if (!isnew) {
            fields.comment_id = data.id;
        }
        if(editOptions.expiry_type === 1 || specific_title_in_list_id === 0 ){
            fields.expiry_days = editOptions.expiry_date;
        }
        if(editOptions.titlelist_id !== null && specific_title_in_list_id > 0){
            fields.titlelist_id = editOptions.titlelist_id !== null ? editOptions.titlelist_id : specific_title_in_list_id;
        }
        let method = (isnew) ? "comment/add" : "comment/edit";
        apiCall(method, fields, (_status, _result) => {
            if (_status) {
                callback();
                message.success("Comment saved successfully.");
                close();
            }
        })
    }

    const createNew = () => {
        setData({ ...data, "text": "" });
        setIsNew(true);
    }

    const drawEdit = () => {
        return (
            <>
                <Form onFinish={(f) => saveComment(f)} layout="vertical">
                    <Row gutter={30}>
                        <Col span={14}>
                            <Form.Item initialValue={data.text} help="<a><b><i><u><br /> HTML tags allowed only" rules={[{ required: true, message: "Comment required" }]} name="text" label="Comment">
                                <Input.TextArea onChange={(e) => setData({ ...data, "text": e.target.value })} rows={8} showCount maxLength={2000}></Input.TextArea>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <CommentOptions 
                                specific_title_in_list_id={specific_title_in_list_id}
                                isnew={isnew} 
                                comment_id={data.id} 
                                editOptions={editOptions} 
                                setEditOptions={setEditOptions} 
                                isbn={isbn} 
                                data={data}/>
                        </Col>
                    </Row>
                    <Divider />
                    <conditional.true value={(!isnew)}>
                        <Button onClick={() => createNew()}>Create New Comment</Button>
                    </conditional.true>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                            {((!isnew) &&  <Button type="primary" danger onClick={() => deleteComment(data.id)}>Delete</Button>)}
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        )
    }

    const open = () => {
        if (props.opennew) {
            setIsNew(true)
        }
        setData(props.data);
        setModalVisible(true);
    }

    const close = () => {
        setIsNew(false);
        setModalVisible(false);
    }

    return (
        <>
            <Modal destroyOnClose width={"100%"} style={{ "maxWidth": "700px" }} footer={null} onCancel={() => close()} visible={modalVisible}>
                <Typography.Title level={5}>{(isnew) ? "New" : "Edit"} Comment</Typography.Title>
                <Divider />
                {drawEdit()}
            </Modal>
            <span style={{ "cursor": "pointer" }} onClick={() => open()}>{props.children}</span>
        </>
    );
}
// When you have the data, and just want to display the card
Comment.Display = (props) => {
    const { 
        data, 
        isbn, 
        callback = () => { }, 
        currentCatalogue = [] 
    } = props;

    const drawEditLink = () => {
        let visible_text = data.rep_tlc_audience;
        let author_text = "";
        //for handling the author text for stores or whatnot, see https://gitlab.bookmanager.com/web/issues/-/issues/3204
        if(data.author.includes("@")){
            if(data.shared_with === "visitors"){
                visible_text = " - visible to visitors at " + data.author.substring(data.author.indexOf("@") + 2);
            }else{
                visible_text = " - " + visible_text;
            }
            
            author_text = data.author;
        }else if(data.rep_author === data.author){
            if(data.shared_with === "visitors"){
                visible_text = " - visible to visitors at " + data.rep_author
            }else{
                visible_text = " - " + visible_text;
            }
            author_text = data.rep_author + " @ " + data.author ;
        }else{
            visible_text = " - " + visible_text;
            author_text = " " + data.rep_author
        }
        return (<>
            <small>
                <em style={{ 
                    "cursor": (data.editable) ? "pointer" : "default", 
                    "fontWeight": "500", 
                    "color": adjustColor(colors[data.shared_with], -50)}}>
                    {author_text}
                </em>
                <em 
                    style={{ 
                        "cursor": (data.editable) ? "pointer" : "default", 
                        "fontWeight": "500", 
                        "color": adjustColor(colors[data.shared_with], -50) 
                    }}>{visible_text} {(data.editable) ? <EditOutlined /> : ""}</em></small></>)
    }

    const borderStyle = () => {
        if(data.shared_with === "booksellers"){
            return { "padding": "5px 10px",  
                "border": "6px solid" + adjustColor(colors[data.shared_with], -20),
                "borderRadius": "10px" 
            }
        }else{
            return { 
                "padding": "5px 10px",  
                "border": "3px solid" + adjustColor(colors[data.shared_with], -20),
                "borderRadius": "10px" }
        }
    }

    return (
        <div style={borderStyle()}>
            <div className="htmlcolor" style={{ "fontSize": "13px", "lineHeight": "16px", "maxWidth": "570px" }}><Html html={data.text} /></div>
            <div style={{ "color": "#888" }}>
                <span>
                    <div>{(data.editable) ? <Comment callback={callback} data={data} isbn={isbn} currentCatalogue={currentCatalogue}>{drawEditLink()}</Comment> : drawEditLink()}</div>
                    <small>Date Posted: {moment(data.date * 1000).format("MMM Do, YYYY")}</small>
                </span>
                {data.expiry !== false ? <small>,  Expiry Date: {moment(data.expiry * 1000).format("MMM Do, YYYY")}</small> : ""}
            </div>
        </div>
    )
}