import { Button } from "antd";
import React, { useState } from "react";

import { apiCall } from "../utils/Api";
import Comment from "./Comment";

export default function Comments(props) {
    const { 
        isbn = "", 
        row = false, 
        comments = false,
        specific_title_in_list_id = 0,
    } = props;
    const [results, setResults] = useState((comments) ? comments : []);

    const getComments = () => {
        let request = {};
        request.isbn = isbn;
        if(specific_title_in_list_id > 0){
            request.titlelist_id = specific_title_in_list_id;
        }
        apiCall("title/getComments", request, (_status, _result) => {
            if (_status) {
                setResults(_result);
            }
        })
    }  

    const drawComments = () => {
        if (results.length < 1) {
            return (<></>)
        }
        return results.map((item, index) => {
            return (
                <div key={index}>
                    <Comment.Display 
                        callback={getComments} 
                        isbn={isbn} 
                        key={index} 
                        data={item}
                        specific_title_in_list_id={specific_title_in_list_id}/>
                    {((index + 1) !== results.length) && <><div className="shim"></div><div className="shim"></div></>}
                </div>
            )
        })
    }

    if (row) {
        return (
            <>
                <div style={{ "float": "left" }}>
                    <Comment 
                        specific_title_in_list_id={specific_title_in_list_id} 
                        isbn={isbn} 
                        opennew
                        data={{ text: "" }} 
                        callback={getComments} >
                        <Button className="mini-btn" size="small"><small>New Comment</small></Button>
                    </Comment>
                    <div className="shim" /><div className="shim" />
                </div>
                <br clear="all" />
                <div style={{ "padding": "0px" }}>
                    <div className="shim" />
                        {drawComments()}
                    <div className="shim" />
                </div>
            </>
        )
    }

    return (<>
        <div style={{ "padding": "0px" }}>
            <div className="shim" />
                {drawComments()}
            <div className="shim" />
        </div>
    </>
    )
}