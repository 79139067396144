import { Button, Divider, Layout, Typography } from "antd";
import React from "react";

export default function LayoutHeader(props) {

    const { title = "", description = "", button = "", filters = "", todo = false } = props;

    return (
        <Layout.Content style={{"padding": "0px" }} >
            <div className="layoutHeader">
                <Typography.Title style={{ "lineHeight": "17px" }} level={4}>
                    <div>
                        {title}
                        {(todo && <span style={{ "color": "#aaa" }}>&nbsp;[<small><em> TODO </em></small>]</span>)}
                    </div>
                </Typography.Title>
                <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                {(button &&
                    <div style={{ "float": "right" }}>
                        {button}
                    </div>
                )}
                {(description && <p>{description}</p>)}
                {((filters) && filters)}
            </div>
        </Layout.Content>
    )
}