
export const settings = {

    is_canadian: true,
    url : "https://mosaicbooks.ca/"
}

export const navigation = [

    {
        name: "Catalogues",
        subnav: [
            {
                name: "My Catalogues",
                slug: "mylists",
            },
            {
                name: "My Company's Catalogues",
                slug: "corplists",
            },
            {
                name: "All Public Catalogues",
                slug: "listcat",
            },
            {
                name: "Manage Comments",
                permission: ["pubdata", "smdata"],
                slug: "catalogues/manage-title-comments"
            },
        ]
    },
    {
        name: "Browse",
        slug: "browse"
    },
    {
        name: "Tools",
        subnav: [
            {
                name: "EDI Activity",
                permission: ["edi_service"],
                slug: "tools/edi-activity",
            },
            {
                name: "Tracked ISBNs",
                permission: ["salesisbn"],
                slug: "tools/tracking",
            },
            {
                name: "Missing Data",
                permission: ["pubdata", "smdata"],
                slug: "tools/missing-data",
            },
            {
                name: "Stock and Title Uploads",
                permission: ["interm"],
                slug: "tools/stock-title-uploads"
            },
        ]
    },
    {
        name: "Admin",
        slug: "administration",
        subnav: [
            {
                name: "My Accounts",
                permission: ["pubdata", "smdata"],
                slug: "administration/accounts",
            },
            {
                name: "Permissions",
                slug: "administration/permissions",
                permission: ["edit_roles"],
                active: true,
            },
            {
                name: "Group Lists",
                permission: ["pubdata", "smdata"],
                slug: "administration/group-lists"
            },
          
        ]
    },
]

export const hist = {
    "draw_months": 14,
    "extras": {
        "backroom": 0,
        "held": 1,
        "onhand": 26,
        "onorder": 0,
        "pending_return": 0,
        "special_order": 0
    },
    "rows": [
        {
            "class": "adjusted",
            "title": "View your detailed activity (adjustments) for this title",
            "total": 0,
            "months": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "week": 0
        },
        {
            "class": "returns",
            "title": "View your detailed activity (returns) for this title",
            "total": 1,
            "months": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            "week": 0
        },
        {
            "class": "received",
            "title": "View your detailed activity (orders) for this title(1st rcvd 1409727600)",
            "total": 591,
            "months": [
                35,
                30,
                30,
                10,
                35,
                20,
                84,
                12,
                24,
                24,
                12,
                48,
                24,
                0
            ],
            "week": 0
        },
        {
            "class": "sold",
            "title": "View your detailed activity (sales) for this title",
            "total": 563,
            "months": [
                23,
                30,
                25,
                19,
                22,
                23,
                70,
                27,
                22,
                23,
                18,
                26,
                29,
                8
            ],
            "week": 8
        },
        {
            "class": "peer",
            "title": "Weighted monthly sales at all Canadian stores",
            "total_stores": 178,
            "total_stores_region": "",
            "months": [
                742,
                856,
                648,
                959,
                1010,
                2348,
                1093,
                899,
                907,
                689,
                1190,
                1250,
                414
            ],
            "week": 242,
            "onhand": 674,
            "onhandlocs": 136,
            "onorder": 247,
            "onorderlocs": 59
        }
    ],
    "notes": "INDIG- &nbsp; $26.95 [pand] Teachings of Plants",
    "station_id": ""
}

export const itemdata = {
    "authors": [
        "Katie Bickell"
    ],
    "price": "22.00",
    "rank": {
        "bestseller": 24568
    },
    "binding": "Paperback",
    "release_date": 1601362800,
    "is_forthcoming": false,
    "available": [
        {
            "code": "",
            "text": "",
            "row_price": "22.00",
            "used": false,
            "note": "",
            "onhand": "3",
            "discount": null,
            "availability": "available",
            "message": "3 in store",
            "sub_message": ""
        }
    ],
    "canadian": true,
    "title": "Always Brave, Sometimes Kind",
    "subtitle": "A Novel",
    "eisbn": "7KkAOaEmoWXUpIcCJHBNgQ",
    "height": 8.5,
    "width": 5.5,
    "pages": 272,
    "publisher": "TouchWood Editions",
    "has_libro_audiobook": false,
    "audience": null,
    "edition": "",
    "edition_type": null,
    "has_interiors": false,
    "print_status": null,
    "imprint": "TouchWood Editions",
    "series": null,
    "series_num": null,
    "isbn": "9781927366xxx",
    "weight": 0.75,
    "carton_qty": 40,
    "description": "<p><b><i>An exciting debut novel told in connected short stories that captures the diverse and complicated networks of people who stretch our communities\u2014sometimes farther than we know.<\/i><\/b><p> <p>Set in the cities, reserves, and rural reaches of Alberta, Katie Bickell\u2019s debut novel is told in a series of stories that span the years from 1990 to 2016, through cycles of boom and bust in the oil fields, government budget cuts and workers rights policies, the rising opioid crisis, and the intersecting lives of people whose communities sometimes stretch farther than they know.<\/p><p>We meet a teenage runaway who goes into labour at the West Edmonton Mall, a doctor managing hospital overflow in a time of healthcare cutbacks, a broke dad making extra pay through a phone sex line, a young musician who dreams of fame beyond the reserve, and a dedicated hockey mom grappling with sense of self when she\u2019s no longer needed\u2014or welcome\u2014at the rink.<\/p><p><i>Always Brave, Sometimes Kind<\/i> captures a network of friends, caregivers, in-laws, and near misses, with each character\u2019s life coming into greater focus as we learn more about the people around them. Tracing alliances and betrayals from different perspectives over decades, Bickell writes an ode to home and community that is both warm and gritty, well-defined and utterly complicated.<\/p>",
    "bio": [
        "<p><b>Katie Bickell<\/b> emigrated from England to northern Alberta in 1990. Her fiction has been published in the <i>Tahoma Literary Review<\/i> and <i>Alberta Views<\/I> and her essays have appeared in <i>WestWord Magazine, HERizons Magazine<\/i>, and on <i>The Temper<\/i>. Chapters from <i>Always Brave, Sometimes Kind<\/i> have received the Alberta Literary Award\u2019s Howard O\u2019Hagan for Short Story, the Writers Guild of Alberta\u2019s Emerging Writer Award, and won the <i>Alberta Views<\/i> Fiction Prize. Katie lives in Sherwood Park, Alberta, just outside of Edmonton.<\/p>"
    ],
    "comments": [],
    "reviews": [],
    "subjects": [
        {
            "subject": "Fiction",
            "sub_labels": [
                {
                    "bisac": "FIC066000",
                    "label": "Small Town & Rural"
                },
                {
                    "bisac": "FIC048000",
                    "label": "Urban & Street Lit"
                },
                {
                    "bisac": "FIC019000",
                    "label": "Literary"
                }
            ]
        }
    ],
    "catalogues": [],
    "illustrations": "na (no illustration)",
    "interiors": []
}

export const units = [
    {
        type: '< 1 Month',
        value: 907,
        sales: 14663,
        color: "#333",
    },
    {
        type: '2-9 Months',
        value: 1282,
        sales: 18819,
        color: "#555",
    },
    {
        type: '10-12 Months',
        value: 21,
        sales: 184,
        color: "#777",
    },
    {
        type: '> 12 Months',
        value: 94,
        sales: 458,
        color: "#999",
    }
];

export const stock = [
    {
        type: 'On hand',
        value: 55613,
        color: "#333",
    },
    {
        type: 'On Order',
        value: 24295,
        color: "#555",
    }
];


export const filters = {
    "subj1": [
        {
            "k": "JUV",
            "c": "4077",
            "e": "Juvenile Fiction"
        },
        {
            "k": "FIC",
            "c": "3721",
            "e": "Fiction"
        },
        {
            "k": "JNF",
            "c": "1522",
            "e": "Juvenile Nonfiction"
        },
        {
            "k": "BIO",
            "c": "1216",
            "e": "Biography & Autobiography"
        },
        {
            "k": "SEL",
            "c": "889",
            "e": "Self-Help"
        },
        {
            "k": "YAF",
            "c": "793",
            "e": "Young Adult Fiction"
        },
        {
            "k": "HIS",
            "c": "748",
            "e": "History"
        },
        {
            "k": "CKB",
            "c": "739",
            "e": "Cooking"
        },
        {
            "k": "SOC",
            "c": "678",
            "e": "Social Science"
        },
        {
            "k": "GAM",
            "c": "490",
            "e": "Games & Activities"
        },
        {
            "k": "HEA",
            "c": "438",
            "e": "Health & Fitness"
        },
        {
            "k": "OCC",
            "c": "436",
            "e": "Body, Mind & Spirit"
        },
        {
            "k": "CGN",
            "c": "426",
            "e": "Comics & Graphic Novels"
        },
        {
            "k": "FAM",
            "c": "389",
            "e": "Family & Relationships"
        },
        {
            "k": "BUS",
            "c": "385",
            "e": "Business & Economics"
        },
        {
            "k": "NAT",
            "c": "362",
            "e": "Nature"
        },
        {
            "k": "PSY",
            "c": "346",
            "e": "Psychology"
        },
        {
            "k": "SCI",
            "c": "344",
            "e": "Science"
        },
        {
            "k": "_PZ",
            "c": "337",
            "e": "__ Puzzles"
        },
        {
            "k": "POL",
            "c": "333",
            "e": "Political Science"
        },
        {
            "k": "_ST",
            "c": "311",
            "e": "__ Stationery"
        },
        {
            "k": "HUM",
            "c": "285",
            "e": "Humor"
        },
        {
            "k": "TRV",
            "c": "270",
            "e": "Travel"
        },
        {
            "k": "ART",
            "c": "268",
            "e": "Art"
        },
        {
            "k": "REL",
            "c": "236",
            "e": "Religion"
        },
        {
            "k": "CAL",
            "c": "230",
            "e": "Calendar"
        },
        {
            "k": "_GM",
            "c": "221",
            "e": "__ Games"
        },
        {
            "k": "SPO",
            "c": "218",
            "e": "Sports & Recreation"
        },
        {
            "k": "REF",
            "c": "215",
            "e": "Reference"
        },
        {
            "k": "POE",
            "c": "204",
            "e": "Poetry"
        },
        {
            "k": "CRA",
            "c": "197",
            "e": "Crafts & Hobbies"
        },
        {
            "k": "PHI",
            "c": "154",
            "e": "Philosophy"
        },
        {
            "k": "MED",
            "c": "152",
            "e": "Medical"
        },
        {
            "k": "GAR",
            "c": "142",
            "e": "Gardening"
        },
        {
            "k": "LCO",
            "c": "135",
            "e": "Literary Collections"
        },
        {
            "k": "_AC",
            "c": "130",
            "e": "__ Activity Cards"
        },
        {
            "k": "_TY",
            "c": "128",
            "e": "__ Toys & Plush"
        },
        {
            "k": "PHO",
            "c": "120",
            "e": "Photography"
        },
        {
            "k": "MUS",
            "c": "120",
            "e": "Music"
        },
        {
            "k": "HOM",
            "c": "120",
            "e": "House & Home"
        },
        {
            "k": "TRU",
            "c": "108",
            "e": "True Crime"
        },
        {
            "k": "YAN",
            "c": "104",
            "e": "Young Adult Nonfiction"
        },
        {
            "k": "PER",
            "c": "103",
            "e": "Performing Arts"
        },
        {
            "k": "_OT",
            "c": "101",
            "e": "__ Other"
        },
        {
            "k": "LIT",
            "c": "86",
            "e": "Literary Criticism"
        },
        {
            "k": "PET",
            "c": "85",
            "e": "Pets"
        },
        {
            "k": "LAN",
            "c": "81",
            "e": "Language Arts & Disciplines"
        },
        {
            "k": "NON",
            "c": "77",
            "e": "Non-Classifiable"
        },
        {
            "k": "DES",
            "c": "76",
            "e": "Design"
        },
        {
            "k": "TEC",
            "c": "72",
            "e": "Technology & Engineering"
        },
        {
            "k": "_BM",
            "c": "67",
            "e": "__ Bookmarks"
        },
        {
            "k": "ARC",
            "c": "49",
            "e": "Architecture"
        },
        {
            "k": "ANT",
            "c": "47",
            "e": "Antiques & Collectibles"
        },
        {
            "k": "EDU",
            "c": "46",
            "e": "Education"
        },
        {
            "k": "COM",
            "c": "44",
            "e": "Computers"
        },
        {
            "k": "TRA",
            "c": "42",
            "e": "Transportation"
        },
        {
            "k": "LAW",
            "c": "34",
            "e": "Law"
        },
        {
            "k": "DRA",
            "c": "27",
            "e": "Drama"
        },
        {
            "k": "_MP",
            "c": "26",
            "e": "__ Maps"
        },
        {
            "k": "MAT",
            "c": "26",
            "e": "Mathematics"
        },
        {
            "k": "_CL",
            "c": "25",
            "e": "__ Clothing & Apparel"
        },
        {
            "k": "_AV",
            "c": "24",
            "e": "__ Audio & Video"
        },
        {
            "k": "FOR",
            "c": "16",
            "e": "Foreign Language Study"
        },
        {
            "k": "STU",
            "c": "9",
            "e": "Study Aids"
        },
        {
            "k": "BIB",
            "c": "7",
            "e": "Bibles"
        },
        {
            "k": "_LG",
            "c": "6",
            "e": "__ Luggage"
        },
        {
            "k": "_HM",
            "c": "6",
            "e": "__ House & Home"
        },
        {
            "k": "_GC",
            "c": "4",
            "e": "__ Greeting Cards"
        },
        {
            "k": "_RA",
            "c": "3",
            "e": "__ Reading Accessories"
        },
        {
            "k": "VID",
            "c": "2",
            "e": "Video & DVD Genre"
        },
        {
            "k": "CUR",
            "c": "1",
            "e": "Current Events"
        }
    ],
    "binding": [
        {
            "k": "PB",
            "c": "10522",
            "e": "Paperback (PB)"
        },
        {
            "k": "HC",
            "c": "4814",
            "e": "Hardcover (HC)"
        },
        {
            "k": "BB",
            "c": "547",
            "e": "Boardbook (BB)"
        },
        {
            "k": "OT",
            "c": "482",
            "e": "Other (OT)"
        },
        {
            "k": "PU",
            "c": "329",
            "e": "Puzzle (PU)"
        },
        {
            "k": "CA",
            "c": 230,
            "e": "Calendar (CA)",
            "subfilter": [
                {
                    "k": "CAP104",
                    "c": "24",
                    "e": "Day by day Calendar"
                },
                {
                    "k": "CAP103",
                    "c": "41",
                    "e": "Engagement Calendar"
                },
                {
                    "k": "CAP102",
                    "c": "13",
                    "e": "Mini Calendar"
                },
                {
                    "k": "CAP110",
                    "c": "1",
                    "e": "Student Calendar"
                },
                {
                    "k": "CAP106",
                    "c": "73",
                    "e": "Wall Calendar"
                }
            ]
        },
        {
            "k": "GM",
            "c": "168",
            "e": "Game (GM)"
        },
        {
            "k": "CS",
            "c": "134",
            "e": "Cards (CS)"
        },
        {
            "k": "VP",
            "c": "54",
            "e": "Vinyl Paperback (VP)"
        },
        {
            "k": "SP",
            "c": "33",
            "e": "Spiral Bound (SP)"
        },
        {
            "k": "BM",
            "c": "26",
            "e": "Bookmark (BM)"
        },
        {
            "k": "MP",
            "c": "25",
            "e": "Sheetmap (MP)"
        },
        {
            "k": "AD",
            "c": "19",
            "e": "Audio CD (AD)"
        },
        {
            "k": "CG",
            "c": "19",
            "e": "Clothing (CG)"
        },
        {
            "k": "BK",
            "c": "17",
            "e": "Book (BK)"
        },
        {
            "k": "BX",
            "c": "11",
            "e": "Box or Slipcased (BX)"
        },
        {
            "k": "TY",
            "c": "11",
            "e": "Toy (TY)"
        },
        {
            "k": "SE",
            "c": "9",
            "e": "Set (SE)"
        },
        {
            "k": "LT",
            "c": "8",
            "e": "Leather\/Fine Binding (LT)"
        },
        {
            "k": "BA",
            "c": "7",
            "e": "Bath Book (BA)"
        },
        {
            "k": "TO",
            "c": "5",
            "e": "Tote bag (TO)"
        },
        {
            "k": "BG",
            "c": "5",
            "e": "Board Game (BG)"
        },
        {
            "k": "CD",
            "c": "5",
            "e": "Audio CD (CD)"
        },
        {
            "k": "LB",
            "c": "3",
            "e": "Library Binding (LB)"
        },
        {
            "k": "DO",
            "c": "2",
            "e": "Doll (DO)"
        },
        {
            "k": "GC",
            "c": "2",
            "e": "Greeting Card (GC)"
        },
        {
            "k": "CL",
            "c": "1",
            "e": "Cloth Book (CL)"
        },
        {
            "k": "ST",
            "c": "1",
            "e": "Stapled (ST)"
        },
        {
            "k": "CM",
            "c": "1",
            "e": "Audio CD (MP3) (CM)"
        },
        {
            "k": "VB",
            "c": "1",
            "e": "Vinyl Bound (VB)"
        }
    ],
    "status": [
        {
            "k": "rankcaranked",
            "c": "14617",
            "e": "Popular"
        },
        {
            "k": "recent",
            "c": "428",
            "e": "Recent releases"
        },
        {
            "k": "fc",
            "c": "96",
            "e": "Future releases",
            "n": 1
        },
        {
            "k": "--"
        },
        {
            "k": "__instock",
            "e": "What's in store",
            "c": "19861"
        },
        {
            "k": "__onorder",
            "e": "What's on order",
            "c": "723"
        },
        {
            "k": "11C",
            "c": "16152",
            "e": "regular stock"
        },
        {
            "k": "11CR",
            "c": "4037",
            "e": "bargain stock"
        },
        {
            "k": "--"
        },
        {
            "k": "__canada",
            "e": "Canadian author",
            "c": "2460"
        }
    ],
    "pubdate": [
        {
            "k": "2021z09z01",
            "c": "1",
            "e": "Sep 2021"
        },
        {
            "k": "2021z08z01",
            "c": "11",
            "e": "Aug 2021"
        },
        {
            "k": "2021z07z01",
            "c": "313",
            "e": "Jul 2021"
        },
        {
            "k": "2021z06z01",
            "c": "652",
            "e": "Jun 2021"
        },
        {
            "k": "2021z05z01",
            "c": "729",
            "e": "May 2021"
        },
        {
            "k": "2021z04z01",
            "c": "698",
            "e": "Apr 2021"
        },
        {
            "k": "2021z03z01",
            "c": "639",
            "e": "Mar 2021"
        },
        {
            "k": "2021z02z01",
            "c": "472",
            "e": "Feb 2021"
        },
        {
            "k": "2021z01z01",
            "c": "453",
            "e": "Jan 2021"
        },
        {
            "k": "2020z12z01",
            "c": "345",
            "e": "Dec 2020"
        },
        {
            "k": "2020z11z01",
            "c": "393",
            "e": "Nov 2020"
        },
        {
            "k": "2020z10z01",
            "c": "676",
            "e": "Oct 2020"
        },
        {
            "k": "2020z09z01",
            "c": "671",
            "e": "Sep 2020"
        },
        {
            "k": "2020z08z01",
            "c": "311",
            "e": "Aug 2020"
        },
        {
            "k": "2020z07z01",
            "c": "224",
            "e": "Jul 2020"
        },
        {
            "k": "2020z06z01",
            "c": "261",
            "e": "Jun 2020"
        },
        {
            "k": "2020z05z01",
            "c": "239",
            "e": "May 2020"
        },
        {
            "k": "2020z04z01",
            "c": "316",
            "e": "Apr 2020"
        },
        {
            "k": "2020z03z01",
            "c": "368",
            "e": "Mar 2020"
        },
        {
            "k": "2020z02z01",
            "c": "219",
            "e": "Feb 2020"
        },
        {
            "k": "2020z01z01",
            "c": "267",
            "e": "Jan 2020"
        },
        {
            "k": "2019z01z01",
            "c": "2589",
            "e": "2019"
        },
        {
            "k": "2018z01z01",
            "c": "1466",
            "e": "2018"
        },
        {
            "k": "2017z01z01",
            "c": "1002",
            "e": "2017"
        },
        {
            "k": "2016z01z01",
            "c": "722",
            "e": "2016"
        },
        {
            "k": "2015z01z01",
            "c": "545",
            "e": "2015"
        },
        {
            "k": "2014z01z01",
            "c": "458",
            "e": "2014"
        },
        {
            "k": "2013z01z01",
            "c": "296",
            "e": "2013"
        },
        {
            "k": "2012z01z01",
            "c": "274",
            "e": "2012"
        },
        {
            "k": "2011z01z01",
            "c": "220",
            "e": "2011"
        },
        {
            "k": "2010z01z01",
            "c": "186",
            "e": "2010"
        },
        {
            "k": "2009z01z01",
            "c": "169",
            "e": "2009"
        },
        {
            "k": "2008z01z01",
            "c": "166",
            "e": "2008"
        },
        {
            "k": "2007z01z01",
            "c": "149",
            "e": "2007"
        },
        {
            "k": "2006z01z01",
            "c": "105",
            "e": "2006"
        },
        {
            "k": "2005z01z01",
            "c": "76",
            "e": "2005"
        },
        {
            "k": "2004z01z01",
            "c": "82",
            "e": "2004"
        },
        {
            "k": "2003z01z01",
            "c": "82",
            "e": "2003"
        },
        {
            "k": "2002z01z01",
            "c": "67",
            "e": "2002"
        },
        {
            "k": "2001z01z01",
            "c": "59",
            "e": "2001"
        },
        {
            "k": "2000z01z01",
            "c": "41",
            "e": "2000"
        },
        {
            "k": "1999z01z01",
            "c": "44",
            "e": "1999"
        },
        {
            "k": "1998z01z01",
            "c": "37",
            "e": "1998"
        },
        {
            "k": "1997z01z01",
            "c": "40",
            "e": "1997"
        },
        {
            "k": "1996z01z01",
            "c": "22",
            "e": "1996"
        },
        {
            "k": "1995z01z01",
            "c": "24",
            "e": "1995"
        },
        {
            "k": "1994z01z01",
            "c": "15",
            "e": "1994"
        },
        {
            "k": "1993z01z01",
            "c": "16",
            "e": "1993"
        },
        {
            "k": "1992z01z01",
            "c": "17",
            "e": "1992"
        },
        {
            "k": "1991z01z01",
            "c": "18",
            "e": "1991"
        },
        {
            "k": "1990z01z01",
            "c": "17",
            "e": "1990"
        },
        {
            "k": "1989z01z01",
            "c": "4",
            "e": "1989"
        },
        {
            "k": "1988z01z01",
            "c": "16",
            "e": "1988"
        },
        {
            "k": "1987z01z01",
            "c": "10",
            "e": "1987"
        },
        {
            "k": "1986z01z01",
            "c": "9",
            "e": "1986"
        },
        {
            "k": "1985z01z01",
            "c": "6",
            "e": "1985"
        },
        {
            "k": "1984z01z01",
            "c": "9",
            "e": "1984"
        },
        {
            "k": "1983z01z01",
            "c": "11",
            "e": "1983"
        },
        {
            "k": "1982z01z01",
            "c": "8",
            "e": "1982"
        },
        {
            "k": "1981z01z01",
            "c": "8",
            "e": "1981"
        },
        {
            "k": "1980z01z01",
            "c": "4",
            "e": "1980"
        },
        {
            "k": "1979z01z01",
            "c": "2",
            "e": "1979"
        },
        {
            "k": "1978z01z01",
            "c": "2",
            "e": "1978"
        },
        {
            "k": "1977z01z01",
            "c": "2",
            "e": "1977"
        },
        {
            "k": "1976z01z01",
            "c": "4",
            "e": "1976"
        },
        {
            "k": "1974z01z01",
            "c": "2",
            "e": "1974"
        },
        {
            "k": "1973z01z01",
            "c": "5",
            "e": "1973"
        },
        {
            "k": "1971z01z01",
            "c": "5",
            "e": "1971"
        },
        {
            "k": "1970z01z01",
            "c": "1",
            "e": "1970"
        },
        {
            "k": "1968z01z01",
            "c": "3",
            "e": "1968"
        },
        {
            "k": "1967z01z01",
            "c": "1",
            "e": "1967"
        },
        {
            "k": "1966z01z01",
            "c": "1",
            "e": "1966"
        },
        {
            "k": "1963z01z01",
            "c": "2",
            "e": "1963"
        },
        {
            "k": "1961z01z01",
            "c": "4",
            "e": "1961"
        },
        {
            "k": "1960z01z01",
            "c": "2",
            "e": "1960"
        },
        {
            "k": "1958z01z01",
            "c": "2",
            "e": "1958"
        },
        {
            "k": "1954z01z01",
            "c": "1",
            "e": "1954"
        },
        {
            "k": "1950z01z01",
            "c": "1",
            "e": "1950"
        },
        {
            "k": "1931z01z01",
            "c": "1",
            "e": "1931"
        },
        {
            "k": "",
            "c": "2473",
            "e": ""
        }
    ],
    "audience": [
        {
            "k": "a0to2",
            "c": "367",
            "e": "Ages 3 and under"
        },
        {
            "k": "a2to4",
            "c": "672",
            "e": "Ages 2-4"
        },
        {
            "k": "a4to6",
            "c": "1873",
            "e": "Ages 4-6"
        },
        {
            "k": "a6to9",
            "c": "2028",
            "e": "Ages 6-9"
        },
        {
            "k": "a9to12",
            "c": "1562",
            "e": "Ages 9-12"
        },
        {
            "k": "a12under",
            "c": "5000",
            "e": "All ages 12 and under"
        },
        {
            "k": "a12over",
            "c": "1105",
            "e": "Ages 12 and over"
        }
    ],
    "excludes": []
}

export const countries =

    [
        { value: "aar", label: "Afar" },
        { value: "abk", label: "Abkhaz" },
        { value: "ace", label: "Achinese" },
        { value: "ach", label: "Acoli" },
        { value: "ada", label: "Adangme" },
        { value: "ady", label: "Adygei" },
        { value: "afa", label: "Afro-Asiatic languages" },
        { value: "afh", label: "Afrihili" },
        { value: "afr", label: "Afrikaans" },
        { value: "ain", label: "Ainu" },
        { value: "aka", label: "Akan" },
        { value: "akk", label: "Akkadian" },
        { value: "alb", label: "Albanian" },
        { value: "ale", label: "Aleut" },
        { value: "alg", label: "Algonquian languages" },
        { value: "alt", label: "Southern Altai" },
        { value: "amh", label: "Amharic" },
        { value: "ang", label: "English, Old (ca. 450-1100)" },
        { value: "anp", label: "Angika" },
        { value: "apa", label: "Apache languages" },
        { value: "ara", label: "Arabic" },
        { value: "arc", label: "Official Aramaic; Imperial Aramaic (700-300 BCE)" },
        { value: "arg", label: "Aragonese" },
        { value: "arm", label: "Armenian" },
        { value: "arn", label: "Mapudungun; Mapuche" },
        { value: "arp", label: "Arapaho" },
        { value: "art", label: "Artificial languages" },
        { value: "arw", label: "Arawak" },
        { value: "asm", label: "Assamese" },
        { value: "ast", label: "Asturian; Bable; Leonese; Asturleonese" },
        { value: "ath", label: "Athapascan languages" },
        { value: "aus", label: "Australian languages" },
        { value: "ava", label: "Avaric" },
        { value: "ave", label: "Avestan" },
        { value: "awa", label: "Awadhi" },
        { value: "aym", label: "Aymara" },
        { value: "aze", label: "Azerbaijani" },
        { value: "bad", label: "Banda languages" },
        { value: "bai", label: "Bamileke languages" },
        { value: "bak", label: "Bashkir" },
        { value: "bal", label: "Baluchi" },
        { value: "bam", label: "Bambara" },
        { value: "ban", label: "Balinese" },
        { value: "baq", label: "Basque" },
        { value: "bas", label: "Basa" },
        { value: "bat", label: "Baltic languages" },
        { value: "bej", label: "Beja; Bedawiyet" },
        { value: "bel", label: "Belarusian" },
        { value: "bem", label: "Bemba" },
        { value: "ben", label: "Bengali" },
        { value: "ber", label: "Berber languages" },
        { value: "bho", label: "Bhojpuri" },
        { value: "bih", label: "Bihari languages" },
        { value: "bik", label: "Bikol" },
        { value: "bin", label: "Bini; Edo" },
        { value: "bis", label: "Bislama" },
        { value: "bla", label: "Siksika" },
        { value: "bnt", label: "Bantu languages" },
        { value: "bos", label: "Bosnian" },
        { value: "bra", label: "Braj" },
        { value: "bre", label: "Breton" },
        { value: "btk", label: "Batak languages" },
        { value: "bua", label: "Buriat" },
        { value: "bug", label: "Buginese" },
        { value: "bul", label: "Bulgarian" },
        { value: "bur", label: "Burmese" },
        { value: "byn", label: "Blin; Bilin" },
        { value: "cad", label: "Caddo" },
        { value: "cai", label: "Central American Indian languages" },
        { value: "car", label: "Galibi Carib" },
        { value: "cat", label: "Catalan" },
        { value: "cau", label: "Caucasian languages" },
        { value: "ceb", label: "Cebuano" },
        { value: "cel", label: "Celtic languages" },
        { value: "cha", label: "Chamorro" },
        { value: "chb", label: "Chibcha" },
        { value: "che", label: "Chechen" },
        { value: "chg", label: "Chagatai" },
        { value: "chi", label: "Chinese" },
        { value: "chk", label: "Chuukese (Truk)" },
        { value: "chm", label: "Mari" },
        { value: "chn", label: "Chinook jargon" },
        { value: "cho", label: "Choctaw" },
        { value: "chp", label: "Chipewyan; Dene Suline" },
        { value: "chr", label: "Cherokee" },
        { value: "chu", label: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic" },
        { value: "chv", label: "Chuvash" },
        { value: "chy", label: "Cheyenne" },
        { value: "cmc", label: "Chamic languages" },
        { value: "cop", label: "Coptic" },
        { value: "cor", label: "Cornish" },
        { value: "cos", label: "Corsican" },
        { value: "cpe", label: "Creoles and pidgins, English-based" },
        { value: "cpf", label: "Creoles and pidgins, French-based" },
        { value: "cpp", label: "Creoles and pidgins, Portuguese-based" },
        { value: "cre", label: "Cree" },
        { value: "crh", label: "Crimean Turkish; Crimean Tatar" },
        { value: "crp", label: "Creoles and pidgins" },
        { value: "csb", label: "Kashubian" },
        { value: "cus", label: "Cushitic languages" },
        { value: "cze", label: "Czech" },
        { value: "dak", label: "Dakota" },
        { value: "dan", label: "Danish" },
        { value: "dar", label: "Dargwa" },
        { value: "day", label: "Land Dayak languages" },
        { value: "del", label: "Delaware" },
        { value: "den", label: "Slave (Athapascan)" },
        { value: "dgr", label: "Dogrib" },
        { value: "din", label: "Dinka" },
        { value: "div", label: "Divehi; Dhivehi; Maldivian" },
        { value: "doi", label: "Dogri" },
        { value: "dra", label: "Dravidian languages" },
        { value: "dsb", label: "Lower Sorbian" },
        { value: "dua", label: "Duala" },
        { value: "dum", label: "Dutch, Middle (ca. 1050-1350)" },
        { value: "dut", label: "Dutch; Flemish" },
        { value: "dyu", label: "Dyula" },
        { value: "dzo", label: "Dzongkha" },
        { value: "efi", label: "Efik" },
        { value: "egy", label: "Egyptian (Ancient)" },
        { value: "eka", label: "Ekajuk" },
        { value: "elx", label: "Elamite" },
        { value: "eng", label: "English" },
        { value: "enm", label: "English, Middle (1100-1500)" },
        { value: "epo", label: "Esperanto" },
        { value: "est", label: "Estonian" },
        { value: "ewe", label: "Ewe" },
        { value: "ewo", label: "Ewondo" },
        { value: "fan", label: "Fang" },
        { value: "fao", label: "Faroese" },
        { value: "fat", label: "Fanti" },
        { value: "fij", label: "Fijian" },
        { value: "fil", label: "Filipino; Pilipino" },
        { value: "fin", label: "Finnish" },
        { value: "fiu", label: "Finno-Ugrian languages" },
        { value: "fkv", label: "Kvensk" },
        { value: "fon", label: "Fon" },
        { value: "fre", label: "French" },
        { value: "frm", label: "French, Middle (ca. 1400-1600)" },
        { value: "fro", label: "French, Old (ca. 842-1400)" },
        { value: "frr", label: "Northern Frisian" },
        { value: "frs", label: "Eastern Frisian" },
        { value: "fry", label: "Western Frisian" },
        { value: "ful", label: "Fulah" },
        { value: "fur", label: "Friulian" },
        { value: "gaa", label: "G&atilde;" },
        { value: "gay", label: "Gayo" },
        { value: "gba", label: "Gbaya" },
        { value: "gem", label: "Germanic languages" },
        { value: "geo", label: "Georgian" },
        { value: "ger", label: "German" },
        { value: "gez", label: "Ethiopic (Ge&rsquo;ez)" },
        { value: "gil", label: "Gilbertese" },
        { value: "gla", label: "Scottish Gaelic" },
        { value: "gle", label: "Irish" },
        { value: "glg", label: "Galician" },
        { value: "glv", label: "Manx" },
        { value: "gmh", label: "German, Middle High (ca. 1050-1500)" },
        { value: "goh", label: "German, Old High (ca. 750-1050)" },
        { value: "gon", label: "Gondi" },
        { value: "gor", label: "Gorontalo" },
        { value: "got", label: "Gothic" },
        { value: "grb", label: "Grebo" },
        { value: "grc", label: "Greek, Ancient (to 1453)" },
        { value: "gre", label: "Greek, Modern (1453-)" },
        { value: "grn", label: "Guarani" },
        { value: "gsw", label: "Swiss German; Alemannic" },
        { value: "guj", label: "Gujarati" },
        { value: "gwi", label: "Gwich&rsquo;in" },
        { value: "hai", label: "Haida" },
        { value: "hat", label: "Haitian French Creole" },
        { value: "hau", label: "Hausa" },
        { value: "haw", label: "Hawaiian" },
        { value: "heb", label: "Hebrew" },
        { value: "her", label: "Herero" },
        { value: "hil", label: "Hiligaynon" },
        { value: "him", label: "Himachali languages; Western Pahari languages" },
        { value: "hin", label: "Hindi" },
        { value: "hit", label: "Hittite" },
        { value: "hmn", label: "Hmong; Mong" },
        { value: "hmo", label: "Hiri Motu" },
        { value: "hrv", label: "Croatian" },
        { value: "hsb", label: "Upper Sorbian" },
        { value: "hun", label: "Hungarian" },
        { value: "hup", label: "Hupa" },
        { value: "iba", label: "Iban" },
        { value: "ibo", label: "Igbo" },
        { value: "ice", label: "Icelandic" },
        { value: "ido", label: "Ido" },
        { value: "iii", label: "Sichuan Yi; Nuosu" },
        { value: "ijo", label: "Ijo languages" },
        { value: "iku", label: "Inuktitut" },
        { value: "ile", label: "Interlingue; Occidental" },
        { value: "ilo", label: "Iloko" },
        { value: "ina", label: "Interlingua (International Auxiliary Language Association)" },
        { value: "inc", label: "Indic languages" },
        { value: "ind", label: "Indonesian" },
        { value: "ine", label: "Indo-European languages" },
        { value: "inh", label: "Ingush" },
        { value: "ipk", label: "Inupiaq" },
        { value: "ira", label: "Iranian languages" },
        { value: "iro", label: "Iroquoian languages" },
        { value: "ita", label: "Italian" },
        { value: "jav", label: "Javanese" },
        { value: "jbo", label: "Lojban" },
        { value: "jpn", label: "Japanese" },
        { value: "jpr", label: "Judeo-Persian" },
        { value: "jrb", label: "Judeo-Arabic" },
        { value: "kaa", label: "Kara-Kalpak" },
        { value: "kab", label: "Kabyle" },
        { value: "kac", label: "Kachin; Jingpho" },
        { value: "kal", label: "Kal&acirc;tdlisut; Greenlandic" },
        { value: "kam", label: "Kamba" },
        { value: "kan", label: "Kannada" },
        { value: "kar", label: "Karen languages" },
        { value: "kas", label: "Kashmiri" },
        { value: "kau", label: "Kanuri" },
        { value: "kaw", label: "Kawi" },
        { value: "kaz", label: "Kazakh" },
        { value: "kbd", label: "Kabardian (Circassian)" },
        { value: "kha", label: "Khasi" },
        { value: "khi", label: "Khoisan languages" },
        { value: "khm", label: "Central Khmer" },
        { value: "kho", label: "Khotanese; Sakan" },
        { value: "kik", label: "Kikuyu; Gikuyu" },
        { value: "kin", label: "Kinyarwanda" },
        { value: "kir", label: "Kirghiz; Kyrgyz" },
        { value: "kmb", label: "Kimbundu" },
        { value: "kok", label: "Konkani" },
        { value: "kom", label: "Komi" },
        { value: "kon", label: "Kongo" },
        { value: "kor", label: "Korean" },
        { value: "kos", label: "Kusaiean (Caroline Islands)" },
        { value: "kpe", label: "Kpelle" },
        { value: "krc", label: "Karachay-Balkar" },
        { value: "krl", label: "Karelian" },
        { value: "kro", label: "Kru languages" },
        { value: "kru", label: "Kurukh" },
        { value: "kua", label: "Kuanyama" },
        { value: "kum", label: "Kumyk" },
        { value: "kur", label: "Kurdish" },
        { value: "kut", label: "Kutenai" },
        { value: "lad", label: "Ladino" },
        { value: "lah", label: "Lahnda" },
        { value: "lam", label: "Lamba" },
        { value: "lao", label: "Lao" },
        { value: "lat", label: "Latin" },
        { value: "lav", label: "Latvian" },
        { value: "lez", label: "Lezgian" },
        { value: "lim", label: "Limburgish" },
        { value: "lin", label: "Lingala" },
        { value: "lit", label: "Lithuanian" },
        { value: "lol", label: "Mongo-Nkundu" },
        { value: "loz", label: "Lozi" },
        { value: "ltz", label: "Luxembourgish; Letzeburgesch" },
        { value: "lua", label: "Luba-Lulua" },
        { value: "lub", label: "Luba-Katanga" },
        { value: "lug", label: "Ganda" },
        { value: "lui", label: "Luise&ntilde;o" },
        { value: "lun", label: "Lunda" },
        { value: "luo", label: "Luo (Kenya and Tanzania)" },
        { value: "lus", label: "Lushai" },
        { value: "mac", label: "Macedonian" },
        { value: "mad", label: "Madurese" },
        { value: "mag", label: "Magahi" },
        { value: "mah", label: "Marshallese" },
        { value: "mai", label: "Maithili" },
        { value: "mak", label: "Makasar" },
        { value: "mal", label: "Malayalam" },
        { value: "man", label: "Mandingo" },
        { value: "mao", label: "Maori" },
        { value: "map", label: "Austronesian languages" },
        { value: "mar", label: "Marathi" },
        { value: "mas", label: "Masai" },
        { value: "may", label: "Malay" },
        { value: "mdf", label: "Moksha" },
        { value: "mdr", label: "Mandar" },
        { value: "men", label: "Mende" },
        { value: "mga", label: "Irish, Middle (ca. 1100-1550)" },
        { value: "mic", label: "Mi&rsquo;kmaq; Micmac" },
        { value: "min", label: "Minangkabau" },
        { value: "mis", label: "Uncoded languages" },
        { value: "mkh", label: "Mon-Khmer languages" },
        { value: "mlg", label: "Malagasy" },
        { value: "mlt", label: "Maltese" },
        { value: "mnc", label: "Manchu" },
        { value: "mni", label: "Manipuri" },
        { value: "mno", label: "Manobo languages" },
        { value: "moh", label: "Mohawk" },
        { value: "mol", label: "Moldavian; Moldovan" },
        { value: "mon", label: "Mongolian" },
        { value: "mos", label: "Moor&eacute;; Mossi" },
        { value: "mul", label: "Multiple languages" },
        { value: "mun", label: "Munda languages" },
        { value: "mus", label: "Creek" },
        { value: "mwl", label: "Mirandese" },
        { value: "mwr", label: "Marwari" },
        { value: "myn", label: "Mayan languages" },
        { value: "myv", label: "Erzya" },
        { value: "nah", label: "Nahuatl languages" },
        { value: "nai", label: "North American Indian languages" },
        { value: "nap", label: "Neapolitan" },
        { value: "nau", label: "Nauruan" },
        { value: "nav", label: "Navajo" },
        { value: "nbl", label: "Ndebele, South" },
        { value: "nde", label: "Ndebele, North" },
        { value: "ndo", label: "Ndonga" },
        { value: "nds", label: "Low German; Low Saxon" },
        { value: "nep", label: "Nepali" },
        { value: "new", label: "Newari; Nepal Bhasa" },
        { value: "nia", label: "Nias" },
        { value: "nic", label: "Niger-Kordofanian languages" },
        { value: "niu", label: "Niuean" },
        { value: "nno", label: "Norwegian Nynorsk" },
        { value: "nob", label: "Norwegian Bokm&aring;l" },
        { value: "nog", label: "Nogai" },
        { value: "non", label: "Old Norse" },
        { value: "nor", label: "Norwegian" },
        { value: "nqo", label: "N&rsquo;Ko" },
        { value: "nso", label: "Pedi; Sepedi; Northern Sotho" },
        { value: "nub", label: "Nubian languages" },
        { value: "nwc", label: "Classical Newari; Old Newari; Classical Nepal Bhasa" },
        { value: "nya", label: "Chichewa; Chewa; Nyanja" },
        { value: "nym", label: "Nyamwezi" },
        { value: "nyn", label: "Nyankole" },
        { value: "nyo", label: "Nyoro" },
        { value: "nzi", label: "Nzima" },
        { value: "oci", label: "Occitan (post 1500)" },
        { value: "oji", label: "Ojibwa" },
        { value: "ori", label: "Oriya" },
        { value: "orm", label: "Oromo" },
        { value: "osa", label: "Osage" },
        { value: "oss", label: "Ossetian; Ossetic" },
        { value: "ota", label: "Turkish, Ottoman" },
        { value: "oto", label: "Otomian languages" },
        { value: "paa", label: "Papuan languages" },
        { value: "pag", label: "Pangasinan" },
        { value: "pal", label: "Pahlavi" },
        { value: "pam", label: "Pampanga; Kapampangan" },
        { value: "pan", label: "Panjabi" },
        { value: "pap", label: "Papiamento" },
        { value: "pau", label: "Palauan" },
        { value: "peo", label: "Old Persian (ca. 600-400 B.C.)" },
        { value: "per", label: "Persian" },
        { value: "phi", label: "Philippine languages" },
        { value: "phn", label: "Phoenician" },
        { value: "pli", label: "Pali" },
        { value: "pol", label: "Polish" },
        { value: "pon", label: "Ponapeian" },
        { value: "por", label: "Portuguese" },
        { value: "pra", label: "Prakrit languages" },
        { value: "pro", label: "Proven&ccedil;al, Old (to 1500); Occitan, Old (to 1500)" },
        { value: "pus", label: "Pushto; Pashto" },
        { value: "qar", label: "Aran&eacute;s" },
        { value: "qav", label: "Valencian" },
        { value: "que", label: "Quechua" },
        { value: "raj", label: "Rajasthani" },
        { value: "rap", label: "Rapanui" },
        { value: "rar", label: "Rarotongan; Cook Islands Maori" },
        { value: "roa", label: "Romance languages" },
        { value: "roh", label: "Romansh" },
        { value: "rom", label: "Romany" },
        { value: "rum", label: "Romanian" },
        { value: "run", label: "Rundi" },
        { value: "rup", label: "Aromanian; Arumanian; Macedo-Romanian" },
        { value: "rus", label: "Russian" },
        { value: "sad", label: "Sandawe" },
        { value: "sag", label: "Sango" },
        { value: "sah", label: "Yakut" },
        { value: "sai", label: "South American Indian languages" },
        { value: "sal", label: "Salishan languages" },
        { value: "sam", label: "Samaritan Aramaic" },
        { value: "san", label: "Sanskrit" },
        { value: "sas", label: "Sasak" },
        { value: "sat", label: "Santali" },
        { value: "scc", label: "Serbian" },
        { value: "scn", label: "Sicilian" },
        { value: "sco", label: "Scots (lallans)" },
        { value: "scr", label: "Croatian" },
        { value: "sel", label: "Selkup" },
        { value: "sem", label: "Semitic languages" },
        { value: "sga", label: "Irish, Old (to 1100)" },
        { value: "sgn", label: "Sign languages" },
        { value: "shn", label: "Shan" },
        { value: "sid", label: "Sidamo" },
        { value: "sin", label: "Sinhala; Sinhalese" },
        { value: "sio", label: "Siouan languages" },
        { value: "sit", label: "Sino-Tibetan languages" },
        { value: "sla", label: "Slavic languages" },
        { value: "slo", label: "Slovak" },
        { value: "slv", label: "Slovenian" },
        { value: "sma", label: "Southern Sami" },
        { value: "sme", label: "Northern Sami" },
        { value: "smi", label: "Sami languages" },
        { value: "smj", label: "Lule Sami" },
        { value: "smn", label: "Inari Sami" },
        { value: "smo", label: "Samoan" },
        { value: "sms", label: "Skolt Sami" },
        { value: "sna", label: "Shona" },
        { value: "snd", label: "Sindhi" },
        { value: "snk", label: "Soninke" },
        { value: "sog", label: "Sogdian" },
        { value: "som", label: "Somali" },
        { value: "son", label: "Songhai languages" },
        { value: "sot", label: "Sotho; Sesotho" },
        { value: "spa", label: "Spanish" },
        { value: "srd", label: "Sardinian" },
        { value: "srn", label: "Sranan Tongo" },
        { value: "srp", label: "Serbian" },
        { value: "srr", label: "Serer" },
        { value: "ssa", label: "Nilo-Saharan languages" },
        { value: "ssw", label: "Swazi; Swati" },
        { value: "suk", label: "Sukuma" },
        { value: "sun", label: "Sundanese" },
        { value: "sus", label: "Susu" },
        { value: "sux", label: "Sumerian" },
        { value: "swa", label: "Swahili" },
        { value: "swe", label: "Swedish" },
        { value: "syc", label: "Classical Syriac" },
        { value: "syr", label: "Syriac" },
        { value: "tah", label: "Tahitian" },
        { value: "tai", label: "Tai languages" },
        { value: "tam", label: "Tamil" },
        { value: "tat", label: "Tatar" },
        { value: "tel", label: "Telugu" },
        { value: "tem", label: "Temne; Time" },
        { value: "ter", label: "Terena" },
        { value: "tet", label: "Tetum" },
        { value: "tgk", label: "Tajik" },
        { value: "tgl", label: "Tagalog" },
        { value: "tha", label: "Thai" },
        { value: "tib", label: "Tibetan" },
        { value: "tig", label: "Tigr&eacute;" },
        { value: "tir", label: "Tigrinya" },
        { value: "tiv", label: "Tiv" },
        { value: "tkl", label: "Tokelauan" },
        { value: "tlh", label: "Klingon; tlhIngan-Hol" },
        { value: "tli", label: "Tlingit" },
        { value: "tmh", label: "Tamashek" },
        { value: "tog", label: "Tonga (Nyasa)" },
        { value: "ton", label: "Tongan" },
        { value: "tpi", label: "Tok Pisin" },
        { value: "tsi", label: "Tsimshian" },
        { value: "tsn", label: "Tswana" },
        { value: "tso", label: "Tsonga" },
        { value: "tuk", label: "Turkmen" },
        { value: "tum", label: "Tumbuka" },
        { value: "tup", label: "Tupi languages" },
        { value: "tur", label: "Turkish" },
        { value: "tut", label: "Altaic languages" },
        { value: "tvl", label: "Tuvaluan" },
        { value: "twi", label: "Twi" },
        { value: "tyv", label: "Tuvinian" },
        { value: "udm", label: "Udmurt" },
        { value: "uga", label: "Ugaritic" },
        { value: "uig", label: "Uighur; Uyghur" },
        { value: "ukr", label: "Ukrainian" },
        { value: "umb", label: "Umbundu" },
        { value: "und", label: "Undetermined language" },
        { value: "urd", label: "Urdu" },
        { value: "uzb", label: "Uzbek" },
        { value: "vai", label: "Vai" },
        { value: "ven", label: "Venda" },
        { value: "vie", label: "Vietnamese" },
        { value: "vol", label: "Volap&uuml;k" },
        { value: "vot", label: "Votic" },
        { value: "wak", label: "Wakashan languages" },
        { value: "wal", label: "Wolaitta; Wolaytta" },
        { value: "war", label: "Waray" },
        { value: "was", label: "Washo" },
        { value: "wel", label: "Welsh" },
        { value: "wen", label: "Sorbian languages" },
        { value: "wln", label: "Walloon" },
        { value: "wol", label: "Wolof" },
        { value: "xal", label: "Kalmyk" },
        { value: "xho", label: "Xhosa" },
        { value: "yao", label: "Yao" },
        { value: "yap", label: "Yapese" },
        { value: "yid", label: "Yiddish" },
        { value: "yor", label: "Yoruba" },
        { value: "ypk", label: "Yupik languages" },
        { value: "zap", label: "Zapotec" },
        { value: "zbl", label: "Blissymbols; Blissymbolics; Bliss" },
        { value: "zen", label: "Zenaga" },
        { value: "zha", label: "Zhuang; Chuang" },
        { value: "znd", label: "Zande languages" },
        { value: "zul", label: "Zulu" },
        { value: "zun", label: "Zuni" },
        { value: "zxx", label: "No linguistic content" },
        { value: "zza", label: "Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki" }
    ];

export const history = {
    on_hand: 3,
    on_order: 2,
    peer_on_hand: [36, 20],
    peer_on_order: [4, 2],
    counts: [
        {
            key: "Adj",
            color: "#C71585",
            values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            key: "Retn",
            color: "#9932CC",
            values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            key: "Rcvd",
            color: "#228B22",
            values: [0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 2, 0, 0, 0, 0]
        },
        {
            key: "Sold",
            color: "#000000",
            values: [0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 0]
        },
        {
            key: "Peer",
            color: "#A52A2A",
            values: [0, 22, 0, 0, 0, 0, 0, 0, 5, 24, 11, 16, 7, 5, 2, 0, 0]
        }
    ]
}

export const exceloptions = [
    {
        label: "Include ISBN barcode",
        value: "show_barcodes",
        checked: false,
    },
    {
        label: "Include \"Print Status\" column",
        value: "show_print_status",
        checked: false,
    },
    {
        label: "Include \"My Lists\" column to reference other lists",
        value: "show_my_lists",
        checked: false,
    },
    {
        label: "Include comments column",
        value: "show_comments",
        checked: false,
    },
    {
        label: "Include URLs",
        value: "include_urls",
        checked: false,
    }
]

export const pdflayouts = [
    {
        label: "15 per page (3 columns)",
        key: "threebyfive",
        value: "15"
    },

    {
        label: "8 per page (2 columns)",
        key: "twobyfour",
        value: "8"
    },

    {
        label: "5 per page (1 column)",
        key: "onebyfive",
        value: "5"
    },

    {
        label: "2 per page (1 column)",
        key: "onebytwo",
        value: "2"
    },

    {
        label: "1 per page (1 column)",
        key: "onebyone",
        value: "15"
    }
];

export const pdfoptions = [
    {
        label: "Use Sans Serif?",
        value: "is_sans_serif",
        checked: true,
    },
    {
        label: "Print Quality Images?",
        value: "is_print_quality",
        checked: false,
    },
    {
        label: "Include Onhand and Onorder",
        value: "show_ohoo",
        checked: false,
    },
    {
        label: "Include Carton Quantity",
        value: "show_ctn_qty",
        checked: true,
    },
    {
        label: "Barcodes",
        value: "show_barcodes",
        checked: false,
    },
    {
        label: "Show My Price (if in stock)",
        value: "show_storeprice",
        checked: true,
    },
    {
        label: "Fallback to List Price if not in stock",
        value: "show_listprices",
        checked: true,
    },
    {
        label: "Include Cover Page",
        value: "include_cover",
        checked: false,
    },
    {
        label: "Include Public Comments (layouts: 1 per, 2 per, 5 per)",
        value: "show_public_comments",
        checked: false,
    },
    {
        label: "Include Private Comments (layouts: 1 per, 2 per, 5 per)",
        value: "show_private_comments",
        checked: false,
    },
    {
        label: "Include Reviews (layouts: 1 per, 2 per)",
        value: "show_reviews",
        checked: false,
    },
    {
        label: "Include Biographies (layouts: 1 per, 2 per)",
        value: "show_biographies",
        checked: false,
    },
    {
        label: "Marketing (for internal use) (layouts: 1 per)",
        value: "show_marketing",
        checked: false,
    }
]