import { Button, Collapse, Form, Input, InputNumber, message, Popover, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall } from '../../utils/Api';
import conditional from '../../utils/conditional';

export default function IndividualSuggestedCartsOnBrowse(props){

    const {
        cart = "",
        isbn = "",
        stores = [],
        is_added = false,
        separateSuggestionValues = false,
        setSyncedIsAdded = () => {},
        setSuggestedCartsWorkingWith = () => {},
    }= props
    const stores_array = stores.length > 0 ? stores : stores.stores

    const [isAddedToCart, setIsAddedToCart] = useState(is_added);
    const [notes, setNotes] = useState(setStartingValues("notes"));
    const [msValues, setMSValues] = useState(initialMSArrayValues());
    const [quantity, setQuantity] = useState(setStartingValues("quantity"));

    function setStartingValues(value_to_set){
        let temp_value = value_to_set === "notes" ? "" : 0;
        if(cart.rows.some((row) => row.isbn === isbn)){
            if(value_to_set === "quantity"){
                let temp_quantity = cart.rows.find((row) => row.isbn === isbn).quantity
                temp_value = temp_quantity > 0 ? temp_quantity : 0;
            }else if(value_to_set === "notes"){
                let temp_notes = cart.rows.find((row) => row.isbn === isbn).note
                temp_value = temp_notes !== "" ? temp_notes : "";
            }
        }
        return temp_value;
    }

    function initialMSArrayValues(){
        let initial_array = [];
        if(stores_array.length > 1){
            if(cart.rows.some((row) => row.isbn === isbn)){
                initial_array = cart.rows.find((row) => row.isbn === isbn).ms_quantity;
            }
            else{
                for(let iterator = 0; iterator < stores.length; iterator++){
                    initial_array.push(0)
                }
            }
        }
        return initial_array;
    }

    function addEditSuggestedCart(data){
        let args = {
            quantity: quantity,
        }
        if(msValues.length > 0){
            args.ms_quantity = JSON.stringify(msValues);
            let initial_value = 0
            args.quantity = msValues.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                initial_value,
            );
            if(args.quantity === 0){
                args.quantity = data.qty
            }
        }
        
        if(notes !== false){
            args.note = notes;
        }
        let api_call_text = "suggested_cart/addItem";
        if(isAddedToCart){
            api_call_text = "suggested_cart/updateItem";
            
            args.cart_item_id = cart.rows.find((row) => row.isbn === isbn).id;
        }else{
            args.cart_id = cart.id;
            args.isbn = isbn;
        }
        apiCall(api_call_text, args, (_status, _result) => {
            if(_status){
                setSuggestedCartsWorkingWith(cart);
                setSyncedIsAdded(true)
                setIsAddedToCart(true)
                message.success("Successfully added this item to: " + cart.name);
            }
        })
    }

    function adjustMSQty(e, item, index) {
        let temp_ms_values = [...msValues];
        temp_ms_values[index] = e;
        setMSValues(temp_ms_values)
    }
    
    function drawMSSection(){
        return(
            <>
                {stores_array.map((item, index) => {
                    return (
                        <div style={{"padding": "5px"}}>
                            <Tooltip placement="top" title={item.name}>
                                <label>{item.altname}: </label>
                            </Tooltip>
                            <Form.Item>
                                <InputNumber
                                    style={{"width": "75px"}}
                                    size="small"
                                    defaultValue={msValues[index] ? msValues[index] : 0}
                                    onChange={(e) => {adjustMSQty(e, item, index)}}
                                />
                            </Form.Item>
                        </div>
                    )
                })}
            </>
        )
    }

    function drawNotesPopUp(){
        return(
            <Input.TextArea
                defaultValue={notes}
                showCount
                maxLength={255}
                style={{"height": "80px"}}
                onChange={(e) => setNotes(e.target.value)}
            />
        )
    }

    function removeFromSC(){
        const cart_item_id = cart.rows.find((row) => row.isbn === isbn).id;
        apiCall("suggested_cart/removeItem", {cart_item_id: cart_item_id}, (_status, _result) => {
            if(_status){
                setSuggestedCartsWorkingWith(cart)
                setIsAddedToCart(false)
                //synced is for DrawSuggestedCarts, so you can see the little notification telling you which is in a cart at a glance
                setSyncedIsAdded(false)
                setMSValues([]);
                message.success("Removed this item from: " + cart.name);
            }
        })
    }

    return (
        <Form onFinish={(_f) => addEditSuggestedCart(_f)} layout="horizontal" style={{"padding-left": "5px", "padding-right": "5px"}}>
            <Button 
                style={{"float": "right"}}
                size='small' 
                htmlType="submit" 
                type="primary">{isAddedToCart ? "Update Cart" : "Add"}</Button>
            <Popover style={{"float": "right"}} trigger={"click"} title="Title" content={drawNotesPopUp()}>
                <Button style={{"float": "right"}} size='small' type="link">Notes</Button>
            </Popover> 
            <conditional.true value={isAddedToCart}>
                <Button  
                    style={{"float": "right"}} 
                    size='small' 
                    onClick={() => removeFromSC()} danger>Remove
                </Button>
            </conditional.true>
            <Row>
                <conditional.true value={!separateSuggestionValues}>
                    <Row>
                        <Form.Item name="qty" label="Qty">
                            <InputNumber 
                                size="small" 
                                min={0} 
                                max={50} 
                                defaultValue={quantity}
                                onChange={(e) => setQuantity(e)}
                                style={{"width": "65px"}}
                            />
                        </Form.Item>
                    </Row>
                </conditional.true>
                <conditional.true value={separateSuggestionValues}>
                    <Row>
                        {drawMSSection()}
                    </Row>
                </conditional.true>
            </Row>
        </Form>
    )
}