import { Checkbox, Divider, message, Space, Tooltip } from "antd";
import React from "react";
import { useSession } from "../utils/Session";
import { apiCall } from "../utils/Api";

export default function Newsletters() {

    const [session, setSession] = useSession();

    const tips = session?.newsletters?.tips;
    const best_on_shelf = session.newsletters?.best_on_shelf;

    const toggleBestOnShelf = () => {
        apiCall("bestonshelf/set", { subscribed: !best_on_shelf }, (_s, _r) => {
            if (_s) {
                setSession({ ...session, "newsletters": { ...session.newsletters, "best_on_shelf": !best_on_shelf } })
            } else {
                message.error(_r.error)
            }
        })
    }

    const toggleNewsletter = () => {
        apiCall("account/setTipNewletter", { subscribed: !tips }, (_s, _r) => {
            if (_s) {
                setSession({ ...session, "newsletters": { ...session.newsletters, "tips": !tips } })
            } else {
                message.error(_r.error)
            }
        })
    }

    return (
        <>
            Bookmanager Newsletters
            <Divider dashed style={{ "margin": "5px 0px" }} />
            <div className="shim" />

            <Tooltip placement="left" title={<small>This is Bookmanager's weekly sales stats round-up that is sent to reps, publishers, distributors, etc. Bookmanager compiles bestseller lists each week, based on data from indie bookstores across Canada. <div className="shim" /><div className="shim" />If you are a subscriber you will have access to stats for your current bestsellers, recent releases and forthcoming titles. This email also includes a link to an XLS download of our national bestseller report, as well as shortcuts to your missing data report, catalogues, and instructions on how to use our Shop Local API.</small>}>
                <Checkbox onChange={() => toggleBestOnShelf()} checked={best_on_shelf}><small>BestOnTheShelf</small></Checkbox>
            </Tooltip>
            <div className="shim" />
            <Tooltip placement="left" title={<small>This is Bookmanager's weekly news and tips email, with a bookstore-focus on the software and web features. New bookstore announcements are also featured here periodically.</small>}>
                <Checkbox onChange={() => toggleNewsletter()} checked={tips}><small>News, Tips & Sales Comparison</small></Checkbox>
            </Tooltip>

            <div className="shim" />
            <Divider dashed style={{ "margin": "5px 0px" }} />
        </>
    )


}