import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";

import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";

export default function BusinessContactInfo(props) {

    const { id } = props;
    const [results, setResults] = useState({
        id: "",
        san: "",
        name: "",
        acct: "",
        addr1: "",
        addr2: "",
        city: "",
        prov: "",
        country: "",
        postal: "",
        phone: "",
        phone2: "",
        tollfree: "",
        fax: "",
        email: "",
        url: "",
        notes: "",
        categories: [],
        changes: [],
        webstore: "",
        edit: false,
        approvedText: ""
    })
    const getContact = () => {

        apiCall("business_directory/getContact", { contact_id: id }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            }
        })
    }

    useEffect(getContact, [id])

    const drawCard = () => {
        // handle bad data - websites without prefix
        let url = "";
        if(results.url){
            if(results.url.substr(0, 4) !== "http"){
                url = "https://" + results.url; 
            } else {
                url = results.url; 
            }
        }

        return (
            <>
                <div style={{ "padding": "8px", "paddingBottom": "0px", "margin": "0px 0px" }}>
                    <div style={{ "float": "right", "textAlign": "right" }}>
                        <Typography.Paragraph style={{ "fontSize": "12px" }}>
                            {(results.url && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Website: </strong><a target="_blank" href={url}>{url}</a></span>)}
                            {(results.phone && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Phone: </strong>{results.phone}</span>)}
                            {(results.phone2 && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Phone: </strong>{results.phone2}</span>)}
                            {(results.tollfree && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Toll Free: </strong>{results.tollfree}</span>)}
                            {(results.fax && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Fax: </strong>{results.fax}</span>)}
                        </Typography.Paragraph>
                    </div>
                    <Typography.Paragraph style={{ "fontSize": "11px" }}>
                        <span style={{ "color": "#069", "fontSize": "12px", "display": "inline" }}><strong>{results.name}</strong>
                        </span>
                        <div style={{ "height": "0px" }} className="shim"></div>
                        <div style={{ "marginTop": "-2px" }}><a href={"mailto:"}>{results.email}</a></div>
                        <div className="shim"></div>
                        <conditional.true value={(results.addr1 || results.addr2)}>
                            {results.addr1} {((results.addr2) && <div>{results.addr2}</div>)} <br />
                        </conditional.true>
                        <conditional.true value={(results.city)}>
                            {results.city}, {results.prov} {results.country} <br />
                        </conditional.true>
                    </Typography.Paragraph>
                </div>
            </>
        )
    }

    return (
        <>
            <Row>
                <Col flex={100}>{drawCard()}</Col>
            </Row>
        </>
    );
}