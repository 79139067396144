/*

Default Layout - Wraps sections with nav, footer etc

*/

import React from "react";
import { Affix, Layout, Menu, Space, Row, Col, Dropdown, Button, Divider, Breadcrumb, BackTop } from 'antd';
import { navigation } from "../data";
import { useHistory } from "react-router-dom";
import logosmall from "../media/logo-small.png";
import {
    SettingOutlined,
    EditOutlined,
    BarChartOutlined,
    DollarOutlined,
    ToolOutlined,
    CustomerServiceOutlined,
    MailOutlined,
} from '@ant-design/icons';
export default function Standard(props) {

    const history = useHistory();
    const linkToPage = (_slug) => {

        //   setSelected(_slug);
        history.push("/" + _slug);
    }


    const getIcon = (_slug) => {
        switch (_slug) {
            case "settings":
                return (<SettingOutlined style={{ "color": "#aaa" }} />);
            case "content":
                return (<EditOutlined style={{ "color": "#aaa" }} />);
            case "reports":
                return (<BarChartOutlined style={{ "color": "#aaa" }} />);
            case "purchasing":
                return (<DollarOutlined style={{ "color": "#aaa" }} />);
            case "tools":
                return (<ToolOutlined style={{ "color": "#aaa" }} />);
            case "support":
                return (<CustomerServiceOutlined style={{ "color": "#aaa" }} />);
        }
    }




    //     <Space>
    //     <Dropdown overlay={menu} placement="bottomLeft" arrow>
    //         <Button type="text">Settings</Button>
    //     </Dropdown>
    // </Space>


    const drawBreadCrumbs = () => {

        return (

            <Breadcrumb>
                <Breadcrumb.Item href="">
                    <span>&nbsp;Settings</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Webstore</Breadcrumb.Item>
            </Breadcrumb>
        )


    }

    const drawSubMenu = (_arr) => {
        return (
            <Menu>
                {_arr.map((item) => {
                    return (
                        <Menu.Item onClick={() => linkToPage(item.slug)} key={item.slug}>
                            {item.name}
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const drawDropdownMenu = () => {
        return navigation.map((item) => {
            return (
                <Dropdown key={item.slug} overlay={() => drawSubMenu(item.subnav)} placement="bottomLeft" arrow>
                    <Button icon={getIcon(item.slug)} style={{ "fontSize": "13px" }} type="text">{item.name}</Button>
                </Dropdown>
            )
        })
    }


    const drawMenu = () => {
        return navigation.map((item) => {
            return (
                <Menu.SubMenu key={item.slug} title={item.name}>
                    {item.subnav.map((_item) => {
                        return (<Menu.Item onClick={() => linkToPage(_item.slug)} key={_item.slug}>{_item.name}</Menu.Item>)
                    })}
                </Menu.SubMenu>
            )
        })
    }


    return (
        <>
            <BackTop style={{"bottom" : "88px"}} />
            <Layout>

                <Layout>
                    <Affix>
                        <div className="shadow" style={{ "backgroundColor": "#fff" }}>
                            <div className="flourish" style={{ "display": "block", "height": "5px" }}></div>
                            <Layout.Header style={{ "maxWidth": "1250px", "margin": "0 auto", "backgroundColor": "#fff", "padding": "0px 15px" }}>

                                <Row>
                                    <Col flex="213px">
                                        <Space>
                                            <img src={logosmall} />
                                            <div ><h4 style={{ "marginBottom": "0px", "marginTop": "2px" }}>&nbsp; Bookmanager Admin</h4></div>
                                            <Divider style={{ "marginLeft": "10px" }} type="vertical" />
                                        </Space>
                                    </Col>
                                    <Col flex="auto">


                                        <Space>
                                            {drawDropdownMenu()}
                                        </Space>

                                    </Col>

                                </Row>


                            </Layout.Header>
                        </div>
                    </Affix>
                    {/* <div style={{ "backgroundColor": "#001529" }}>
                        <Menu style={{ "maxWidth": "1250px", "margin": "0 auto" }} theme="dark" mode="horizontal">
                            {drawMenu()}
                        </Menu>
                    </div> */}

                    <Layout.Content style={{ "width": "100%", "minWidth": "762px", "maxWidth": "1250px", "margin": "0 auto" }}>

                        <div className="mobile15">
                            <div className="shim"></div><div className="shim"></div>
                            {drawBreadCrumbs()}
                            <div className="shim"></div><div className="shim"></div>
                            <div className="shadow" style={{"borderRadius" : "2px"}}>
                            {props.children}
                            </div>
                           
                        </div>
                    </Layout.Content>
                    <div className="shadow" style={{ "backgroundColor": "#fff" }}>
                        <Layout.Footer style={{ "backgroundColor": "#fff", "padding": "13px 50px", "maxWidth": "1250px", "margin": "0 auto" }}>Footer</Layout.Footer>
                    </div>
                </Layout>
            </Layout>
        </>
    );

}