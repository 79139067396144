import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Layout, message, Space, Typography, Upload } from "antd";
import React, { useState } from "react";

import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";

export default function StockTitleUploadPage() {

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const onRemove = (_file) => {
        setFileList([]);
    }

    // disables auto sending file. 
    const beforeUpload = (_file) => {
        setFileList([_file]);
        return false;
    }

    const handleUpload = () => {
        setUploading(true);
        apiCall("interm/upload", { file: fileList[0] }, (_status, _result) => {
            if (_status) {
                message.success("File uploaded successfully.")
            } else {
                message.error("Error: " + _result.error);
            }
            setUploading(false);
        })
    }

    return (
        <>
            <Layout className="layout" >
                <LayoutHeader
                    title="Stock and Title Uploads"
                    description="Use this page to upload your stock or title data information for us to process as an alternative to FTP."
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Card>
                            <p>Select a file and click "Start upload"</p>
                            <Space align="start">
                                <Upload multiple={false} onRemove={(_file) => onRemove(_file)} beforeUpload={(_file) => beforeUpload(_file)}>
                                    <Button disabled={(fileList.length > 0)} icon={<UploadOutlined />}>Select file</Button>
                                </Upload>
                                <Button
                                    type="primary"
                                    onClick={handleUpload}
                                    disabled={fileList.length === 0}
                                    loading={uploading}
                                >
                                    {uploading ? 'Uploading' : 'Start upload'}
                                </Button>
                            </Space>
                        </Card>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}