import { Space, Typography } from "antd";
import React from "react";

export default function AccountCard(props) {

    const { store_name = "", contact = { first_name: "", last_name: "" } } = props;

    return (<div className="accountCard" >
        <div>
            <Space size={0}>
                <div style={{ "textAlign": "right", "paddingLeft": "3px" }}>
                    <Typography.Title style={{ "paddingLeft": "3px", "overflow": "hidden" }} level={5}>
                        <div className="shim"></div>
                        <div style={{ "color": "#4880dc", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap", "maxWidth": "160px", "marginBottom" : "-5px", "marginLeft": "5px" }}>
                            <small>
                            {contact.first_name} {contact.last_name}
                            </small>
                            &nbsp;</div>
                        <div style={{ "display": "block", "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": "nowrap", "color": "#fff", "fontSize": "11px", "fontWeight": "normal", "marginTop": "0px", "opacity": "0.7", "maxWidth": "150px" }}>
                            <small>{store_name}</small>
                        </div>
                    </Typography.Title>
                </div>
            </Space>
        </div>
    </div>)
}