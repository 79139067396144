import { Button, Col, Form, Input, Layout, message, Radio, Row, Select, Space, Spin, Switch, Table, Tabs, Tag, Tooltip, Typography, Upload } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import Overlay from "../../components/Overlay";
import MultiSelectExcel from "../../components/title/multi_select/MultiSelectExcel";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { disabledColor } from "../../utils/Utils";
import { pluralize } from "../../utils/Utils";

export default function TrackingISBNsPage() {


    const [isbns, setIsbns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isbnsSubset, setIsbnsSubset] = useState([]);
    const [useSubset, setUseSubset] = useState(false);
    const [tableOrRawView, setTableOrRawView] = useState(false);
    const [ISBNs, setISBNs] = useState("");
    const [isValidSub, setIsValidSub] = useState(true)
    //If Uploading a file is required, look at ListUpload.js for reference

    const [subscription, setSubscription] = useState({
        changed_ts: 0,
        expires_ts: 0,
        countdown: 0
    });

    const getSubscription = () => {
        apiCall("isbn_tracking/getSubscription", {}, (_status, _result) => {
            if (_status) {
                setSubscription(_result);
            } else {
                setIsValidSub(false);
                message.error(_result.error)
            }
            getIsbns();
        })
    }

    const getIsbns = () => {
        apiCall("isbn_tracking/getIsbns", {}, (_status, _result) => {
            // ---------Results---------
            // date_created: 1677527009
            // eisbn: "fL1Fm1B4rfEK80yqt7U5RQ"
            // is_seen: true
            // isbn: "9780385697378"
            // title: "Lessons in Chemistry"
            if (_status) {
                setIsbns(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        });
    }

    useEffect(getSubscription, []);

    const isbnsToString = () => {
        let ret = ""
        isbns.forEach(item => {
            ret += item.isbn + "\t\t" + item.title + "\n";
        })
        return ret;
    }

    const singleRemove = (isbn_to_be_removed) => {
        let temp_isbns = isbns.filter((item) => isbn_to_be_removed !== item.isbn)
        let isbns_json = temp_isbns.map((item) =>{
            return item.isbn;
        })
        apiCall("isbn_tracking/trackIsbns", {isbns_json: JSON.stringify(isbns_json)}, (_status, _result) => {
            if(_status){
                message.success("Successfully removed ISBN")
                getIsbns();
            }else{
                message.error(_result)
            }
        })
    }

    const columns = [
        {
            title: <small></small>,
            dataIndex: 'eisbn',
            key: 'eisbn',
            width: 50,
            render: (e) => {
                return <img className="lightshadow" style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_im.php?b=" + e} />
            }
        },
        {
            title: <small>ISBN</small>,
            sorter: (a,b) => a.isbn - b.isbn,
            dataIndex: 'isbn',
            key: 'isbn',
            width: 125
        },
        {
            title: <small>Title</small>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title:
                <Tooltip title="First time the sales of the ISBN were viewed">
                    <small>First Viewed</small>
                </Tooltip>,
            dataIndex: 'first_seen',
            key: 'first_seen',
            sorter: (a,b) => a.first_seen - b.first_seen,
            width: 175,
            render: (e, f) => {
                if(!f.is_seen){
                    return("Not yet viewed")
                }else{
                    return(moment(f.first_seen * 1000).format("MMM Do YYYY"))
                }
            }
        },
        {
            title:
                <Tooltip title="If an ISBN is removeable, a button will be displayed">
                    <small>Removable</small>
                </Tooltip>,
            dataIndex: 'is_seen',
            key: 'is_seen',
            width: 175,
            render: (e, f) => {
                if(!f.is_seen){
                    return(<Button 
                        disabled={loading}
                        danger 
                        size="small" 
                        onClick={(e) => singleRemove(f.isbn)}>
                            Remove
                    </Button>)
                }else{
                    return(<small>Viewed, Not Removable</small>)
                }
            }
        },
    ];

    const viewInBrowse = () => {
        let temp_isbns = isbns.map(item => {
            return item.isbn;
        })
        apiCall("browse/getCacheKeyForIsbns", {isbns: JSON.stringify(temp_isbns), name: 'Tracked ISBNs'}, (_status, _result) => {
            if (_status) {
                window.open("/browse/filter/x/" + _result.id + "/v/sequence", "_blank");
            } else {
                message.error(_result.error);
            }
        });
    }

    const trackNewISBN = (e) => {
        let isbns_json = "";
        let temp_isbns = isbns.map(item => {
            return item.isbn;
        })
        if(e.hasOwnProperty("isbn_bulk_input")){
            isbns_json = JSON.stringify(e.isbn_bulk_input.split('\n').concat(temp_isbns))
        }else{ 
            isbns_json = JSON.stringify([e.isbn_input].concat(temp_isbns))
        }
        apiCall("isbn_tracking/trackIsbns", {isbns_json: isbns_json}, (_status, _result) => {
            if(_status){
                message.success("ISBNs successfully added to your current list.")
                getIsbns();
            }else{
                message.error(_result)
            }
        })
    }

    // const openExcelModal = () => {
    //     return (
         
    //     )
    // }

    const copyISBNListToClipboard = () => {
        let clipboard_array = ""
        isbns.forEach(item => {
            clipboard_array += item.isbn + "\n";
        })
        navigator.clipboard.writeText(clipboard_array);
        message.success("ISBN List copied to clipboard")
    }

    const allRemove = () => {
        apiCall("isbn_tracking/trackIsbns", {isbns_json: JSON.stringify([])}, (_status, _result) => {
            if(_status){
                message.success("Removed " + _result.deleted +  pluralize(_result.deleted, " ISBN", "s"))
                getIsbns();
            }else{
                message.error(_result)
            }
        })
    }
    


    const drawForm = () => {
        return (
            <>
                <Row>
                    <Col span={18}>
                        {/* <Row>
                            <Space>
                                <Input.Search placeholder="Search..." style={{"width" : "250px"}} size="small" enterButton />
                                <Select 
                                    size="small" 
                                    style={{"width" : "150px"}} 
                                    onChange={(e) => changeRowOrder(e)}
                                    value={rowOrder}
                                    defaultValue={rowOrder}>
                                    <Select.Option value="entered">As Entered</Select.Option>
                                    <Select.Option value="alpha">Alphabetically</Select.Option>
                                </Select>
                                <Button 
                                    disabled={loading}
                                    danger 
                                    size="small" 
                                    onClick={resetSearchParams}>
                                        Reset Search
                                </Button>
                            </Space>
                        </Row> */}
                        {/* <div className="shim" /><div className="shim" /> */}
                        <Row style={{"marginBottom": "10px"}}>
                            <Col span={16}>
                                <Space>
                                    <Radio.Group
                                        value={tableOrRawView}
                                        defaultChecked={tableOrRawView}
                                        size="small"
                                        onChange={(e) => {
                                            setTableOrRawView(e.target.value)
                                        }}>
                                        <Tooltip title="Detailed view of ISBNs, with more data than the List view">
                                            <Radio.Button style={{"marginRight": "10px"}} value={false}>View Titles</Radio.Button>
                                        </Tooltip>
                                        <Tooltip title="Basic view of ISBNs and Titles, not editable">
                                            <Radio.Button value={true}>View ISBNs</Radio.Button>
                                        </Tooltip>
                                        
                                    </Radio.Group>
                                    <Tooltip title="Clicking this will automatically copy only the ISBNs to your clipboard, so you can paste them somewhere else">
                                        <Button 
                                            size="small" 
                                            onClick={copyISBNListToClipboard}>
                                                Copy ISBNs
                                        </Button>
                                    </Tooltip>
                                    
                                </Space>
                            </Col>
                            <Col span={4} offset={4}>
                                <Tooltip title="Only ISBNs that have not yet been viewed will be removed">
                                    <Button 
                                        disabled={loading}
                                        style={{"float": "right"}}
                                        danger 
                                        size="small" 
                                        onClick={allRemove}>
                                            Remove All Possible
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <conditional.true value={!tableOrRawView}>
                            <Table 
                                scroll={{ y: 547 }} 
                                className="bc" 
                                style={{ "border": "1px solid" }} 
                                pagination={(isbns.length>500) ? {pageSize : 500, showSizeChanger : false} : false}  
                                columns={columns} 
                                dataSource={useSubset ? isbnsSubset : isbns} 
                                size="small">
                            </Table>
                        </conditional.true>
                        <conditional.true value={tableOrRawView}>
                            <Typography.Title level={5}>
                                Raw List of Added ISBNs
                            </Typography.Title>
                            <Input.TextArea 
                                rows={30}
                                value={isbnsToString()} />
                        </conditional.true>
                    </Col>
                    <Col span={6}>
                        <Form style={{"padding": "20px"}} size="small" layout="vertical" onFinish={(e) => trackNewISBN(e)}>
                            <Typography.Title level={5}>
                                Add ISBNs
                            </Typography.Title>
                            <Typography.Paragraph>
                                ISBNs can be added to the current list of Tracked ISBNs in a bulk fashion, one per line.
                            </Typography.Paragraph>
                            <Form.Item name="isbn_bulk_input" label="ISBNs">
                                <Input.TextArea rows={15}/>
                            </Form.Item>
                            <Button size="small" type="primary" htmlType="submit">Update</Button>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }

    function determineSubscriptionEndDate(){
        if(isValidSub){
            return ( <>
                {" "} Your access is available until <strong>
                    {moment(subscription.expires_ts * 1000).format("MMM Do YYYY")}
                </strong>. You may track up to <strong>
                    {subscription.countdown}
                </strong> {pluralize(subscription.countdown, " ISBN", "s")}.
            </>) 
        }
        return (<>
            {" "} Your subscription has expired, you cannot track any new ISBNs.
        </>)
    }

    return (
        <>
            {( !loading && <Layout className="layout">
                <LayoutHeader
                    title={<>Tracked ISBNs</>}
                    description={<>You can access sales and stock trend information for any ISBN entered here. Such information is only accessible with a current subscription.</>}/>
                <Layout.Content style={{ "padding": "20px" }}>
                    <Space>
                        <Button 
                            size="small" 
                            onClick={viewInBrowse} 
                            type="primary">View ISBNs in Browse
                        </Button> 
                        <Button
                            size="small"
                            color={disabledColor}>
                            <Overlay 
                                maskClosable={false} 
                                width={900} 
                                component={
                                    <MultiSelectExcel isbns={isbns.map(item => {return item.isbn;})}/> // title/getExcelOnIsbsns
                                }>Excel
                            </Overlay>
                        </Button>
                    </Space>
                    <div style={{"paddingBottom": "10px"}}>
                        You are tracking <strong>{isbns.length.toString()}</strong> {pluralize(isbns.length, "ISBN", "s")}. You
                        have {subscription.countdown > 0 && <strong>
                            {subscription.countdown - isbns.length.toString()}
                        </strong>} available. 
                        {determineSubscriptionEndDate()}
                    </div>
                    
                    <Spin spinning={loading}>
                        {drawForm()}
                    </Spin>
                </Layout.Content>
            </Layout> )}
        </>
    )
}