import { Alert, Button, Divider, Form, Input, message, Space, Typography } from "antd";
import React, { useState } from "react";

import { apiCall } from "../../utils/Api";

export default function ListsCopy(props) {

    const { results = {}, setResults=() => { }, list_id = "", name = "", close = () => {} } = props;

    const [loading, setLoading] = useState(false)
    const onFinishForm = (_f) => {
        _f["list_id"] = list_id;
        setLoading(true)
        apiCall("titlelist/copy", _f, (_status, _result) => {
            if (_status) {
                let res = {...results};
                let ind = res.rows.findIndex(item => item.list_id === list_id);
                res.rows.splice((ind+1), 0, _result)
                setResults(res);
                close(); 
                message.success("Catalogue copied successfully!")
            }
            setLoading(false)
        })
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                
                <Typography.Title style={{ "marginRight": "20px" }} level={5}>Copy {name}</Typography.Title>
                <Divider />
                <Form onFinish={(_f) => onFinishForm(_f)} layout="vertical">
                    <Form.Item name="new_name" rules={[{ required: true }]} initialValue={"(Copy) " + name} label="New name for list" >
                        <Input  />
                    </Form.Item>
                    <Alert style={{"padding" : "0px 10px", "marginBottom" : "20px"}} type="warning" description={<small>After copying this catalogue, it will be listed under "My Catalogues."</small>} />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Cancel</Button>
                        <Button loading={loading} type="primary" htmlType="submit">Copy List</Button>
                    </Space>
                </div>
                <br clear="all" />
                </Form>
            </div>
        </>
    )
}