import { Modal, Table, Typography } from "antd";
import React, { useState } from "react";
import { disabledColor } from "../../utils/Utils";

export default function Shared(props) {

    const [visible, setVisible] = useState(false);
    const { shared = {} } = props;

    const rows = Array.isArray(shared?.rows) ? shared?.rows : [];
    const count = rows.filter(item => item.is_read).length;
    const total = rows.length;
    const columns = [
        { title: <small>Store</small>, dataIndex: 'name', key: 'name' },
        {
            title: <small>Is client</small>, width: 80, dataIndex: 'is_client', key: 'is_client', render: (e) => {
                return (e) ? "Yes" : "No";
            }
        },

    ];

    const drawShared = () => {
        return (
            <>
                <Typography.Title level={5}>Store views ({count}/{total})</Typography.Title>
                <Table
                    scroll={{ y: 300 }} size="small" pagination={false}
                    columns={columns}
                    dataSource={rows.filter(item => item.is_read)}
                    className={"tableBorder"}
                />
            </>
        )
    }

    if (!count) {
        return (
            <div style={{ "height": "25px", "display": "flex", alignItems: "end" }}>
                <span style={{ "fontSize": "14px", "fontWeight": "400", color : disabledColor, "lineHeight": "16px", "marginBottom": "1px" }}>Store Views ({count})</span>
            </div>

        )
    }

    return (
        <>
            <Modal footer={false} onCancel={() => setVisible(false)} visible={visible}>{drawShared()}</Modal>
            <div style={{ "height": "25px", "display": "flex", alignItems: "end" }}>
                <a style={{ "fontSize": "14px", "fontWeight": "400", "lineHeight": "16px", "marginBottom": "1px" }} onClick={() => setVisible(true)}>Store Views ({count})</a>
            </div>
        </>
    )
}