import React from "react";
import { Modal, message } from "antd";
import { apiCall } from "../../utils/Api";

export default function SuggestedCartDelete(props) {

    const { 
        id = "", 
        name = "", 
        setSuggestedCarts = () => {},
        suggestedCarts = [],
        setRowCount = () => {},
    } = props;

    const confirmDelete = () => {
        Modal.confirm({title : "Delete list?", onOk : () => deleteList(), content : <>Are you sure you want to delete the list "<em style={{"fontWeight" : "600"}}>{name}</em>"?</>})
    }

    const deleteList = () => {
        apiCall("suggested_cart/delete", {cart_id : id}, (_status, _result) => {
            if(_status){
                message.success("List deleted successfully.");
                let _rows = suggestedCarts.filter(item => item.id !== id);
                setRowCount(_rows.length)            
                setSuggestedCarts(_rows);
            } else {
                message.error(_result.error);
            }
        })
    }

    return (
            <div onClick={() => confirmDelete()}>
                {props.children}
            </div>
    );

}