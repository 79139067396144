import { Alert, Button, Divider, Space, Tooltip, Typography } from "antd";

export default function ListSharePublicTab(props) {
    const {
        list = {},
        close = () => { },
        setShared = () => { },
        drawReturnToReportCardButton = () => { },
    } = props;

    const { shared_status = "none", name = "" } = list;

    const is_public = (shared_status === "everyone");

    const drawButton = () => {
        if (is_public) {
            // is public
            return (
                <Tooltip title="This will share the catalogue with ALL stores">
                    <Button type="primary" onClick={() => setShared(false)}>Make Private</Button>
                </Tooltip>
            )
        } else {
            // is private
            return (
                <Tooltip title="This will share the catalogue with ALL stores">
                    <Button type="primary" onClick={() => setShared(true)}>Share Publicly</Button>
                </Tooltip>
            )
        }
    }

    return (
        <div style={{ "padding": "20px" }}>
            <Typography.Title level={5}>Publicly Share: <em>"{name}"</em></Typography.Title>
            <Typography.Paragraph>
                Sharing this publicly means anyone can see this Catalogue. <br />
                Once a store has viewed the catalogue, you can no longer unshare it with them.
            </Typography.Paragraph>
            {(is_public && <Alert type="info" description={<>This catalogue is currently Public. Click "Make Private" to unshare publicly. </>} / >)}
            <Divider />
            <div className="float-flex">
                <Space>
                    {drawReturnToReportCardButton()}
                    <Button type="danger" onClick={() => close()}>Cancel</Button>
                </Space>
                {drawButton()}
            </div>
        </div>
    )
}