import { Button, Descriptions, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";

import nocover from "../../media/no-cover.png";
import { apiCall } from "../../utils/Api";

export default function SuggestedCartDetails(props) {
    const { 
        data = {},
        close = () => {},
    } = props;

    const [titleText, setTitleText] = useState(data.name + " details:");

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const columns = [
        {
            title: 'Cover', width: "30px", dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <>
                    <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "30px", "marginRight": "10px" }} src={"https://cdn1.bookmanager.com/i/b?b=" + e} alt="" />
                </>;
            }
        },
        {
            title: "Title", key: "title", dataIndex: "title"
        },
        {
            title: "ISBN", key: "isbn", dataIndex: "isbn"
        },
        {  
            title: "Total Quantity", key: "quantity", dataIndex: "quantity"
        },
        {
            title: "Multi-Store Quantities", key: "ms_quantity", dataIndex: "ms_quantity", render: (e, f) => {
                let has_ms_quantities = f.ms_quantity.some((element) => element > 0)
                if(has_ms_quantities){
                    return f.ms_quantity.map(item => item).join(", ")
                }
                return "No Multi-Store quantities suggested"
            }
        },
        {
            title: "Author(s)", key: "authors", dataIndex: "authors", render: (e, f) => {
                return f.authors.map(item => item.name).join(", ")
            }
        },
        {
            title: "Notes", key: "note", dataIndex: "note", width: '500px', render: (e, f) => {
                if(f.note !== ""){
                    return f.note
                }
                return "No note"                
            }
        },
        // Keeping this commented out for now. Might want to make this complete after a conversation with Trevor/Diana/Kellynda
        // this is more of a "let's see if it's worth it" thing
        // {
        //     title: <small>Actions</small>, width: "160px", dataIndex: 'folder', key: 'folder', render: (e, f) => {
        //         return (
        //             <Space align="end">
        //                 {drawDeleteIcon(f)}
        //                 {/* remove plus other actions will be here. For now, empty */}
        //             </Space>
        //         )
        //     }
        // },
    ]

    return (
        <div style={{ "padding": "20px" }}>
            <Typography.Title level={3}>{titleText}</Typography.Title>
            <Descriptions
                style={{"paddingBottom": "20px"}}
                title={<Typography.Title level={5}>General Info:</Typography.Title>}>
                <Descriptions.Item label={<span className="c"><strong>Name:</strong></span>}><span className="c">{data.name}</span></Descriptions.Item>
                <Descriptions.Item label={<span className="c"><strong>Shared:</strong></span>}><span className="c"> {data.shared_date !== "" ? data.shared_date : "Not yet shared"}</span></Descriptions.Item>
                <Descriptions.Item label={<span className="c"><strong>Completed:</strong></span>}><span className="c">{data.marked_done_date !== "" ? data.marked_done_date : "Not yet completed"}</span></Descriptions.Item>
                <Descriptions.Item label={<span className="c"><strong>Store Name:</strong></span>}> <span className="c">{data.store_info.name}</span></Descriptions.Item>
                <Descriptions.Item label={<span className="c"><strong>Store SAN:</strong></span>}> <span className="c">{data.store_info.san}</span></Descriptions.Item>
            </Descriptions>
            <Typography.Title level={5}>Title Info:</Typography.Title>
            <Table
                size='small'
                columns={columns}
                dataSource={data.rows}
            />
            <span style={{ "float": "right" }}>
                <Space>
                    <Button type="primary" onClick={(e) => close()}>Close</Button>
                </Space>
            </span>
            <br clear="all" />
        </div>
    )
}