import { CloseOutlined } from '@ant-design/icons';
import { Button, Space, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";

import conditional from "../../utils/conditional";
import { addCommas, disabledColor } from "../../utils/Utils";
import SuggestedCartCreateEdit from "../carts/SuggestedCartCreateEdit";
import SuggestedCartDelete from "../carts/SuggestedCartDelete";
import SuggestedCartExcel from "../carts/SuggestedCartExcel";
import SuggestedCartPDF from "../carts/SuggestedCartPDF";
import SuggestedCartShare from "../carts/SuggestedCartShare";
import ItemLayouts from "../ItemLayouts";
import Overlay from "../Overlay";
import Renderer from "../Renderer";
import Slider from "../Slider";
import SliderItem from "../SliderItem";
import SuggestedCartDetails from './SuggestedCartDetails';

export default function ListSuggestedCarts(props) {

    const { 
        sharedView = false,
        is_catalogue = false, 
        getAllSuggestedCarts = () => {},
        getAllAvailableStores = () => {},
        suggestedCarts = [],
        setSuggestedCarts = () => {},
        drawComponent = () => { }, 
        data = {}, 
        setRowCount = () => {},
        availableStores = {},
    } = props;
    
    const {
        id = "",
        name = "",
        rows = [],
        shared_date = null,
        store_info = null,
        marked_done_date
    } = data;

    const linkOut = (_id) => {
        window.open("/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }

    return (
        <>
            <div className="hideHover" style={{"paddingTop": "15px", "paddingBottom": "5px"}}>
                <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                    <Tooltip title="Click the name to view all these titles in more detail">
                        <a onClick={() => linkOut(id)}>{name}</a>    
                    </Tooltip>
                    <div style={{ "float": "right", "textAlign": "right" }}>
                        <Typography.Paragraph style={{ "fontSize": "12px" }}>
                            <Tooltip title="Store that this Suggested Order is being shared with">
                                <span>Store: </span><span style={{ "fontWeight": "normal" }}>{store_info.name}</span><br />
                            </Tooltip>
                            {(shared_date !== "" && <>
                                <Tooltip title="Date this list was shared with the above store">
                                    <span>Shared: </span><span style={{ "fontWeight": "normal" }}>{moment(shared_date * 1000).format("MMMM D YYYY h:mma")}</span><br />
                                </Tooltip>
                            </>)}
                            {/* Below will be for when a completed value is passed, so we can immediately show when the store has completed working with the cart */}
                            {(marked_done_date !== "" && <>
                                <Tooltip>
                                    <span>Completed: </span><span style={{ "fontWeight": "normal" }}>{moment(marked_done_date * 1000).format("MMMM D YYYY h:mma")}</span><br />
                                </Tooltip>
                            </>)}
                            <span style={{ "color": disabledColor, "fontSize": "12px", "display": "block", "fontWeight": "normal" }}>
                                <em>Suggested Order: {id} </em>&nbsp;
                            </span>
                        </Typography.Paragraph>
                    </div>
                    <div className="shim" />
                </Typography.Title>
                <div className="shim"></div>
                {/* Max 11 wide */}
                <div style={{ "maxHeight": "170px", "width": "1196px" }}>
                    <Slider>
                        <Space>
                            {rows.slice(0, 20).map(img => {
                                return (
                                    <SliderItem key={img.isbn} isbn={img.isbn} action={() => drawComponent(<div key={img.isbn}>
                                        <div style={{ "padding": "0 0px" }}>
                                            <div style={{ "marginTop": "0px", "borderTop": "1px solid" }} className="closebar bc">
                                                <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                                                <br clear="both" />
                                            </div>
                                        </div>
                                        <div className="shim" /><div className="shim" />
                                        <Renderer><ItemLayouts display="details2" isbn={img.isbn} /></Renderer>
                                        <div className="shim" /><div className="shim" />
                                    </div>)}>
                                    <img className="shadow-large" style={{ "maxHeight": "150px" }} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/slider_im.php?b=" + img.eisbn} />
                                    </SliderItem>
                                )
                            })}
                            {((rows.length > 20) && <div onClick={() => linkOut(id)} style={{ "textAlign": "center", "width": "100px" }}><small>View all <br />{rows.length} titles</small></div>)}
                        </Space>
                    </Slider>
                </div>
            </div>
            <Space size={0} style={{"paddingBottom": "15px"}}>
                <Tooltip title="A detailed view of everything to do with this Suggested Order">
                    <Button 
                        style={{"marginRight": "5px"}}
                        type="primary" 
                        size="small"><small>
                            <Overlay 
                                width={1200} 
                                component={
                                    <SuggestedCartDetails
                                        data={data}/>
                                }>
                                Details
                            </Overlay>
                        </small>
                    </Button>
                </Tooltip>
                {((!is_catalogue && !sharedView) && 
                    <Tooltip title="Edit the name and associated store">
                        <Tag 
                            className="tag-btn cataloguebtn" 
                            color={disabledColor}>
                            <Overlay 
                                maskClosable={false} 
                                width={700} 
                                component={
                                    <SuggestedCartCreateEdit 
                                        data={props.data}
                                        valid={true}
                                        getAllSuggestedCarts={getAllSuggestedCarts}
                                        availableStores={availableStores}
                                        getAllAvailableStores={getAllAvailableStores}
                                        edit={true}
                                        copy={false}
                                    />}>Edit
                            </Overlay>
                        </Tag>
                    </Tooltip>
                )}
                {((!sharedView) && 
                    <Tooltip title="Create a copy of this Suggested Order">
                        <Tag 
                            className="tag-btn cataloguebtn" 
                            color={disabledColor}>
                            <Overlay 
                                width={450} 
                                component={
                                    <SuggestedCartCreateEdit 
                                        data={props.data}
                                        valid={true}
                                        getAllSuggestedCarts={getAllSuggestedCarts}
                                        availableStores={availableStores}
                                        getAllAvailableStores={getAllAvailableStores}
                                        edit={false}
                                        copy={true}
                                    />}>Copy
                            </Overlay>
                        </Tag>
                    </Tooltip>
                )}
                {((!sharedView) &&  
                    <Tooltip title="Export to an Excel spreadsheet">
                        <Tag 
                            className="tag-btn cataloguebtn" 
                            color={disabledColor}>
                            <Overlay 
                                component={<SuggestedCartExcel id={id}/>} 
                                width={500}>Excel
                            </Overlay>
                        </Tag> 
                    </Tooltip>
                )}
                <Tooltip title="Export to a PDF">
                    <Tag 
                        className="tag-btn cataloguebtn" 
                        color={disabledColor}>
                        <Overlay 
                            component={
                            <SuggestedCartPDF 
                                count={rows.length} 
                                id={id} />} 
                            width={500}>PDF
                        </Overlay>
                    </Tag>
                </Tooltip>
                <Tooltip title="Open a new tab to with a printable version">
                    <Tag 
                        onClick={() => window.open("/print/list/" + id, "_blank")} 
                        className="tag-btn cataloguebtn"  
                        color={disabledColor}>Print
                    </Tag>
                </Tooltip>
                {((!sharedView && rows.length > 0) && 
                <Tooltip title="Share with the associated store for this Suggested Order">
                    <Tag 
                        className="tag-btn cataloguebtn" 
                        color={disabledColor}>
                        <Overlay 
                            width={650} 
                            component={
                                <SuggestedCartShare 
                                    data={data}    
                                    list_id={id} />
                            }>Share
                        </Overlay>
                    </Tag>
                </Tooltip>)}
                {((!is_catalogue && !sharedView) && 
                    <Tooltip title="Delete this Suggested Order">
                        <SuggestedCartDelete 
                            name={name} 
                            suggestedCarts={suggestedCarts}
                            setSuggestedCarts={setSuggestedCarts}
                            setRowCount={setRowCount}
                            id={id} >
                            <Tag 
                                className="tag-btn cataloguebtn" 
                                color="red">Delete
                            </Tag>
                        </SuggestedCartDelete>
                    </Tooltip>
                )}
            </Space>
        </>
    );
}