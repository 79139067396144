import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Radio, Select, Space, Table, Tooltip, Typography } from "antd";
import { useState } from "react";

export default function ListShareGroupTab(props){
    const { 
        close = () => {},
        loading = false,
        presetSANLists = [],
        setShared = () => {},
        chosenList = "",
        setChosenList = () => {},
        setSelectedRowKeys = () => {},
        drawReturnToReportCardButton = () => {},
    } = props;

    const [showSelectedListInTable, setShowSelectedListInTable] = useState(chosenList === "" ? false : true)
    
    const columns = [
        {
            title: <small>SAN</small>, width: '80px', dataIndex: 'san', key: 'san', sorter: (a,b) => a.san.localeCompare(b.san),
        },
        {
            title: <small>Store Name</small>, dataIndex: 'name', key: 'name', sorter: (a,b) => a.name.localeCompare(b.name),
        },
        {
            title: <small>City</small>, width: '130px', dataIndex: 'city', key: 'city', sorter: (a,b) => a.city.localeCompare(b.city),
        },
        {
            title: <small>Province/State</small>, width: '40px', dataIndex: 'province', key: 'province', sorter: (a,b) => a.province.localeCompare(b.province),
        },
    ];

    const settingChosenList = (chosen) => {
        let temp = presetSANLists.filter((list) => list.id === chosen)
        setChosenList(temp[0]);
        setShowSelectedListInTable(true)
        setSelectedRowKeys(temp[0].rows.map((store) => {return store.contact_id}))
    }

    return (
        <div style={{ "padding": "20px" }}>
            <Typography.Title level={5}>Share with a Group List</Typography.Title>
            <span>
                <Select
                    onSelect={(e) => settingChosenList(e)}
                    showSearch
                    className='ant-select-with-custom-arrow'
                    showArrow
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{ "width": "250px" }}
                    defaultValue={chosenList.name}
                    value={chosenList.name}
                    placeholder="Select Group List">
                    {presetSANLists.map((item, index) => {
                        return (
                            <Select.Option key={index} value={item.id} >
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </span>
            <div className="shim"/>
            <Typography.Paragraph>
                Stores in this list:
            </Typography.Paragraph>
            <Table
                style={!showSelectedListInTable ? {"marginBottom": "10px"} : {}}
                size="small"
                loading={loading}
                pagination={true} 
                columns={columns}
                dataSource={chosenList.rows}
            />
            <span>
                <div style={{ "float": "left" }}>
                    <Space>
                        {drawReturnToReportCardButton()}
                        <Button type="danger" onClick={() => close()}>Cancel</Button>
                    </Space>
                </div>
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={() => setShared(false, false)}>Unshare this Catalogue</Button>
                        <Button 
                            disabled={!showSelectedListInTable}
                            onClick={(e) => setShared(false)} 
                            type="primary">Share</Button>
                    </Space>
                </div>
            </span> 
            <br clear="all" />
        </div>
    )
}