import { DownOutlined } from '@ant-design/icons';
import { Divider, message, Select, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import { InfoCircleOutlined} from '@ant-design/icons';
import conditional from "../utils/conditional";
import { useSession } from "../utils/Session";

export default function SelectStores(props) {

    const { onChange = () => { } } = props;
    const [session, setSession] = useSession();
    const [suggestedCarts, setSuggestedCarts] = useState([]);
    const [scCount, setSCCount] = useState(0);
    const [initLoaded, setInitLoaded] = useState(false);
    const [sharedStores, setSharedStores] = useState([]);
    const [successfulPermissions, setSuccessfulPermissions] = useState(false);
    const getSharedStores = () => {
        let api_object = {
            my_accounts: false,
            approved: true,
            pending: false,
            declined: false,
            potential: false,
        }
        apiCall("rep_control/getAll", api_object, (_status, _result) => {
            if (_status) {
                setSharedStores(_result)
                setSuccessfulPermissions(true)
            } else {
                message.error("Unable to select stores to work with, please contact Bookmanager to update your permissions/subscriptions")
                // message.error(_result.error)
            }
            setInitLoaded(true);
        })

    }

    useEffect(getSharedStores, []);

    const [storeCount, setStoreCount] = useState(session.stores_working_with.map(item => item.contact_id).length);
    // function switchSuggestedCarts(results = false){
    //     let store_ids = []
    //     if(!results){
    //         session.stores_working_with.forEach((store) => {
    //             store_ids.push(store.contact_id)
    //         })
    //     }else{
    //         results.stores_working_with.forEach((store) => {
    //             store_ids.push(store.contact_id)
    //         });
    //     }
    //     apiCall("suggested_cart/getAll", {hide_shared : true, limit: 50, offset: 0, store_ids: JSON.stringify(store_ids)}, (_status, _result) => {
    //         if (_status) {
    //             setSuggestedCarts(_result.rows);
    //             setSCCount(session.suggested_carts_working_with.length);
    //         } 
    //     })
    // }

    // useEffect(switchSuggestedCarts, []);

    function setSuggestedCartsWorkingWith(_sc, _task) {
        let this_stores_carts = session.suggested_carts_working_with.map(item => item.id)
        if (this_stores_carts.includes(_sc.toString()) && _task === "remove") {
            this_stores_carts.splice(this_stores_carts.indexOf(_sc.toString()), 1);
        } else if (!this_stores_carts.includes(_sc.toString()) && _task === "add") {
            this_stores_carts.push(_sc.toString())
        }
        apiCall("session/setSuggestedCartsWorkingWith", { suggested_cart_ids_json: JSON.stringify(this_stores_carts) }, (_status, _result) => {
            if (_status) {
                setSCCount(_result.suggested_carts_working_with.length);
                setSession(_result)
            }
        })
    }

    const switchStore = (e) => {
        setStoreCount(e.length)

        apiCall("session/setStoresWorkingWith", { store_ids_json: JSON.stringify(e) }, (_status, _result) => {
            if (_status) {
                setSession(_result);
               
              
                onChange(e.length);
                
            } else {
                message.error(_result.error);
            }
        })
    }

    function drawSelectOptions(store) {
        return suggestedCarts.map((item) => {
            if (item.store_info.name === store.name) {
                return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
            }
        })
    }

    function getStoresSuggestedCarts(store) {
        let this_stores_carts = session.suggested_carts_working_with.filter((item) => item.store_info.name == store.name)
        return this_stores_carts.map(cart => cart.id)
    }

    return (
        <>
            {((initLoaded) &&
                <>
                    <conditional.true value={successfulPermissions}>
                        <div>
                            <conditional.true value={storeCount < 5}>
                                <label>Select store data to view (max 5) <Tooltip title={<><small>This list is only stores who have approved data sharing with you. To view accounts currently sharing data with you, or, to send a data share request, go to the Admin &gt; My Accounts menu.</small></>}><a><InfoCircleOutlined /></a></Tooltip></label><br />
                         
                            </conditional.true>
                            <conditional.true value={storeCount >= 5}>
                                <label style={{ "color": "red" }}>You cannot select any more stores</label><br />
                            </conditional.true>
                            <Select
                                mode="multiple"
                                getPopupContainer={trigger => trigger.parentElement}
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                className='ant-select-with-custom-arrow'
                                defaultValue={session.stores_working_with.map(item => item.contact_id)}
                                onChange={(e) => switchStore(e)}
                                style={{ width: 250 }}>
                                {sharedStores.map(item => {
                                    return (
                                        <Select.Option
                                            key={item.contact_id}
                                            value={item.contact_id}
                                            label={item.name}> {item.name}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                            {/* Hiding the Suggested Cart selections until this feature actually works */}
                            {/* {session.stores_working_with.map((store) => {
                                return(
                                    <>
                                        <Divider dashed style={{ "margin": "5px 0px" }} />
                                        <conditional.true value={scCount < 5}>
                                            <label>Select up to {5 - scCount} more suggested orders for: <br></br>{store.name}</label><br />
                                        </conditional.true>
                                        <conditional.true value={scCount >= 5}>
                                            <label style={{"color": "red"}}>You cannot select any more carts!</label><br />
                                        </conditional.true>
                                        <Select 
                                            mode="multiple" 
                                            defaultValue={getStoresSuggestedCarts(store)}
                                            onDeselect={(e) => setSuggestedCartsWorkingWith(e, "remove")}
                                            onSelect={(e) => setSuggestedCartsWorkingWith(e, "add")} 
                                            style={{ width: 250 }}>
                                            {drawSelectOptions(store)}
                                        </Select>  
                                    </>
                                )
                            })} */}
                        </div>
                    </conditional.true>
                    {/* <conditional.true value={!successfulPermissions}>
                        <div style={{"width": "200px"}}>
                            You are unable to select stores to work with, please contact Bookmanager to update your subscriptions
                        </div>
                    </conditional.true> */}
                </>
            )}
        </>
    )
}