import React from "react";
import { Tooltip, Button } from "antd";
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { disabledColor, favColor } from "../../utils/Utils";
import { apiCall } from "../../utils/Api";

export default function ListFav(props) {

    const { list = {}, results = {}, setResults = () => { } } = props;
    const { list_id = "", is_starred = false } = list;

    const toggleListFav = (is_picked) => {

        apiCall("titlelist/star", { list_id: list_id, is_picked: is_picked }, (_status, _result) => {
            if (_status) {
                let newArr = { ...results };
                let update = results.rows.find(item => item.list_id === _result.list_id);
                update.is_starred = _result.is_starred;
                newArr.rows[results.rows.findIndex(item => item.list_id === _result.list_id)] = update;
                setResults({ ...results, "rows": newArr.rows })
            }
        });

    }

    return (
        <Tooltip title="Star this List/Catalogue to internally mark it as a favourite.">
            <div onClick={() => toggleListFav(!is_starred)}>
                <Button size="small" type="link" icon={(!is_starred) ? <StarOutlined style={{ "color": disabledColor }} /> : <StarFilled style={{ "color": favColor }} />} />
            </div>
        </Tooltip>
    );


}