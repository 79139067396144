import { Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { apiCall, apiDownload } from "../../utils/Api";

export default function InnerExpandedTable(props){
    const {
        record = [],
        loading = false,
        setLoading = () => {},
        getDrilldown = () => {},
        supplier = "",
        group = "",
        isbnValues = [],
        dataValues = [],
    } = props;

    const [innerTableData, setInnerTableData] = useState([]);

    const determineDrilldownValue = (type, value) => {
        let type_prefix = type.substring(0, type.indexOf(" "));
        if(value === "_notitle"){
            return type_prefix.concat("_notit").toLowerCase();
        }
        return type_prefix.concat(value).toLowerCase();
    }

    const grouping_row = record.prefix !== null ? record.prefix : record.pubdate

    //getDrilldown(grouping_row, group, drilldown)
    //grouping_row: y/n, the date, or the prefix
    //group:        valid (none), prefix, or pubdate
    //drilldown:    the specific oo_isbn, oh_isbn, etc.

    const columns = [
        {
            title:  
                <Tooltip title="Individual ISBN types">
                    <small>Type</small>
                </Tooltip>, 
            width: '150px', dataIndex: 'isbntype', key: 'isbntype'
        },
        {
            title:
                <Tooltip title="Total ISBNs in the ISBN type, not the total of ISBNs missing some data">
                    <small>Count</small>
                </Tooltip>, 
            dataIndex: 'count', key:'count',  render: (e, f) => {
                
                return <span 
                    className="isbnCount" 
                    style={{ "cursor": "pointer"}}
                    onClick={() => getDrilldown(grouping_row, group, determineDrilldownValue(f.isbntype, "_isbns"), f.count)}>{f.count}</span>
            }
        },
    ]
    if(dataValues.no_title){
        columns.push({
            title:  
                <Tooltip title="ISBNs missing a title">
                    <small>No Title</small>
                </Tooltip>,  
            dataIndex: 'notitle', 
            key: 'notitle',  
            render: (e, f) => {
                return <span 
                    className="noTitle" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(grouping_row,  group, determineDrilldownValue(f.isbntype, "_notitle"), f.notitle)}>{f.notitle}</span>
            }
        })
    }
    if(dataValues.no_img){
        columns.push({
            title:
                <Tooltip title="ISBNs missing an image. Does not include ISBNs that don't have interior images">
                    <small>No Img</small>
                </Tooltip>,
            dataIndex: 'noimg', 
            key: 'noimg',  
            render: (e, f) => {
                return <span 
                    className="noImg" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(grouping_row,  group, determineDrilldownValue(f.isbntype, "_noimg"), f.no_img)}>{f.noimg}</span>
            }
        })
    }
   
    if(dataValues.no_bio){
        columns.push({
            title:
                <Tooltip title="ISBNs missing an author biography">
                    <small>No Bio</small>
                </Tooltip>, 
            dataIndex: 'nobio', 
            key: 'nobio',  
            render: (e, f) => {
                return <span 
                    className="noBio" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(grouping_row,  group, determineDrilldownValue(f.isbntype, "_nobio"), f.no_bio)}>{f.nobio}</span>
            }
        })
    }
    if(dataValues.no_desc){
        columns.push({
            title:
                <Tooltip title="ISBNs missing a description">
                    <small>No Desc</small>
                </Tooltip>,  
            dataIndex: 'nodesc', 
            key: 'nodesc',  
            render: (e, f) => {
                return <span 
                    className="noDesc" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(grouping_row,  group, determineDrilldownValue(f.isbntype, "_nodesc"), f.no_desc)}>{f.nodesc}</span>
            }
        })
    }


    if(dataValues.no_interiors){
        columns.push({
            title:
                <Tooltip title="ISBNs missing a interiors">
                    <small>No Interiors</small>
                </Tooltip>, 
            dataIndex: 'nointerior', key: 'nointerior',  render: (e, f) => {
                return <span 
                    className="noInteriors" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nointerior"), f.nointerior)}>{f.nointerior}</span>
            }
        })
    }

    if(dataValues.no_digitals){
        columns.push({
            title:
                <Tooltip title="ISBNs missing digitals">
                    <small>No DRCs/ARCs</small>
                </Tooltip>, 
            dataIndex: 'nodigital', key: 'nodigital',  render: (e, f) => {
                return <span 
                    className="noDRC" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nodigital"), f.nodigital)}>{f.nodigital}</span>
            }
        })
    }

    if(dataValues.no_annotations){
        columns.push({
            title:
                <Tooltip title="ISBNs missing annotations">
                    <small>No Reviews</small>
                </Tooltip>, 
            dataIndex: 'noreview', key: 'noreview',  render: (e, f) => {
                return <span 
                    className="noReviews" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_noreview"), f.noreview)}>{f.noreview}</span>
            }
        })
    }

    if(dataValues.no_related_isbns){
        columns.push({
            title:
                <Tooltip title="ISBNs missing related">
                    <small>No Comparables</small>
                </Tooltip>, 
            dataIndex: 'nocomparable', key: 'nocomparable',  render: (e, f) => {
                return <span 
                    className="noComparables" 
                    style={{ "cursor": "pointer"}} 
                    onClick={() => getDrilldown(f.valid_ean, "valid", determineDrilldownValue(f.isbntype, "_nocomparable"), f.nocomparable)}>{f.nocomparable}</span>
            }
        })
    }

    

    const prepMissingDataForDisplay = () => {
        
        let temp_array = [];
        //unshifted backwards to make sure the rows are in the correct order on the page
        //therefore ITO ISBNs is actually the bottom row, and ALL ISBNs is the top row
        if(isbnValues.ito_isbns){
            temp_array.unshift(
                {
                    isbntype: "ITO ISBNs",
                    count: record.ito_isbns,
                    notitle: record.ito_notit,
                    nodesc: record.ito_nodesc,
                    nobio: record.ito_nobio,
                    noimg: record.ito_noimg,

                    nointerior: record.ito_nointerior,
                    nodigital: record.ito_nodigital,
                    noreview: record.ito_noreview,
                    nocomparable: record.ito_nocomparable,
                },
            )
        } 
        if(isbnValues.oo_isbns){
            temp_array.unshift(
                {
                    isbntype: "OO ISBNs",
                    count: record.oo_isbns,
                    notitle: record.oo_notit,
                    nodesc: record.oo_nodesc,
                    nobio: record.oo_nobio,
                    noimg: record.oo_noimg,

                    nointerior: record.oo_nointerior,
                    nodigital: record.oo_nodigital,
                    noreview: record.oo_noreview,
                    nocomparable: record.oo_nocomparable,
                },
            )
        }
        if(isbnValues.oh_isbns){
            temp_array.unshift(
                {
                    isbntype: "OH ISBNs",
                    count: record.oh_isbns,
                    notitle: record.oh_notit,
                    nodesc: record.oh_nodesc,
                    nobio: record.oh_nobio,
                    noimg: record.oh_noimg,

                    nointerior: record.oh_nointerior,
                    nodigital: record.oh_nodigital,
                    noreview: record.oh_noreview,
                    nocomparable: record.oh_nocomparable,
                },
            )
        }
        if(isbnValues.nyr_isbns){
            temp_array.unshift(
                {
                    isbntype: "NYR ISBNs",
                    count: record.nyr_isbns,
                    notitle: record.nyr_notit,
                    nodesc: record.nyr_nodesc,
                    nobio: record.nyr_nobio,
                    noimg: record.nyr_noimg,

                    nointerior: record.nyr_nointerior,
                    nodigital: record.nyr_nodigital,
                    noreview: record.nyr_noreview,
                    nocomparable: record.nyr_nocomparable,
                },
            )
        }
        if(isbnValues.rank_isbns){
            temp_array.unshift(
                {
                    isbntype: "Rank ISBNs",
                    count: record.rank_isbns,
                    notitle: record.rank_notit,
                    nodesc: record.rank_nodesc,
                    nobio: record.rank_nobio,
                    noimg: record.rank_noimg,

                    nointerior: record.rank_nointerior,
                    nodigital: record.rank_nodigital,
                    noreview: record.rank_noreview,
                    nocomparable: record.rank_nocomparable,
                },
            )
        }
        if(isbnValues.all_isbns){
            temp_array.unshift(
                {
                    isbntype: "All ISBNs",
                    count: record.all_isbns,
                    notitle: record.all_notit,
                    nodesc: record.all_nodesc,
                    nobio: record.all_nobio,
                    noimg: record.all_noimg,

                    nointerior: record.all_nointerior,
                    nodigital: record.all_nodigital,
                    noreview: record.all_noreview,
                    nocomparable: record.all_nocomparable,
                },
            )
        }
        setInnerTableData(temp_array)
    }

    useEffect(prepMissingDataForDisplay, [])
    useEffect(prepMissingDataForDisplay, [isbnValues])

    const alternateRowColours = (record, index) => {
        if(index%2 === 1){
            return "evenRowColour"
        }
    }

    return(
        <>
            <div style={{ "padding": "0px 0px" }}>
                <div style={{ "marginTop": "-10px", "marginBottom": "10px" }}>
                    <div className="shim" /><div className="shim" />
                    <Typography.Title level={5}>
                        Detailed Summary
                    </Typography.Title>
                    <Table
                        size="small"
                        bordered
                        rowClassName={(record, index) => alternateRowColours(record, index)}
                        pagination={false}
                        loading={loading}
                        columns={columns}
                        dataSource={innerTableData}
                    />
                </div>
            </div>
        </>
    )
}