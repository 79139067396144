import { CaretDownOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Col, Row, Select, Space, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";

import conditional from "../utils/conditional";
import { usePrefs } from "../utils/Prefs";
import { useSession } from "../utils/Session";

export default function Condition(props) {

    const [session, setSession] = useSession();
    const [showActivity, setShowActivity] = useState("");
    const [prefs, setPrefs] = usePrefs();
    const [data, setData] = useState(props.data);
    const [beginningMsInfo, setBeginningMsInfo] = useState(props.data.sales.msinfo);
    const [openState, setOpenState] = useState("");

    const toggleActivity = (_view, _lbl = "") => {
        let reopen = (_lbl !== openState);
        setOpenState(_lbl)
        setShowActivity(((_view) && _view === showActivity) ? "" : _view)
        if (reopen) {
            setTimeout(() => {
                setShowActivity(_view)
            }, 1)
        }
    }

    const getStoreName = () => {
        let _store = session.stores_working_with.find(item => item.san === data.store_san);
        if(_store){
            return _store.name;
        } else {
            return "";
        }
    }

    const storeName = getStoreName();

    // Original sizes
    // xsmall: 28px
    // small:  30px
    // mid:    37px
    // large:  38px
    // xlarge: 73px

    const sizes = { 
        xsmall: (28 * 2) + "px",
        small: (30 * 2) + "px",
        mid: (37 * 2) + "px",
        large: (38 * 2) + "px",
        xlarge: (73 * 2) + "px"
    }

    // destructure
    const {
        // condition
        code = "",
        code_text = "",
        code_used_desc = "",
        code_used_note = "",
        // extras
        total_onhand = 0,
        minimum_onhand = 0,
        backroom = 0,
        held = 0,
        available_onhand = 0,
        pending_return = 0,
        special_order = 0,
        available_onorder = 0,
        pending_onorder = 0,
        // from bookmanager backups
        primary_key = "",
        discount = "",
        title = "",
        pubdate = "",
        author = "",
        binding = "",
        price = "",
        sell_price = "",
        carton_quantity = 0,
        profit_cost_string = "",
        supplier_1 = "",
        supplier_2 = "",
        supplier_3 = "",
        section = "",
        out_of_stock_status = "",
        returnable = "",
        entered = "",
        original_publisher = "",
        // Sales
        sold = false,
        cart_qty = 0,
        received = false,
        adjusted = false,
        returns = false,
        msinfo = []
    } = data.sales;


    const osstat_convert = {
        'A': 'AVAI',
        'B1': 'BOS',
        'B2': 'BNYP',
        'B3': 'BRP',
        'B4': 'BITO',
        'C1': 'COS',
        'C2': 'CNYP',
        'C3': 'CNOP',
        'C4': 'CNR',
        'C5': 'COP',
        'C6': 'CNL',
        'C7': 'CNW',
        'C8': 'COSI',
        'C9': 'CPXL',
        'CA': 'CRP',
        'CB': 'CITO',
        'CC': 'CBU',
        'CD': 'CBC'
    }

    const converted_out_of_stock_status = osstat_convert[out_of_stock_status] || out_of_stock_status;

    // This is gross, but it's to assign spots to put extra info - Any overflow will be added to the top right;
    const getExtras = () => {
        let _ex = [];
        let _spots = [];
        if (special_order) {
            _ex.push({
                label: "S/O",
                class: "extra-lbl-special_order",
                valueClass: "extra-special_order",
                value: special_order
            })
        }
        if (held) {
            _ex.push({
                label: "Held",
                class: "extra-lbl-held",
                valueClass: "extra-held",
                value: held
            })
        }
        // only show if a specific store is selected or is non multi-store
        if ((minimum_onhand && msinfo.length === 1) || (minimum_onhand && prefs.store)) {
            _ex.push({
                label: "mOH",
                class: "extra-lbl-minimum_onhand",
                valueClass: "extra-minimum_onhand",
                value: minimum_onhand
            })
        }
        // only show if a specific store is selected or is non multi-store
        if ((backroom && msinfo.length === 1) || (backroom && prefs.store)) {
            _ex.push({
                label: "Bkrm",
                class: "extra-lbl-backroom",
                valueClass: "extra-backroom",
                value: backroom
            })
        }
        if (pending_return) {
            _ex.push({
                label: "Pdrn",
                class: "extra-lbl-pending_return",
                valueClass: "extra-pending_return",
                value: pending_return
            })
        }
        // Add extra to returns row
        if (_ex.length > 0 && returns) {
            let _x = _ex[0];
            _x.spot = "Retn";
            _spots.push(_x);
            _ex.shift();
        }
        // Add extra to returns row
        if (_ex.length > 0 && adjusted) {
            let _x = _ex[0];
            _x.spot = "Adj";
            _spots.push(_x);
            _ex.shift();
        }
        // Add extra to date row
        if (msinfo.length === 1 && _ex.length > 0) {
            let _x = _ex[0];
            _x.spot = "Date";
            _spots.push(_x);
            _ex.shift();
        }
        return {
            spots: _spots,
            extra: _ex
        }
    }
    const extras = getExtras();

    function storesCount(){
        let _store = session.stores_working_with.find(item => item.san === data.store_san);
        return _store
    }

    const parseSold = (_msinfo = false) => {
        if (!_msinfo) {
            return [];
        }
        let _sold = [];
        _msinfo.forEach(element => {
            if (element.hasOwnProperty("sold")) {
                element.sold.storenum = element.storenum;
                element.sold.available_onhand = element.hasOwnProperty("available_onhand") ? element.available_onhand : 0;
                element.sold.total_onhand = element.hasOwnProperty("total_onhand") ? element.total_onhand : 0;
                element.sold.available_onorder = element.hasOwnProperty("available_onorder") ? element.available_onorder : 0;
                element.sold.pending_onorder = element.hasOwnProperty("pending_onorder") ? element.pending_onorder : 0;
                element.sold.cart_qty = element.hasOwnProperty("cart_qty") ? element.cart_qty : 0;
                _sold.push(element.sold);
            } else {
                let x = {}
                x.storenum = element.storenum;
                x.available_onhand = element.hasOwnProperty("available_onhand") ? element.available_onhand : 0;
                x.total_onhand = element.hasOwnProperty("total_onhand") ? element.total_onhand : 0;
                x.available_onorder = element.hasOwnProperty("available_onorder") ? element.available_onorder : 0;
                x.pending_onorder = element.hasOwnProperty("pending_onorder") ? element.pending_onorder : 0;
                x.cart_qty = element.hasOwnProperty("cart_qty") ? element.cart_qty : 0;
                if ((x.available_onhand) || (x.total_onhand) || (x.available_onorder) || (x.pending_onorder)) {
                    _sold.push(x);
                }
            }
        });
        return _sold;
    }

    const drawExtra = (_obj, _row = false) => {
        if (!_obj) {
            return (<></>)
        }
        if (_row) {
            return (
                <Row key={_obj.label} onClick={() => toggleActivity("sales", _obj.label)} className="bc" style={{ "fontSize": "11px", "color": "#aaa", "textAlign": "right", "borderBottom": "1px solid", "lineHeight": "22px" }}>
                    <Col flex={"auto"} className="bc">&nbsp;</Col>
                    <Col flex={sizes.mid} className={"bc " + _obj.class} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "none", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.label}</div>
                    </Col>
                    <Col flex={sizes.large} className={"bc " + _obj.valueClass} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "none", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.value}</div>
                    </Col>
                </Row>
            )
        } else {
            return (
                <>
                    <Col flex={sizes.mid} onClick={() => toggleActivity("sales", _obj.label)}  className={"bc " + _obj.class} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "none", "fontWeight": "normal", "textAlign": "center", "paddingRight": "1px", "marginRight": "6px" }}>
                        <div>{_obj.label}</div>
                    </Col>
                    <Col flex={sizes.large} onClick={() => toggleActivity("sales", _obj.label)}  className={"bc " + _obj.valueClass} style={{ "fontSize": "11px", "width": "25px", "cursor": "pointer", "borderLeft": "none", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.value}</div>
                    </Col>
                </>
            )
        }
    }

    const switchStore = (_store) => {
        setPrefs({ ...prefs, "store": _store });
        if (_store === "") {
            setData(props.data);
        }
        let obj = msinfo.find(item => item.storenum === _store);
        if (!obj) {
            return;
        }
        obj.code = code;
        obj.code_text = code_text;
        obj.msinfo = msinfo;
        setData({...data, "sales" : obj});
    }

    function checkIsMultiStore(){
        let isMultiStore = false
        session.stores_working_with.forEach((store) => {
            if(store.stores.length > 1 && store.name === storeName){
                isMultiStore = true
            }
        })
        return isMultiStore
    }

    const drawStoreSelector = () => {
        return (
            <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow minselect' onChange={(_store_num) => switchStore(_store_num)}  style={{ "fontSize": "13px", "width": "4.5vw" }} showArrow={<CaretDownOutlined />} bordered={false} value={prefs.store} size="small">
                <Select.Option value={""} key={"init"}><small>All Stores</small></Select.Option>
                {beginningMsInfo.map((item, index) => {
                    return (<Select.Option key={index} value={item.storenum}><small>Store #{item.storenum}</small></Select.Option>)
                })}
            </Select>
        )
    }

    const drawHeadersRow = () => {
        let _months = ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]
        _months = _months.map((item, index) => {
            return moment().startOf("month").add(-(_months.length - (index + 1)), "month").format("MMM");
        });
        return (
            <Row className="bc" style={{ "fontSize": "11px", "color": "#aaa", "textAlign": "right", "borderBottom": "0px solid", "lineHeight": "22px" }}>
                <Col span="2" className="bc" style={{ "borderRight": "1px solid" }}>&nbsp;</Col>
                <Col span="2" className="bc c" style={{ "borderRight": "1px solid", "borderBottom": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>Total</Col>
                {_months.map((item, _index) => {
                    return (<Col span="1" className="bc" onClick={() => toggleActivity("sales")} key={_index} style={{ "paddingRight": "2px", "cursor": "pointer", "borderBottom": "1px solid" }}><span className="c">{item}</span></Col>)
                })}
                <Col span="1" className="bc" style={{ "borderBottom": "1px solid", "borderRight": "1px solid", "paddingRight": "2px" }}><span className="c">Wk</span></Col>
                <conditional.true value={(msinfo.length === 1 && !checkIsMultiStore())}>
                    {drawExtra(extras.spots.find(item => item.spot === "Date"))}
                </conditional.true>
                <Col span="5" className="bc" style={{"borderRight": "1px solid",  "borderBottom": "1px solid" }}>
                    {/* <conditional.true value={checkIsMultiStore()}> */}
                    <conditional.true value={(msinfo.length > 0)}>
                        {drawStoreSelector()}
                    </conditional.true>
                </Col>
            </Row>
        )
    }

    const drawToggle = (_aoo = available_onorder, _poo = pending_onorder) => {
        if (!_aoo && !_poo && !prefs.pendingOO) {
            _aoo = "";
        }
        return (<>
            <Col span="1"  onClick={(e) => { e.preventDefault(); e.stopPropagation(); setPrefs({ ...prefs, "pendingOO": !prefs.pendingOO }); }} className="bc" style={{ "cursor": "pointer", "borderLeft": "1px solid", "backgroundColor": "#ffe8bf", "color": "#000", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                <Tooltip title={_aoo + " copies are on a sent PO for stock. This quantity does not include special orders (S/O)."}><div>{(converted_out_of_stock_status === "BOS" && "Yes ")}{_aoo}</div></Tooltip>
            </Col>
            <Col span="1"  onClick={(e) => { e.preventDefault(); e.stopPropagation(); setPrefs({ ...prefs, "pendingOO": !prefs.pendingOO }); }} className="bc" style={{ "cursor": "pointer", "borderLeft": "1px solid", "backgroundColor": "#ffe8bf", "color": "#000", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                <Tooltip title={_poo + " are in a pending stock order (not sent). This quantity does not include special orders (S/O)."}><div>{(converted_out_of_stock_status === "BOS" && "Yes ")}{_poo}</div></Tooltip>
            </Col>
        </>)
    }

    const drawOnhand = (_oh, _toh) => {
        if (!_oh && !_toh) {
            _oh = ""
        }
        let _oh_label = _oh || "0";
        return (
            <Col span="2" className="bc" style={{ "cursor": "pointer", "borderLeft": "none", "backgroundColor": "#ccf2cc", "color": "#000", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                <Tooltip title={_oh_label + " copies are available on hand, with " + _toh + " copies total on hand. When the 'total' and 'available' count differ, this likely indicates that some are reserved for customer (Held) or could be in a pending stock return (Pdrn)."}><div>{(converted_out_of_stock_status === "AVAI" && "Yes ")}{_oh}{(_toh && _toh !== _oh) ? <sup style={{ "top": "-3px" }}>{_toh}</sup> : ""}</div></Tooltip>
            </Col>
        )
    }

    const drawValues = (_data = {}, _type = "", _cart_qty = null) => {
        if (!_data && _type !== "Rcvd" && _type !== "Ttl") {
            return <></>
        }
        if (!_data) {
            _data = {};
        }
        let { 
            months = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""], 
            total = 0, 
            week = 0, 
            onhandlocs = 0, 
            onorderlocs = 0, 
            onhand = 0, 
            onorder = 0, 
            title = "" 
        } = _data;
        let _color = "#000";
        let _class = "rowSold";
        let border = false;
        switch (_type) {
            case "Adj":
                border = true;
                _color = "#228B22";
                _class = "rowAdj";
                break;
            case "Retn":
                border = true;
                _color = "#228B22";
                _class = "rowRetn";
                break;
            case "Rcvd":
                border = true;
                _color = "#228B22";
                _class = "rowRcvd";
                break;
            default:
                _color = "#000";
                _class = "rowSold";
                border = "false";
        }
        // Change the Ttl label to Sold for non-multistores
        let _label = _type;
        if (msinfo === 1 && _label === "Ttl") {
            _label = "Sold"
        }
        return (
            <Row onClick={() => toggleActivity("sales", _type)} className={_class + " bc rowHover"} style={{ "fontSize": "14px", "lineHeight": "22px", "fontWeight": "normal", "textAlign": "right", "borderBottom": (border) ? "0px solid" : "none" }}>
                <conditional.true value={(_type !== "Peer")}>
                    <Col span="2" className="bc" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingLeft": "1px", "fontWeight": "normal", "fontSize": "14px" }}>{_label}&nbsp;</Col>
                    <Col span="2" className="bc" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px", "fontSize": "14px" }}>{(total) ? total : ""}</Col>
                </conditional.true>
                {months.map((item, _index) => {
                    return (<Col span="1" key={_index} style={{"fontSize": "14px", "textAlign": "right", "paddingRight": "2px" }} >{(item) ? item : ""}</Col>);
                })}
                <Col span="1" className="bc" style={{ "borderLeft": "1px solid", "paddingRight": "2px", "borderRight": "1px solid #000" }}><em>{(week) ? week : ""}</em></Col>
                <conditional.true value={(_type === "Rcvd")}>
                    <Col span="2" className="bc" style={{ "borderTop": "none", "color": "#4f9818", "textAlign": "right", "paddingRight": "3px", "fontSize": "14px" }}><em>aOH</em></Col>
                    <Col span="1" className="bc" style={{ "borderTop": "none", "color": "#e77e21", "textAlign": "right", "paddingRight": "3px", "fontSize": "14px" }}><em>{"aOO"}</em></Col>
                    <Col span="1" className="bc" style={{ "borderTop": "none", "color": "#e77e21", "textAlign": "right", "paddingRight": "3px", "fontSize": "14px" }}><em>{"pOO"}</em></Col>
                    <Col span="1" style={{"width": "18px", "padding": "0px 0px", "backgroundColor": "#000", "borderRadius": "10px", "textAlign": "center", "color": "#fff", "height": "18px", "fontSize": "14px" }}>
                        <Tooltip title={<small>Biz cart quantities</small>}><ShoppingCartOutlined style={{ "marginTop": "4px", "display": "block" }} /></Tooltip>
                    </Col>
                </conditional.true>
                <conditional.true value={(_type === "Sold" || _type.includes("#"))}>
                    {drawOnhand(_data.available_onhand, _data.total_onhand)}
                    {drawToggle(_data.available_onorder, _data.pending_onorder)}
                </conditional.true>
                <conditional.true value={(_type === "Ttl" || _type === "Sold")}>
                    {drawOnhand(available_onhand, total_onhand)}
                    {drawToggle()} 
                </conditional.true>
                <conditional.true value={(_cart_qty !== null)}>
                    <Col span="1" style={{"fontSize": "14px", "width": "20px", "height": "23px", "backgroundColor": "#abdeff", "padding": "0px 2px", "color": "#000" }}>
                        {(_cart_qty) ? _cart_qty : <>&nbsp;</>}
                    </Col>
                </conditional.true>
            </Row>
        )
    }

    const drawPrice = (_prev = false) => {
        if (!price) {
            return "";
        }
        if (sell_price && _prev) {
            if (discount) {
                return <><span style={{ "color": "#f12a46" }}></span><div style={{ "overflow": "hidden" }}><small><nobr>({discount}% off ${price})</nobr></small></div></>
            }
            return <><div>(reg. ${price})</div></>
        }
        if (_prev) {
            return "";
        }
        return <div style={{ "fontSize": "14px", "color": (sell_price) ? "#f12a46" : "inherit", "fontWeight": "normal" }}>{(sell_price) ? "$" + sell_price.toString() : "$" + price.toString()}</div>
    }

    return (
        <>
            
            <div className="float-flex" style={{ "alignItems": "flex-end" }}>
                <div>
                    <Space size={3}>
                        <Typography.Title level={4}>{storeName}</Typography.Title>
                    </Space>
                </div>
                <div>
                    <Space size={3}>{drawPrice(true)} {drawPrice()}</Space>
                </div>
            </div>
            <div className="bc-light bc" style={{ border: "1px solid", borderLeft: "6px solid", "borderRight": "1px solid"  }}>
                <div>
                    {drawHeadersRow()}
                    {drawValues(adjusted, "Adj")}
                    {drawValues(returns, "Retn")}
                    {drawValues(received, "Rcvd")}
                    <conditional.true value={(prefs.store === "")}>
                        {(parseSold(msinfo).length > 0 && <>
                            <div className="bc" style={{ "borderTop": '0px solid' }}>
                                {parseSold(msinfo).map((item, index) => {
                                    return drawValues(item, "#" + item.storenum, item.cart_qty || 0);
                                })}
                            </div>
                        </>)}
                    </conditional.true>
                    <conditional.true value={(prefs.store !== "")}>
                        {(parseSold(msinfo).length > 0 && <>
                            <div className="bc" style={{ "borderTop": '0px solid' }}>
                                {parseSold(msinfo).filter(i => i.storenum === prefs.store).map((item, index) => {
                                    return drawValues(item, "#" + item.storenum, item.cart_qty || 0);
                                })}
                            </div>
                        </>)}
                    </conditional.true>
                    <conditional.true value={true}>
                        <div className="bc" style={{ "borderTop": '0px solid' }}>
                            {drawValues(sold, "Ttl", cart_qty)}
                        </div>
                    </conditional.true>
                </div>
            </div>
            <conditional.true value={(extras.spots.length > 0)}>
                <div className="bc" style={{ "marginTop": "-3px", "borderLeft": "6px solid" }}>
                    <div className="bc" style={{ "display": "inline", "borderBottom": "1px solid" }}>
                        <Space size={0}>
                            {extras.spots.map(item => {
                                return drawExtra(item);
                            })}
                        </Space>
                    </div>
                </div>
            </conditional.true>
        </>
    )
}