import { Button, Divider, Modal, Space, Typography } from "antd";
import React, { useState } from "react";

export default function Resources(props) {

    const { data = [] } = props;
    const [visible, setVisible] = useState();

    const drawModal = () => {
        return (
            <Modal destroyOnClose={true} onCancel={() => setVisible(false)} footer={null} visible={visible}>
                <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                    <div>Digital Resources ({data.length})</div>
                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "5px" }} />
                </Typography.Title>
                <Space direction="vertical">
                {data.map(item => {
                    return(<a key={item.url} target={"_blank"} href={item.url}>{item.name}</a>)
                })}
                </Space>
                
            </Modal>
        )
    }

    if (data.length < 1) {
        return (<></>);
    }

    return (
        <>
            {drawModal()}
            <Button onClick={() => setVisible(true)} size="small" className="mini-btn" ><small>Digital Resources ({data.length}) </small></Button>
        </>
    )

}