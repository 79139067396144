import { Space, Table } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';


export default function DragTable(props) {
  const {
    size = "small", 
    rowKey="", 
    className = "", 
    style = {}, 
    scroll = {}, 
    pagination = false, 
    // columns = [], 
    data = [], 
    setData = () => {}, 
    callback = () => {} 
  } = props; 



  const columns = [...props.columns]; // Create a shallow copy to avoid direct mutation
  columns.splice(1, 0, { // Insert at index 1
      title: <small>Shift</small>,
      dataIndex: 'success',
      width: "60px",
      key: 'success',
      render: (e, f, g) => {
          return (<Space><a onClick={() => moveItemDown(g, current)}><DownCircleFilled /></a><a><UpCircleFilled onClick={() => moveItemUp(g, current)} /></a></Space>);
      }
  });
  

  const [current, setCurrent] = useState(1); 
  
  const type = 'DraggableBodyRow';
  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          ...style,
        }}
        {...restProps}
      />
    );
  };


  const updateData = (_d, _h, _current) => {

    let _aug = (_current * 10) - 10;
   
    _d = _aug + _d; 
    _h = _aug + _h; 

    let _arr = [...data];
    let _c; //clone
    if (_d < _h) {
      _c = _arr[_d];
      _arr.splice((_h + 1), 0, _c);
      _arr.splice(_d, 1);
    }

    if (_d > _h) {
      _c = _arr[_d];
      _arr.splice(_d, 1);
      _arr.splice((_h), 0, _c);
    }
    setData(_arr);

    return _arr; 
  }

  const moveItemDown = (_d, _current) => {
    let _aug = (_current * 10) - 10;
    _d = _aug + _d;
  
    let _arr = [...data];
    // Ensure we are not at the end of the array
    if (_d < _arr.length - 1) {
      // Swap _d with the item one position below
      let temp = _arr[_d];
      _arr[_d] = _arr[_d + 1];
      _arr[_d + 1] = temp;
    }
  
    setData(_arr);
    return _arr;
  };

  const moveItemUp = (_d, _current) => {
    let _aug = (_current * 10) - 10;
    _d = _aug + _d;
    let _arr = [...data];
    // Ensure we are not at the beginning of the array
    if (_d > 0) {
      // Swap _d with the item one position above
      let temp = _arr[_d];
      _arr[_d] = _arr[_d - 1];
      _arr[_d - 1] = temp;
    }
    setData(_arr);
    return _arr;
  }


  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      let _arr = updateData(dragIndex, hoverIndex, current);
      if(JSON.stringify(_arr) !== JSON.stringify(data)){  
        callback(_arr); 
      }
    },
    [data, current],
  );

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Table
          size={size}
          style={style}
          rowKey={rowKey}
          pagination={{onChange : (e) => { setCurrent(e)}}}
          className={className}
          scroll={scroll}
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr;
          }}
        />
      </DndProvider>
    </>
  );
}