import { DeleteOutlined, GlobalOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, message, Modal, Space, Spin, Upload } from "antd";
import React, { useState } from "react";

import { apiCall } from "../../utils/Api";

export default function CoverImage(props) {

    const { isbn, eisbn, editing=false, cover_image_key = "", cover_image_cache = 0 } = props;
    const [visible, setVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [valid, setValid] = useState(true);

    const image_path = "https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache + (cover_image_key && "&b2b=" + cover_image_key);


    const open = () =>{
        setValid(true);
        setVisible(true);
    }

    const deleteImage = () =>{
        apiCall("title/deleteCoverImage", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setVisible(false);
                message.success("Cover image delete requested.");
            } else {
                message.error(_result.error);
            }
            setUploading(false);
        });
    }

    const uploadFile = (_file) => {
        setUploading(true);
        apiCall("title/uploadCoverImage", { file: _file.file, isbn: isbn }, (_status, _result) => {
            if (_status) {
                message.success("Cover image update requested.");
            } else {
                message.error(_result.error)
            }
            setUploading(false);
        });
    }
    
    const copyFileFromUrl = (_f) => {
        apiCall("title/uploadCoverImageByUrl", { url: _f["url"], isbn: isbn }, (_status, _result) => {
            if (_status) {
                setVisible(false);
                message.success("Cover image update requested.");
            } else {
                setValid(false);
            }
            setUploading(false);
        });
    }

    const drawEdit = () => {
        if (uploading) {
            return (<Spin />)
        }

        return (
            <>
                <Space direction="vertical" size={15}>
                    <Upload customRequest={(e) => uploadFile(e)} accept=".jpg, .jpeg, .png"  >
                        <span style={{ "color": "#fff" }}><UploadOutlined /> <small>Upload</small></span>
                    </Upload>
                    <Modal destroyOnClose footer={null} closeIcon={<></>} visible={visible}>
                        <Form className={(!valid) ? "ant-form-item-has-error" : ""} onFinish={(_f) => copyFileFromUrl(_f)} layout="vertical">
                            <Form.Item help={<small style={{color : (!valid) ? "#ff4d4f" : "#AAA"}}>Use a direct link to image</small>} rules={[{required : true}]} name="url" label="Image URL">
                                <Input type="url" placeholder="Image URL" />
                            </Form.Item>
                            <span style={{ "float": "right" }}>
                                <Space>
                                    <Button onClick={() => setVisible(false)}>Cancel</Button><Button htmlType="submit" type="primary">Copy</Button>
                                </Space>
                            </span>
                            <br clear="all" />
                        </Form>
                    </Modal>
                    <span onClick={() => open()} style={{ "color": "#fff" }}><GlobalOutlined /> <small>URL</small></span>
                    <span onClick={() => Modal.confirm({onOk : () => deleteImage(), content : "Are you sure you wish to delete this image?"})} style={{ "color": "#fff" }}><DeleteOutlined /> <small>Remove</small></span>
                </Space>
            </>
        )
    }

    return (
        <>
            <Image 
                className="shadow-large" 
                preview={{ visible: false, mask: (editing) ? drawEdit() : false }} 
                onError={(e) => e.target.src="/no-cover.png"} 
                placeholder={""} 
                src={image_path} />
        </>
    )
}