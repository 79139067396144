import React from "react";
import { Modal, message } from "antd";
import { apiCall } from "../../utils/Api";

export default function ListDelete(props) {

    const { list_id = "", name = "", results = {rows : [], row_count : 0}, setResults = () => { } } = props;

    const confirmDelete = () => {
        Modal.confirm({title : "Delete list?", onOk : () => deleteList(), content : <>Are you sure you want to delete the list "<em style={{"fontWeight" : "600"}}>{name}</em>"?</>})
    }

    const deleteList = () => {
        apiCall("titlelist/delete", {list_id: list_id}, (_status, _result) => {
            if(_status){
                message.success("List deleted successfully.");
                let _rows = results.rows.filter(item => item.list_id !== list_id);
                setResults({...results, "rows" : _rows, "row_count" : results.row_count - 1 || 0});
            } else {
                message.error(_result.error);
            }
        })
    }

    return (
            <div onClick={() => confirmDelete()}>
                {props.children}
            </div>
    );

}