import { Table, Typography, Checkbox } from "antd";
import nocover from "../../../media/no-cover.png";

export default function MultiSelectListEditTable (props){
    const {
        isbns_full_data = [],
        changeHighlights = () => {},
        show_highlight = true,
    } = props
    
    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const columns = [
        {
            title: <small>Cover</small>, width: "30px", dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <>
                    <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "30px", "marginRight": "10px" }} src={"https://cdn1.bookmanager.com/i/b?b=" + e} alt="" />
                </>;
            }
        },
        {
            title: <small>ISBN</small>, width: '70px', dataIndex: 'isbn', key: 'isbn'
        },
        {
            title: <small>Title</small>, width: "150px", dataIndex: 'title', key: 'title'
        },
    ]
    if(show_highlight){
        columns.unshift({
            title: <small>Highlight</small>, width: "25px", render : (e, f) => {
                return (<Checkbox onClick={(e) => {
                    changeHighlights(f.isbn, e.target.checked)
                } }/>)
            }
        })
    }

    return (
    <>
        <Typography.Title level={5}>
            Chosen Titles via Multi-Select:
        </Typography.Title>
        <Table
            size="small"
            pagination={false} 
            columns={columns}
            dataSource={isbns_full_data}
        />
    </>);
}