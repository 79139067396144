import { Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { sales_history_tooltip } from "../utils/Utils";

export default function SalesSummary(props) {
    const { conditions = {} } = props;
    const parseData = () => {
        let c = conditions;
        let ret = []
        if (c.hasOwnProperty("sales_summary") && c.sales_summary.hasOwnProperty("months")) {
            ret.push({
                className: "rowRawData",
                s1: c.sales_summary.months[11] ? c.sales_summary.months[11] : 0,
                s2: c.sales_summary.months[12] ? c.sales_summary.months[12] : 0,
                s3: c.sales_summary.months[13] ? c.sales_summary.months[13] : 0,
                // don't display total on peer data
                total: c.sales_summary.total_stores + c.sales_summary.total_stores_region,
                week: c.sales_summary.week
            })
        }
        return ret;
    }

    const checkBold = (e, f) => {
        let styled_number_return;
        if (!e) {
            styled_number_return = "";
        }else if (f.label !== "Adj" && f.label !== "Retn" && f.label !== "Peer") {
            styled_number_return = <strong><div>{e}</div></strong>;
        } else {
            styled_number_return = <div>{e}</div>
        }
        return styled_number_return;
    }

    const columns = [
        {
            title: <small style={{"fontSize": "10px"}} className="c">Loc</small>, width: "25px", className: "cellBorder", dataIndex: 'total', key: 'total', render: (e, f) => { 
                return (e) ? <div>{e}</div> : "";
            }
        },
        //  style={{"fontSize": "10px"}}
        { title: <small style={{"fontSize": "10px"}}>{moment().startOf("month").add(-2, "month").format("MMM")}</small>, dataIndex: 's1', key: 's1', render: (e, f) => { return checkBold(e, f) } },
        { title: <small style={{"fontSize": "10px"}}>{moment().startOf("month").add(-1, "month").format("MMM")}</small>, dataIndex: 's2', key: 's2', render: (e, f) => { return checkBold(e, f) } },
        { className: "cellBorder", title: <small style={{"fontSize": "10px"}}>{moment().startOf("month").format("MMM")}</small>, dataIndex: 's3', key: 's3', render: (e, f) => { return checkBold(e, f) } },
        {
            title: <small style={{"fontSize": "10px"}}>Wk</small>, width: "27px", dataIndex: 'week', key: 'week', render: (e, f) => {
                return (e) ? <em >{e}</em> : "";
            }
        },
    ]

    return (
        <>
            {/* <Tooltip placement='right' title={sales_history_tooltip}> */}
                <div>
                    <Table
                        rowClassName={(record, index) => {
                            return record.className;
                        }}
                        size="small"
                        className="miniHistory columnHeaders"
                        style={{ "marginRight": "1px", "borderTop": "none", "width": "0px"}}
                        dataSource={parseData()}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            {/* </Tooltip> */}
        </>
    )
}