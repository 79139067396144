/*
    ---- Session ----
    Acts like a global varable, accessible from any component
*/

import React from 'react';

let SessionContext;
let { Provider } = (SessionContext = React.createContext())
export const useSession = () => React.useContext(SessionContext)

export default function Session(props) { 
    // wrap component in a provider. 
    return <Provider value={props.value}>{props.children}</Provider>
}