import { BulbFilled, BulbOutlined } from '@ant-design/icons';
import { ConfigProvider, Spin, Tooltip } from 'antd';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import { apiCall } from './utils/Api';
import Prefs from './utils/Prefs';
import Session from './utils/Session';
import SiteRouter from './utils/SiteRouter';

import './antd.css';
import './App.css';
import './chrome.scrollbar.css';
import './Dark.css';
import "./medium.css";
import "medium-editor/dist/css/medium-editor.css";

/*
- Entry point into rep site. 
- Fetches and sets settings & session data.
- Saves a session id into a cookie (session_repsite_id)
- Draws session context & routes
*/

function App() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState({});
  const [prefs, setPrefs] = useState({
    store: "",
    pendingOO: false,
    bm_data: false,
    last_used : "",
    selected : Cookies.get("selected") || "comments"
  });
  const getSession = () => {
    apiCall("session/get", {}, (_status, _result) => {
      if (_status) {
        Cookies.set("session_repsite_id", _result.session_id, { expires: 365 });
        if (_result.logged_in) {
          setSession(_result);
          setLoading(false);
        } else {
          setSession(_result);
          setLoading(false);
        }
      }
    })
  }

  useEffect(() => {
    getSession();
    setShareCookie();
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    }
  }, []);

  const onFocus = (e) => {
    setShareCookie();
  }

  const setShareCookie = () => {
    // set this cookie so share.bookmanager.com can read and any other subdomains can overwrite
    Cookies.set("last_domain", "rep", { expires: 365, domain: ".bookmanager.com" });
  }
  let _init_dark = ((Cookies.get("darkmode") === "true") ? true : false);
  const [dark, setDark] = useState(_init_dark);
  document.body.classList.toggle('dark', dark);

  const toggleDark = () => {
    document.body.classList.toggle('dark', !dark);
    Cookies.set("darkmode", !dark, { expires: 365 });
    setDark(!dark);
  }


  if (loading) {
    return (
      <Spin spinning={loading}>
        <div style={{ "width": "1310px", "margin": "0 auto", "height": "800px" }}></div>
      </Spin>
    );
  }

  return (
    <ConfigProvider>
      <Prefs value={[prefs, setPrefs]}>
        <Session value={[session, setSession]}>
          <Router>
            <SiteRouter dark_mode={<Tooltip title={"Toggle dark/light display mode"}><div className="no-print" onClick={() => toggleDark(!dark)} style={{ "position": "fixed", "bottom": "25px", "left": "25px", "cursor": "pointer" }}>{(dark) ? <small><BulbFilled style={{ "color": "#666" }} /></small> : <small><BulbOutlined style={{ "color": "#666" }} /></small>}</div></Tooltip>} dark={dark} basename="/" />
          </Router>
        </Session>
        <div id="printArea" />
      </Prefs>
      </ConfigProvider>
  );
}

export default App;