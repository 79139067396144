import { MenuOutlined, PicCenterOutlined } from '@ant-design/icons';
import { Button, Empty, Layout, Radio, Spin, Table, Tooltip } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ListSuggestedCarts from "../components/carts/ListSuggestedCarts";
import SuggestedCartCreateEdit from "../components/carts/SuggestedCartCreateEdit";
import Overlay from "../components/Overlay";
import Paginate from "../components/Paginate";
import Renderer from "../components/Renderer";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import { objectToPath, pathToObject } from "../utils/Utils";

/*
Important note:

Suggested Orders and Suggested Carts are the same thing, working was changed on Feb 21st, 2024.
Refactoring all the variables didn't seem worth it at the time, so they still use "Cart"

- George
*/

export default function SuggestedCart() {

    const history = useHistory();
    const location = useLocation();
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname))
    const [showDetails, setShowDetails] = useState((Cookies.get("suggested_carts_show_lists") === "true") ? false : true);
    const [initLoaded, setInitLoaded] = useState(false);
    const [suggestedCarts, setSuggestedCarts] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [visible, setVisible] = useState(false);
    const [availableStores, setAvailableStores] = useState([]);
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(true);
    const path = "/orders/suggested-orders";
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);
    const [sharedView, setSharedView] = useState(false);
    
    const showModal = (hide_shared = false) => {
        setVisible(true);
        getAllAvailableStores();
    };

    function getAllAvailableStores(){
        apiCall("suggested_cart/getAvailableStores", {},(_status, _result) => {
            if (_status) {
                setAvailableStores(_result);
            } 
        })
        setInitLoaded(true)
    }

    function getAllSuggestedCarts(hide_shared = false, sf = searchFilters){
        apiCall("suggested_cart/getAll", {hide_shared : hide_shared, limit: sf.l, offset: sf.o}, (_status, _result) => {
            if (_status) {
                setSuggestedCarts(_result.rows);
                setRowCount(_result.row_count);
                setLoading(false);
            } 
        })
    }

    //To grab all suggested orders for the list on page load, no matter the status
    useEffect(() => {
        getAllSuggestedCarts(true)
        getAllAvailableStores()
    }, []);

    const displayGroup = () => {
        return (
            <>
                <Radio.Group size="small" value={showDetails.toString()} onChange={(e) => {
                    Cookies.set("suggested_carts_show_lists", showDetails);
                    setShowDetails(!showDetails);
                }} buttonStyle="solid">
                    <Radio.Button value="true"><small><PicCenterOutlined/></small></Radio.Button>
                    <Radio.Button value="false"><small><MenuOutlined/></small></Radio.Button>
                </Radio.Group>
            </>
        )
    }
    
    const columns = [
        {
            title: <small>Name</small>, width: 60, dataIndex: 'isbns_count', key: 'isbns_count' ,
        },
    ]

    function drawPagination(){
        return(
            <Paginate  
                loading={loading} 
                paginate={{ 
                    current: current, 
                    offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0,
                    pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20}} 
                setPaginate={(e) => {
                    setCurrent(e.current)
                    let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                    setSearchFilters(sf);
                    getAllSuggestedCarts(true, sf);
                    history.push({ pathname: path + objectToPath(sf) });
                }} 
                count={rowCount} 
            />
        )
    }

    return (
        <>
            <Layout className="layout" style={{ "minHeight": "200px" }}>
                <LayoutHeader
                    title={<>Suggested Orders</>}
                    description={
                        <>
                            <div style={{"paddingBottom": "10px"}}>
                                <Tooltip title="Create a new suggested order">
                                    <Button type="primary" >
                                        <Overlay 
                                            maskClosable={false} 
                                            width={700} 
                                            component={
                                                    <SuggestedCartCreateEdit 
                                                        valid={valid}
                                                        getAllSuggestedCarts={getAllSuggestedCarts}
                                                        availableStores={availableStores}
                                                />}>Create Order
                                        </Overlay>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div>
                                <Radio.Group 
                                    size='small'
                                    defaultValue={sharedView} 
                                    buttonStyle="solid" 
                                    onChange={(e) => { 
                                        setSharedView(e.target.value)
                                        getAllSuggestedCarts(!e.target.value)
                                        }
                                    }>
                                    <Tooltip title="Orders that can still be worked on before sharing them">
                                        <Radio.Button value={false}>Non-Shared Orders</Radio.Button>
                                    
                                    <Tooltip title="Orders that have been shared and can no longer be edited"></Tooltip>
                                        <Radio.Button value={true}>Shared Orders</Radio.Button>
                                    </Tooltip>
                                </Radio.Group>
                            </div>
                        </>
                    }
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    {drawPagination()}
                    <div className="bc bcg2" style={{ "borderTop": (showDetails) ? "1px solid" : "none" }}>
                        <conditional.true value={(!showDetails)}>
                            <Table
                                rowKey="list_id"
                                size="small"
                                columns={columns}
                                loading={loading}
                                className={"fullTable"}
                                dataSource={suggestedCarts}
                                pagination={false}
                                expandable
                            />
                        </conditional.true>
                        <conditional.true value={(showDetails)}>
                            <Spin spinning={loading}>
                                <div style={{ "paddingLeft": "20px", "paddingRight": "20px" }}>
                                    <conditional.true value={(rowCount < 1)}>
                                        <Empty description="No results." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </conditional.true>
                                    {suggestedCarts.map((item, index) => {
                                        return (
                                            <div key={item.id}>
                                                <Renderer>
                                                    <ListSuggestedCarts
                                                        sharedView={sharedView} 
                                                        showModal={showModal}
                                                        getAllSuggestedCarts={getAllSuggestedCarts} 
                                                        suggestedCarts={suggestedCarts}
                                                        setSuggestedCarts={setSuggestedCarts} 
                                                        availableStores={availableStores}
                                                        getAllAvailableStores={getAllAvailableStores}
                                                        rowCount={rowCount}
                                                        setRowCount={setRowCount}
                                                        data={item}
                                                    />
                                                </Renderer>
                                                {(suggestedCarts.length !== index+1 && <div className="borderBottom " style={{ "marginRight": "-20px", "marginLeft": "-20px", "borderBottomWidth": "6px" }} />)}
                                            </div>)
                                        }
                                    )}
                                </div>
                            </Spin>
                        </conditional.true>
                    </div>
                    {drawPagination()}
                </Layout.Content>
            </Layout>
        </>
    )
}