/*
    ---- Html ----
    Displays HTML
*/

import React from "react";

function Html(props) {

  const { html } = props;

  const display = () => {
    return { __html: html };
  }

  return (<><div style={{ "overflow": "hidden"}} className="html htmlcolor" dangerouslySetInnerHTML={display()}></div></>);

}

export default Html;