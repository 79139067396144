import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import { Col, message, Row, Tooltip } from "antd";
import moment from "moment-timezone";
import { QuestionCircleTwoTone, UploadOutlined } from '@ant-design/icons';

export default function LifetimeSales(props) {

    const { isbn = "" } = props;
    const [results, setResults] = useState({ sales: [] });


    const qp = <>

        <em>A note about lifetime sales...</em><div className="shim" /><div className="shim" />
        The standard deviation in Lifetime Sales statistics can be a result of three things. Please keep these in mind:<div className="shim" /><div className="shim" />
        Stores can choose to keep their sales data for anywhere from 1 year to 8 years, which may not be the same for every store. Also, the number of stores submitting data can change year over year. Lastly, stores may send sales data you see in the Peer stats but not in the Lifetime stats or vice versa.<div className="shim" /><div className="shim" />
        The effect would be small enough that sales trends would not be inaccurate, but it is possible that the actual numbers are not 100% accurately comparable over a shorter time frame.

    </>

    const getLifetimeSales = () => {
        apiCall("title/getLifetimeSales", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error);
            }
        })
    }

    useEffect(getLifetimeSales, [])



    const drawHeadersRow = () => {
        let _months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        return (
            <Row className="bc" style={{ "fontSize": "11px", "color": "#aaa", "textAlign": "right", "borderBottom": "0px solid", "lineHeight": "22px" }}>
                <Col span="4" className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>Year</Col>
                {_months.map((item, _index) => {
                    return (
                        <Col
                            key={_index}
                            style={{ "cursor": "pointer" }}
                            flex={"35px"}
                        >
                            <span className="c">{item}</span>
                        </Col>
                    )
                })}
                <Col flex={"10px"} className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}></Col>
                <Col flex={"50px"} className="bc c" style={{ "textAlign": "right", "paddingRight": "2px" }}>Ttl</Col>
            </Row>
        )
    }


    function drawValues(_data = {}, _type = "", _cart_qty = null) {

        let {
            isbn = "",
            year = "",
            total_sold = 0,
            total_locs = 0,
            sold = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            locs = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        } = _data;
        let _class = "rowRawData";
        return (
            <Row className={_class + " bc"} style={{ "fontSize": "11px", "lineHeight": "22px", "fontWeight": "normal", "textAlign": "right", "borderBottom": "0px solid" }}>

                <Col span="4" className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>{year}</Col>
                {sold.map((item, _index) => {
                    return (
                        <Col
                            flex={"35px"}
                            key={_index}
                            style={{ "textAlign": "right", "paddingRight": "2px" }}
                        ><>{(item) ? item : ""}</>
                        </Col>
                    );
                })}

                <Col flex={"10px"} className="bc c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}></Col>
                <Col flex={"50px"} className="bc c" style={{ "textAlign": "right", "paddingRight": "2px" }}><span className="rowRawData">{total_sold}<sup>{total_locs}</sup></span></Col>

            </Row>
        )
    }



    return (
        <div>
            <div className="shim" />
            <div><small>Lifetime Sales:
                <Tooltip   overlayStyle={{maxWidth: '500px'}} title={<div><small>{qp}</small></div>}>
                    <QuestionCircleTwoTone style={{ "paddingLeft": "5px", "cursor": "pointer", "fontSize": "14px" }} />
                </Tooltip></small></div>
            <div className="bc" style={{ "marginLeft": "0px", "border": "1px solid", "borderLeft": "6px solid" }}>
                {drawHeadersRow()}
                {results.sales.reverse().map(item => drawValues(item))}
            </div>
        </div>
    )
}