import { ClockCircleFilled, ShareAltOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Layout, message, Modal, Popconfirm, Radio, Select, Space, Spin, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";
import IndividualAccountHistoryModal from '../../components/admin/IndividualAccountHistoryModal';
import Overlay from '../../components/Overlay';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ucfirst } from '../../utils/Utils';

export default function MyAccountsPage() {

    const history = useHistory();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chosenView, setChosenView] = useState("all");
    const [sort, setSort] = useState("name");
    

    let _term = history.location.state || "";
    const [term, setTerm] = useState(_term);
    
    



  

    const fetchAll = (_term = term) => {
        setLoading(true)
        let _sel = "all"
        let _obj = {
            my_accounts: (_sel === "my_accounts" || _sel === "all") ? true : false,
            approved: (_sel === "approved" || _sel === "all") ? true : false,
            pending: (_sel === "pending" || _sel === "all") ? true : false,
            declined: (_sel === "declined" || _sel === "all") ? true : false,
        }
        if (_term) {
            _obj.filter = _term;
        }
        if (_sel === "all") {
            _obj.potential = true;
        } else {
            _obj.potential = false;
        }
        apiCall("rep_control/getAll", _obj, (_status, _result) => {
            if (_status) {
                setTerm(_term);
                setResults(_result);
            }
            setLoading(false)
        })
    }

    const filterResults = () => {
        switch (chosenView) {
            case "my_accounts":
                return results.filter(item => item.basic_link_status === "approved");
            case "approved":
                return results.filter(item => item.basic_link_status === "approved" && item?.share_relationship_info?.status === "approved");
            case "not_sharing":
                return results.filter(item => item.basic_link_status === "approved" && item?.share_relationship_info?.status !== "approved");
            case "requested":
                return results.filter(item => item.basic_link_status === "requested");
            default:
                return results;
        }
    }

    const sortResults = (_arr) => {
        let has = _arr.filter(item => item.has_link || item?.share_relationship_info?.status || item?.my_account_relionship_info?.status);
        let has_not = _arr.filter(item => !item.has_link && !item?.share_relationship_info?.status && !item?.my_account_relionship_info?.status);
        return (has.sort((a, b) => a[sort].localeCompare(b[sort])).concat(has_not.sort((a, b) => a[sort].localeCompare(b[sort]))))
    }

    useEffect(() => {
        fetchAll(history.location.state)
    }, [history.location.state]);

    const getStarted = () => {
        Modal.info({
            width: "650px", icon: <></>, title: "Getting started", content: <div style={{ "height": "calc(100vh - 300px)", "overflow": "auto", "paddingRight": "10px" }}>
                <Divider style={{ "margin": "15px 0px" }} />
                <p> Accounts listed on this page are stores that you can work with, stores you currently work with, and even stores you once worked with. As well, if you are working with a store, you are able to request to see their data.  </p>
                <p> If the store you want to work with is not listed on this page, you may need to search using the search bar, or try changing the filters to narrow down your results.  </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a gray add person icon <nobr className="noInteract"><Tag className="tag-btn" color="gray"><strong><UsergroupAddOutlined /></strong></Tag></nobr> means you are not connected to them, and need to send a request to connect. </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a green add person icon <nobr className="noInteract"><Tag className="tag-btn" color="#78c042"><strong><UsergroupAddOutlined /></strong></Tag></nobr> are ones that have agreed to work with you, but are not yet sharing their data with you.  </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a gray share icon <nobr className="noInteract"><Tag className="tag-btn" color="gray"><strong><ShareAltOutlined /></strong></Tag></nobr> means you have not yet requested to see their data. You cannot send a request to see their data until you have successfully connected with them </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts with a green share icon <nobr className="noInteract"><Tag className="tag-btn" color="#78c042"><strong><ShareAltOutlined /></strong></Tag></nobr> are sharing their store data with you, and you are able to share lists and catalogues with them.  </p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p> Accounts listed with a purple share icon <nobr className="noInteract"><Tag className="tag-btn" color="#8f3fe4"><strong><ShareAltOutlined /></strong></Tag></nobr> indicate that this request has been sent once or more and you are waiting on a response from the store</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Please contact <a href="mailto:mail@bookmanager.com">mail@bookmanager.com</a> | 250.763.4415 if you have any questions about sharing with your reps, and the data that is visible.</p>
            </div>
        })
    }

    const updateRow = (_item) => {
        let _update = [...results];
        let _index = _update.findIndex(itm => itm.contact_id === _item.contact_id);
        _update[_index] = _item;
        setResults(_update);
    }


    const submitRequest = (_obj) => {
        if (_obj.has_link) {
            apiCall("rep_control/removeLink", { contact_id: _obj.contact_id }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                } else {
                    message.error(_result.error)
                }
            });
        } else {
            apiCall("rep_control/submitLink", { contact_id: _obj.contact_id }, (_status, _result) => {
                if (_status) {
                    updateRow(_result);
                } else {
                    message.error(_result.error)
                }
            });

        }
    }

    const headerValue = () => {
        return (<>
            My Accounts
            {/* <Button size='small' type="text" onClick={() => getStarted()}><small><InfoCircleOutlined /> Getting started</small></Button> */}
        </>)
    }

    const linkOut = (url) => {

        if (!url) { return; }
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = "https://" + url;
        }

        // Open the URL in a new tab
        window.open(url, "_blank");
    }


    const SearchField = (props) => {
        const { fetchAll = () => { }, initial = "" } = props;
        const [term, setTerm] = useState(initial);

        useEffect(() => {
            setTerm(initial)
        }, [initial])
        return (
            <Form.Item >
                <Input.Search onSearch={(e) => fetchAll(e)} allowClear onChange={(e) => setTerm(e.target.value)} value={term} enterButton placeholder="Search for a store" style={{ "width": "350px" }} />
            </Form.Item>
        )
    }


    const getIcon = (e, _is_account) => {

        let tip = "";
        if(_is_account){
            tip = <>A <strong style={{"color" : "#78c042"}}>green</strong> checkmark here means that the store has confirmed you are their rep, which lets you share titles and catalogues with them. An <strong style={{"color" : "#e77e21"}}>orange</strong> 'requested' means the store has not yet accepted the rep request. (If the store accepts the rep request, but not the data share, we recommend you reach out to them directly to discuss. It's possible this was unintentional, so chatting with them to discuss the features is key!)</>
        } else {
            tip = <>A <strong style={{"color" : "#78c042"}}>green</strong> checkmark here indicates the store is sharing their data with you, meaning you're able to see their sales, stock and order quantities for your lines. An <strong style={{"color" : "#e77e21"}}>orange</strong> 'requested' means the store has not yet approved the data share. (And if this field is <u>blank</u> but there's a green checkmark under the My Account, this means that on our old site the store never accepted your data share request. This might have been intentional, or, the store simply missed the original notification and inadvertently never shared their data. In these rare cases, we suggest that you click the 'cancel' on the left, and then re-submit a fresh new 'request'. We also then recommend that you reach out to the store, and discuss request/data share - so that way your connection here is quickly re-established!)</>;
        }

        if (e === "approved" || e === "approved_inactive") {
            return (<Tooltip title={<small>{tip}</small>}><CheckCircleFilled style={{ "fontSize": "20px", "color": "#78c042", "cursor" : "pointer" }} /></Tooltip>)
        } else if (e === "requested" || e === "rerequested") {
            return (<Tooltip title={<small>{tip}</small>}><ClockCircleFilled style={{ "fontSize": "20px", "color": "#e77e21", "cursor" : "pointer" }} /></Tooltip>)
        } else if (e === "declined" || e === "removed") {
            return (<CloseCircleFilled style={{ "fontSize": "20px", "color": "#A52A2A" }} />)
        } else {
            return (<></>)
        }

    }

    const columns = [
        {
            title: <small>Action</small>, width: 90, dataIndex: 'has_link', key: 'has_link', render: (e, f) => {

                if (e) {
                    return (
                        
                            <Tooltip placement={"right"} title={<small><strong>Cancel</strong> will disable the full rep link between you and the store. In order to re-establish the connection, you'll need to send a new request to get started.</small>}><Popconfirm title="Are you sure?" onConfirm={() => submitRequest(f)}><Button block type={"default"} size="small"><small>Cancel</small></Button> </Popconfirm></Tooltip>
                       
                    )
                } else {
                    return (<Tooltip placement={"right"}  title={<small><strong>Request</strong> will send a notification to the store, letting them know that you want to be tagged as their rep so that you can share titles and catalogues with them. Once the store approves the rep request, they can also confirm if they want to share their data with you, meaning you will be able to see this account's sales, on hand and on order quantities for your lines.</small>}><Button onClick={() => submitRequest(f)} block type={"primary"} size="small"><small>Request</small></Button></Tooltip>)
                }
            }
        },
        {
            title: <small>My Account</small>, width: 90, align: "center", dataIndex: 'my_account_relionship_info', key: 'my_account_relionship_info', render: (e, f) => {

                if(!f.has_link){
                    return(<></>)
                }

                return getIcon(e.status, true);
            }
        },
        {
            title: <small>Shared Data</small>, width: 90, align: "center", dataIndex: 'share_relationship_info', key: 'share_relationship_info', render: (e, f) => {

                if(!f.has_link){
                    return(<></>)
                }

                return getIcon(e.status, false);

            }
        },
        {
            title: <small>Store</small>, dataIndex: 'name', key: 'name'
        },
        { title: <small>SAN</small>, dataIndex: 'san', key: 'san' },
        {
            title: <small>Email</small>, dataIndex: 'email', key: 'email', render: (e) => {
                return (<a href={"mailto:" + e}>{e}</a>)
            }
        },
        {
            title: <small>Website</small>, width: 73, dataIndex: 'url', key: 'url', render: (e) => {
                return (<Button onClick={() => linkOut(e)} disabled={(!e)} size='small'><small>Website</small></Button>)
            }
        },
        { title: <small>City</small>, dataIndex: 'city', key: 'city' },
        { title: <small>Province</small>, dataIndex: 'province', key: 'province' },
        {
            title: <small>History</small>, width: 90, dataIndex: 'history', key: 'history', render: (e, f) => {
                return (
                    <Button block disabled={!f.has_link} size="small" type='primary'>
                        <Overlay
                            width={600}
                            component={
                                <IndividualAccountHistoryModal contact_id={f.contact_id} />
                            }
                        ><small>Show History</small>
                        </Overlay>
                    </Button>
                )
            }
        },
    ]

    return (
        <>
            <Layout style={{ "backgroundColor": "#fff" }}>
                <LayoutHeader
                    title={headerValue()}
                    filters={
                        <Form size="small" layout="vertical">
                            <Space>
                                <SearchField initial={term} fetchAll={fetchAll} />
                                <Form.Item>
                                    <Radio.Group onChange={(e) => setChosenView(e.target.value)} defaultValue={"all"} buttonStyle="solid">
                                        <Radio.Button value="all"><small>All</small></Radio.Button>
                                        <Radio.Button value="my_accounts"><small>All My Accounts</small></Radio.Button>
                                        <Radio.Button value="approved"><small>Sharing Data Only</small></Radio.Button>
                                        <Radio.Button value="not_sharing"><small>My Accounts Not Sharing Data</small></Radio.Button>
                                        <Radio.Button value="requested"><small>Requested Only</small></Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item>
                                    <Select
                                        style={{ "width": "120px" }}
                                        defaultValue="name"
                                        onChange={(e) => setSort(e)}
                                        options={[
                                            { value: "name", label: <small>Sort by Name</small> },
                                            { value: "province", label: <small>Sort by Province</small> },
                                            { value: "city", label: <small>Sort by City</small> },
                                        ]}
                                    />
                                </Form.Item>
                            </Space>
                            <br clear="both" />
                        </Form>
                    }
                />
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                    <div style={{ "padding": "0px 20px", "marginTop": "-6px", "marginBottom": "-6px" }}>
                        <div style={{ "padding": "0px" }}>
                            <br />
                            <Table
                                rowKey={"contact_id"}
                                bordered
                                loading={loading}
                                size='small'
                                dataSource={sortResults(filterResults())}
                                columns={columns}
                                pagination={false}
                            />
                            <br /><div className="shim" />
                        </div>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}