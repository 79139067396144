import { AppstoreOutlined, CloseOutlined, MenuOutlined, PicLeftOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Radio, Spin, Table, Typography } from "antd";
import Cookies from 'js-cookie';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { bestsellerColor, futureColor, getListPrice, parseSubjectsWeird, successColor, ucfirst } from "../../utils/Utils";
import ItemLayouts from "../ItemLayouts";
import Paginate from "../Paginate";
import Renderer from "../Renderer";

export default function TitleResults(props) {

    const [session, setSession] = useSession(); 
    const { 
        isbn = "", 
        term = "", 
        type = "keyword", 
        name = "", 
        has_close = true, 
        related = false, 
        series = false,
    } = props;

    const ref = useRef();
    const [results, setResults] = useState({ rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [current, setCurrent] = useState(1);

    const [filters, setFilters] = useState({
        term: term,
        display: Cookies.get("last_sub_display") || "list",
        sort: "date",
        show_details: false
    });

    const [searchFilters, setSearchFilters] = useState(
        {
            o: 0, // offset
            l: 10, // limit
        }
    )

    const scrollIntoViewWithOffset = (selector, offset) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                selector.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset,
        })
    }

    useEffect(() => {
        if (loading) { scrollIntoViewWithOffset(ref.current, 280) }
    }, [])

    const getItems = () => {
        let _filters = { ...searchFilters }
        _filters.t = term;
        _filters.k = type;
        setLoading(true);
        // related category flagged, use related 
        if (related) {
            if(related !== "related"){
                apiCall("title/getRelatedEditionsDetails", { isbn: isbn, detail_category: related, limit: searchFilters.l, offset: searchFilters.o }, (_status, _result) => {
                    if (_status) {
                        setResults(_result);
                    }
                })
            }
            setLoading(false);
            return; 
        }
        // series category flagged, use related 
        if (series) {
            apiCall("browse/get", { z : series, l: searchFilters.l, o: searchFilters.o  }, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                }
                setLoading(false);
            })
            return; 
        }
        // Not related, regular browse call
        if (!related) {
            if(isbn){
                _filters.exclude_isbn = isbn; 
            }
            apiCall("browse/get", _filters, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                    setLoading(false);
                }
            })
        }
    }

    useEffect(getItems, [searchFilters.o, searchFilters.l]);

    const colorDate = (_timestamp) => {
        let now = moment().unix();
        let color = "inherit";
        if (_timestamp > now) {
            // future
            color = futureColor;
        } else {
            // within the last two months
            if ((now - _timestamp) < 5274000) {
                // within the last two months
                color = successColor;
            }
        }
        return color;
    }

    const columns = [
        {},
        {
            title: <small>PubDate</small>, dataIndex: 'release_date', key: 'release_date', render: (e) => {
                return <span style={{ "color": colorDate(e) }}>{moment(e * 1000).format("MMMD-YY")}</span>;
            }
        },
        {
            title: <small>Status</small>, dataIndex: 'print_status', key: 'print_status', render: (e) => {
                return (e) ? ucfirst(e) : ""
            }
        },
        {
            title: <small>Rank</small>, dataIndex: 'rank', key: 'rank', render: (rank) => {
                let color = "";
                let is_future = rank.hasOwnProperty('future') ? true : false; 
                if (is_future) {
                    color = "#2c7eb5";
                } else {
                    color = "#2cb58d";
                    if (rank.bestseller <= 1000) { 
                        color = "#b52c2c"; 
                    } else if (rank.bestseller <= 10000) { 
                        color = "#d26a16" 
                    } else if (rank.bestseller <= 30000) { 
                        color = "#c1aa2d" 
                    } else if (rank.bestseller <= 100000) { 
                        color = "#73b52c"; 
                    }
                }
                if(rank.hasOwnProperty("bestseller")){
                    return <span style={{ "color": color }}>{rank.bestseller}</span>
                }else{
                    return <span style={{ "color": futureColor }}>{rank.future}</span>
                }
            }
        },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {

                return(getListPrice(f.list_price_can, f.list_price_usa, session.is_canadian, true))
              
            }
        },
        { title: <small>Bd</small>, dataIndex: 'binding_code', key: 'binding_code' },
        {
            title: <small>Title</small>, className: "nowrap250", dataIndex: 'title', key: 'title', render: (e, f) => {
                return (<a onClick={() => expandCell(f)}>{e}{(f.subtitle) ? " : " + f.subtitle : ""}</a>)
            }
        },
        {
            title: <small>Author</small>, className: "nowrap", dataIndex: 'authors', key: 'authors', render: (e) => {
                return e.map(item => item.name).join(", ")
            }
        },
        { title: <small>Series</small>, className: "nowrap", dataIndex: 'series', key: 'series' },
        { title: <small>#</small>, dataIndex: 'series_num', key: 'series_num' },
        {
            title: <small>Subjects</small>, dataIndex: 'subjects', key: 'subjects', render: (e) => {
                return <span>
                    {parseSubjectsWeird(e, 15)}
                </span>;
            }
        },
        { title: <small>Publisher</small>, className: "nowrap", dataIndex: 'publisher', key: 'publisher' },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn' },
    ]

    const drawChildTable = (_record) => {

        console.log(_record);
        return (
            <>
                <div className="hideHover" style={{ "margin": "-7px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <div className="shim" /> <div className="shim" /> <div className="shim" />
                        <Renderer close={false}><ItemLayouts data={_record} display="details2" /></Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const expandCell = (_data, _type) => {
        if (!expanded.includes(_data.isbn)) {
            setExpanded([_data.isbn]);
        } else {
            setExpanded([]);
        }
    }

    const drawPagination = () => {
        return (
            <div style={{ "margin": "0px -20px" }}>
                <Paginate
                    loading={loading}
                    paginate={{ current: current, offset: (searchFilters).hasOwnProperty("o") ? searchFilters.o : 0, pagesize: (searchFilters).hasOwnProperty("l") ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        setSearchFilters({ ...searchFilters, "o": e.offset, "l": e.pagesize });
                    }} count={results.row_count } />
            </div>
        )
    }

    const drawFilters = () => {
        return (
            <div style={(has_close) ? { marginBottom: "0px" } : { marginTop: "-1px" }} className="closebar">
                <Form size="small" layout="vertical">
                    <conditional.true value={(!has_close)}>
                        <div style={{ "height": "8px" }} className="shim" />
                    </conditional.true>
                    <div style={{ "padding": "20px", "paddingBottom": "0px" }}>
                        <div className="float-flex">
                            <Typography.Title style={{ "lineHeight": "17px" }} level={5}>{name}</Typography.Title>
                            <div style={{ "paddingRight": "6px", "marginTop": "-6px" }}>
                                <Radio.Group size="small" value={filters.display} onChange={(e) => {
                                    Cookies.set("last_sub_display", e.target.value);
                                    setFilters({ ...filters, "display": e.target.value })
                                
                                }} buttonStyle="solid">
                                    <Radio.Button value={"details2"}><small><PicLeftOutlined /></small></Radio.Button>
                                    <Radio.Button value={"list"}><small><MenuOutlined /></small></Radio.Button>
                                    <Radio.Button value={"cards"}><small><AppstoreOutlined /></small></Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="shim" style={{ "height": "8px" }} />
                    </div>
                </Form>
            </div>
        )
    }

    const drawResults = () => {
        return (
            <div>
                <div ref={ref}></div>
                <conditional.true value={(filters.display === "list")}>
                    <div style={{ "margin": "0 -20px" }}>
                        <Table
                            size="small"
                            pagination={false}
                            rowKey={"isbn"}
                            loading={loading}
                            columns={columns}
                            dataSource={results.rows}
                            expandable
                            expandedRowKeys={expanded}
                            expandIconColumnIndex={-1}
                            onExpand={(e) => { return false; }}
                            expandIcon={() => { return (<></>) }}
                            expandedRowRender={((record) => drawChildTable(record))}
                        />
                    </div>
                </conditional.true>
                <conditional.true value={(filters.display !== "list")}>
                    <br />
                    <Spin spinning={loading}>
                        {/* Split into chunks so renderer can draw placeholder */}
                        <conditional.true key={"cards"} value={(filters.display === "cards")}>
                            <div>
                                {results.rows.reduce((all, one, i) => {
                                    const ch = Math.floor(i / 5);
                                    all[ch] = [].concat((all[ch] || []), one);
                                    return all
                                }, []).map((item, index) => {
                                    return <div key={item[0].isbn}><Renderer><ItemLayouts data={item} index={index} display={filters.display} /></Renderer><Divider dashed /></div>
                                })}
                            </div>
                        </conditional.true>
                        <conditional.true value={(filters.display !== "cards")}>
                            <div>
                                {results.rows.map((item) => {
                                    return <div key={item.isbn}><Renderer close={false}><ItemLayouts data={item} display={filters.display} /></Renderer> <Divider dashed /></div>
                                })}
                            </div>
                        </conditional.true>
                    </Spin>
                </conditional.true>
            </div>
        )
    }

    if (results.rows.length < 1 && !loading) {
        return "No related titles found.";
    }

    return (
        <div>
            {drawFilters()}
            <conditional.true value={related !== "related"}>
                {drawPagination()}
            </conditional.true>
            {drawResults()}
            <conditional.true value={related !== "related"}>
                {drawPagination()}
            </conditional.true>
        </div>
    )
}