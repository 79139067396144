import { Button, Divider, Form, Input, Space, Table, Typography } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

import { apiCall } from "../../utils/Api";

export default function IndividualAccountHistoryModal(props) {

    let {
        contact_id = 0,
        close = () => { },
    } = props;

    const [contactHistory, setContactHistory] = useState([]);



    const drawStatus = (_note) => {
        let ret;
        let color = (_note.misc.approved) ? "#00aa5f" : "#aa0000";
        let corp = "";
        // eslint-disable-next-line default-case
        switch (_note.message_type) {
            //initial rep request from the store
            case "rep_ack":
                corp = _note.corp.name;
                ret = <><strong>{_note.corp.name}</strong> has {(_note.misc.approved) ? <strong style={{ "color": color }}>accepted</strong> : <strong style={{ "color": color }}>declined</strong>} your rep request. {(_note?.misc?.declined_reason && <em> Reason: {_note?.misc?.declined_reason}</em>)}</>
                break;
            case "rem_rep_syn":
                corp = _note.misc.request_contact.name
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> a rep request.</>
                break;
            case "rem_rep_resyn":
                corp = _note.misc.request_contact.name
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> another rep request.</>
                break;

            // data sharing request with the rep
            case "repshare_ack":
                corp = _note.corp.name;
                ret = <><strong>{_note.corp.name}</strong> has {(_note.misc.approved) ? <strong style={{ "color": color }}>accepted</strong> : <strong style={{ "color": color }}>declined</strong>} your data share request. {(_note?.misc?.declined_reason && <em> Reason: {_note?.misc?.declined_reason}</em>)}</>
                break;
            case "rem_repshare_syn":
                corp = _note.misc.request_contact.name;
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> a data share request.</>
                break;
            case "rem_repshare_resyn":
                corp = _note.misc.request_contact.name;
                ret = <>You sent <strong>{_note.misc.request_contact.name}</strong> another data share request.</>
                break;
        }
       
        return ret; 
        
    }



    const columns = [
        {
            title: <small>Status</small>,
            key: 'status',
            dataIndex: 'status',
            render: (e, f) => {
                return drawStatus(f);
            }
        },
        {
            title: <small>Date Changed</small>,
            width : 170,
            key: 'datechanged',
            dataIndex: 'datechanged',
            render: (e, f) => {
                return moment(e * 1000).format("MMM Do, YYYY - hh:mma")
            }
        },
    ];

    const getHistoryData = () => {
        let contact_history = [];
        apiCall("notification/getAllWithStore", { store_id: contact_id }, (_status, _result) => {
            if (_status) {
                contact_history = _result
                setContactHistory(contact_history)
            }
        });
    }

    useEffect(getHistoryData, []);

    function closeModal() {
        close();
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>History of Interactions</Typography.Title>
                <Divider />
                <Table
                    bordered
                    size='small'
                    scroll={{ y: 320 }}
                    pagination={false}
                    dataSource={contactHistory}
                    columns={columns}
                />
                <Divider />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => closeModal()}>Close History</Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>
    );
}