import { Modal } from "antd";
import React, { useState } from "react";

export default function Overlay(props) {

    const { 
        maskClosable=true, 
        component, 
        width=1024, 
        inline = false, 
        auto_open = false, 
        callback = () => {}
    } = props;
    const [modalVisible, setModalVisible] = useState(auto_open);

    const close = () =>{
        callback(); 
        setModalVisible(false);
    }

    return (
        <>
            <Modal
                onCancel={close}
                maskClosable={maskClosable}
                visible={modalVisible}
                closable={true}
                bodyStyle={{"padding" : "0px" , "margin" : "0px"}}
                destroyOnClose
                width={width}
                footer={null}
            >
                {React.cloneElement(component, { close: close })}
            </Modal>
            <div style={{"display" : (inline) ? "inline" : "block"}} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setModalVisible(true)}}> {props.children}</div>
        </>
    );

}