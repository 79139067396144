import { FacebookFilled, TwitterSquareFilled } from '@ant-design/icons';
import { Button, Divider, Input, message, Space, Table, Typography } from "antd";
import React, { useState } from "react";

import nocover from "../../media/no-cover.png";
import { apiCall } from '../../utils/Api';
import { useSession } from "../../utils/Session";

export default function SuggestedCartShare(props) {

    const { 
        close = () => { }, 
        list_id = "", 
        data = [] 
    } = props;

    const [session, setSession] = useSession();

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const columns = [
        {
            title: <small>Cover</small>, width: "30px", dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <>
                    <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "30px", "marginRight": "10px" }} src={"https://cdn1.bookmanager.com/i/b?b=" + e} alt="" />
                </>;
            }
        },
        {
            title: "Title", key: "title", dataIndex: "title"
        },
        {
            title: "ISBN", key: "isbn", dataIndex: "isbn"
        },
        {  
            title: "Total Quantity", key: "quantity", dataIndex: "quantity"
        },
        {
            title: "Multi-Store Quantities", key: "ms_quantity", dataIndex: "ms_quantity", render: (e, f) => {
                return f.ms_quantity.map(item => item).join(", ")
            }
        }
    ]

    const setSuggestedCartsInSession = () => {
        let temp_scs_from_session = session.suggested_carts_working_with.map(item => item.id);
        temp_scs_from_session.forEach((cart, index) => {
            if(cart === list_id){
                temp_scs_from_session.splice(index, 1);
            }
        });
        apiCall("session/setSuggestedCartsWorkingWith", {suggested_cart_ids_json: JSON.stringify(temp_scs_from_session)}, (_status, _result) => {
            if(_status){
                setSession(_result)
            }
        })
    }

    const sendSharedList = () => {
        apiCall("suggested_cart/share", {cart_id: list_id}, (_status, _result) => {
            if(_status){
                setSuggestedCartsInSession(_result);
                message.success("Suggested Order has been successfully shared")
            }
        })
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Share with {data.store_info.name}</Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} />
                <span style={{ "display": "block", "fontWeight": "normal", "paddingBottom": "10px"}}>
                    Once you share this, you will no longer be able to edit or delete.
                </span>
                
                <Typography.Title level={5}>Selected ISBNs:</Typography.Title>
                <Table
                    size='small'
                    columns={columns}
                    dataSource={data.rows}
                />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Cancel</Button>
                        <Button type='primary' onClick={(e) => sendSharedList()}>Share</Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>
    );
}