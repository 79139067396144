import { Image, Space, Table, Tooltip } from "antd";
import moment from "moment-timezone";

export default function TitlePubstockTab(props){
    const {
        pubstockData = [],
    } = props

    const smallText = (title) => {
        return (
            <small>{title}</small>
        )
    }

    const drawOnOrderWithTooltip = () => {
        let _arr = [
            "ITO - Import To Order: must be imported, taking at least 2-6 weeks to get stock.",
            "Qwk - Quick: not stocked, but can get stock in less than 2 weeks.",
            "POD - Print On Demand: copies printed on demand.",
            "QPOD - Quick Print On Demand: copies printed on demand the same day.",
            "RP - Reprinting: the book is being reprinted by the publisher.",
            "NYR - Not Yet Received/Published: not yet received by the wholesaler, or published by the publisher.",
            "TOS - Temporarily out of stock: item will be in stock shortly.",
            "BOO - 'Backorder Only' title available as special order. ETA unknown.",
            "Mgd - Managed title: quantities shipped are managed manually"
        ]

        return (
            <Tooltip overlayInnerStyle={{ width: "550px" }} title={<>
                <div>Non-numeric O/O pubstock codes.</div>
                <div className="shim" />
                <div>
                    <small>
                        {_arr.map(item => {
                            return (<div>{item}</div>)
                        })}
                    </small>
                </div>
            </>}>
                <span style={{ "cursor": "pointer" }}><small>O/O</small></span>
            </Tooltip>
        )
    }

    const columns = [
        {
            title: smallText("Source"), dataIndex: "acct", key: "acct", render (e, f) {
                if(f.country === "Canada"){
                    return (
                        <Space>
                            <Image 
                                preview={false}
                                src="https://bookmanager.com/i/CA.png"/> 
                            {f.acct}
                        </Space>
                    )
                } else if(f.country === "USA"){
                    return (
                        <Space>
                            <Image 
                                preview={false}
                                src="https://bookmanager.com/i/US.png"/>
                            {f.acct}
                        </Space>
                    )
                }
            }
        },
        {
            title: smallText("O/H"), dataIndex: "onhand", key: "onhand", render (e, f) {
                return f.onhand;
            }
        },
        {
            title: drawOnOrderWithTooltip(), dataIndex: "onorder", key: "onorder", render (e, f) {
                return drawOO(f.onorder);
            }
        },
        {
            title: <Tooltip title="Prices are in vendor's currency">{smallText("Price")}</Tooltip>, dataIndex: "supplier_price", key: "supplier_price", render (e, f){
                return ("$" + f.supplier_price) 
            }
        },
        {
            title: smallText("As Of"), dataIndex: "as_of", key: "as_of", render (e, f) {
                return moment(f.as_of * 1000).fromNow()
            }
        },
        {
            title: smallText("Returnable"), dataIndex: "returnable", key: "returnable"
        },
    ];

    const ColumnHelp = (props) => {
        return (
            <Tooltip title={props.title}>
                <span style={{ "cursor": "pointer" }} >{props.children}</span>
            </Tooltip>
        )
    }

    const drawOO = (_code) => {
        let msg = ""
        // eslint-disable-next-line default-case
        switch (_code) {
            case "ITO":
                msg = <>Import To Order<small><br />Must be imported, taking at least 2-6 weeks to get stock.</small></>
                break;
            case "Qwk":
                msg = <>Quick<small><br />Not stocked, but can get stock in less than 2 weeks.</small></>
                break;
            case "LOW":
                msg = <>LOW<small><br />Stock onhand is relatively low. Item may get backordered/cancelled.</small></>
                break;
            case "POD":
                msg = <>Print On Demand<small><br />Copies printed on demand.</small></>
                break;
            case "QPOD":
                msg = <>Quick Print On Demand<small><br />Copies printed on demand the same day.</small></>
                break;
            case "RP":
                msg = <>Reprinting<small><br />The book is being reprinted by the publisher.</small></>
                break;
            case "NYR":
                msg = <>Not Yet Received / Published<small><br />Not received by the wholesaler or published by the publisher.</small></>
                break;
            case "TOS":
                msg = <>Temporarily out of stock<small><br />Item will be in stock shortly.</small></>
                break;
            case "BOO":
                msg = <>Backorder Only<small><br />Title available as a special order. ETA unknown.</small></>
                break;
            case "Mgd":
                msg = <>Managed Title<small><br />Quantities shipped are managed manually.</small></>
                break;
        }
        if (msg) {
            return (<ColumnHelp title={msg}>{_code}</ColumnHelp>)
        }
        return _code;
    }

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={pubstockData}
            pagination={false}
        />
    )
}