import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse, Radio, Tooltip, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { apiCall } from '../../utils/Api';
import conditional from "../../utils/conditional";
import { useSession } from "../../utils/Session";
import IndividualSuggestedCartsOnBrowse from './IndividualSuggestedCartsOnBrowse';

export default function DrawSuggestedCarts(props) {

    const [session, setSession] = useSession();
    const { 
        isbn, 
        isMultiStore = false,
        storeName = "", 
    } = props;
    const [separateSuggestionValues, setSeparateSuggestionValues] = useState(false);
    const [stores, setStores] = useState(props.stores);
    const [isAdded, setIsAdded] = useState(false);

    const tooltip_title = "Changes made in the \"Total\" section will not be dispersed in the \"Individual\" section"

    function drawHeader(){
        let cart = (isMultiStore ? "Carts" : "Cart")
        return (
            <>
                <Typography.Title level={4}> Suggested {cart} for {storeName} </Typography.Title> 
                <conditional.true value={isMultiStore}>
                        <Radio.Group 
                            style={{"paddingBottom": "10px"}}
                            size='small'
                            defaultValue={separateSuggestionValues} 
                            buttonStyle="solid" 
                            onChange={(e) => setSeparateSuggestionValues(e.target.value)}>
                            <Radio.Button value={false}>
                                <Tooltip title={"Total suggested quantity to be ordered, regardless of location being ordered to"}>
                                    Provide Total Quantity
                                </Tooltip>
                            </Radio.Button>
                            <Radio.Button value={true}>
                                <Tooltip title={"Individual suggested quantities to be ordered per-store. " + tooltip_title}>
                                    Provide Individual Quantities
                                </Tooltip>
                            </Radio.Button>
                        </Radio.Group>
                    {/* </Tooltip> */}
                </conditional.true>
            </>
        )
    }

    const determineIfAddedToCart = (cart) => {
        if(cart.rows.length > 0){
            if(cart.rows.some((entry) => entry.isbn === isbn)){
                return true;
            }
        }
        return false;
    }

    function setSuggestedCartsWorkingWith(_sc){
        let this_stores_carts = session.suggested_carts_working_with.map(item => item.id)
        apiCall("session/setSuggestedCartsWorkingWith", {suggested_cart_ids_json: JSON.stringify(this_stores_carts)}, (_status, _result) => {
            if(_status){
                setSession(_result)
            }
        })
    }

    return (
        <>
            <div style={{transitionProperty: "border", "transitionDuration": "0.2s", "marginLeft": "5px" }}>
                {drawHeader()}
                <Collapse 
                    expandIconPosition="left"
                    expandIcon={(p) => {return (!p.isActive) ?  <PlusOutlined style={{"fontSize" : "9px"}} /> : <MinusOutlined style={{"fontSize" : "9px"}} />}}
                    ghost  
                    style={{"marginBottom": "5px"}}>
                    {session.suggested_carts_working_with.map((cart,index) => {
                        if(cart.store_info.name === storeName){
                            const is_added = determineIfAddedToCart(cart);
                            return (
                                <Collapse.Panel  
                                    header={
                                    <span>
                                        <strong className='c' >{cart.name}</strong>
                                        <strong className='c' style={{"color": "#14A346"}}>{(isAdded || is_added) ? <>  (Currently in Cart)</> : ""}</strong>
                                    </span>
                                    } key={index}>
                                    <IndividualSuggestedCartsOnBrowse
                                        cart={cart}
                                        isbn={isbn}
                                        stores={stores}
                                        is_added={is_added}
                                        syncedIsAdded={isAdded}
                                        setSyncedIsAdded={setIsAdded}
                                        setSuggestedCartsWorkingWith={setSuggestedCartsWorkingWith}
                                        separateSuggestionValues={separateSuggestionValues}
                                    />
                                </Collapse.Panel>
                            )
                        }
                    })}
                </Collapse>
            </div>
        </>
    )
}


