import React from "react";

export default function SliderItem(props) {
    const { action = () => { } } = props;
    const key = Math.random(1000).toString();
    /*
        When a visitor presses they with either release in a 5px radius (click) or drag and move futher (no click)
    */
    let x = 0;
    let buffer = 5;
    const onDown = (e) => { x = e.pageX; }
    const onUp = (e, _item) => {
        if (!(Math.abs(e.pageX - x) > buffer)) {
            // not dragging
            action();
        }
    }

    /*
        Same logic for click v drag - except for touch events
    */
    let link = true;
    const onTDown = () => {
        link = true;
    }
    const onTUp = (_item) => {
        if (link) {
            // not dragging
            action();
        }
    }
    const onTMove = () => {
        link = false;
    }

    return (
        <>
            <div key={key} className="slide" >
                <div onTouchMove={(e) => onTMove(e)} onTouchStart={(e) => onTDown(e)} onTouchEnd={(e) => onTUp(e)} onMouseDown={(e) => onDown(e)} onMouseUp={(e) => onUp(e, e)} style={{ "display": "block" }}>
                    {props.children}
                </div>
            </div>
        </>
    )
}