import { Button, Checkbox, Divider, Form, Input, message, Modal, Select, Space, Tooltip, Typography } from "antd";
import react, { useState, useEffect } from "react";
import { apiCall, apiDownload } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { InfoCircleOutlined } from '@ant-design/icons';

export default function ListCreateExcel(props) {
    const { list_id, close = () => { } } = props;
    const [session, setSession] = useSession();

    const [sharedStores, setSharedStores] = useState([]);
    const initOptions = [
        {
            label: "Include ISBN barcode",
            value: "show_barcodes",
            checked: false,
        },
        {
            label: "Include \"Print Status\" column",
            value: "show_print_status",
            checked: false,
        },
        {
            label: "Include \"My Lists\" column to reference other lists",
            value: "show_my_catalogues",
            checked: false,
        },
        {
            label: "Include comments column",
            value: "show_comments",
            checked: false,
        },
        {
            label: "Include URLs",
            value: "include_urls",
            checked: false,
        },
        {
            label: "Include store data (OnHand, OnOrder, and Biz cart OnOrder only)",
            tool_tip : "This list is only stores who have approved data sharing with you. To view accounts currently sharing data with you, or, to send a data share request, go to the Admin > My Accounts menu.",
            value: "show_ohoo",
            checked: false,
        }
    ]

    const [options, setOptions] = useState(initOptions)

    const store = options.find(item => item.value === "show_ohoo") || { checked: false };

    const onFormFinish = (_f) => {

     
        _f["list_id"] = list_id;
        apiDownload(_f, "titlelist/getExcel");
    }

    const onChange = (_val, _ind) => {
        let _options = [...options];
        _options[_ind].checked = _val;
        setOptions(_options);
    }

    const getSharedStores = () => {
        let api_object = {
            my_accounts: false,
            approved: true,
            pending: false,
            declined: false,
            potential: false,
        }
        apiCall("rep_control/getAll", api_object, (_status, _result) => {
            if (_status) {
                setSharedStores(_result)
            } else {
                message.error("Unable to select stores to work with, please contact Bookmanager to update your permissions/subscriptions")
            }
        })

    }

    useEffect(getSharedStores, [])

    const default_store = (session?.stores_working_with.length>0) ?  session.stores_working_with[0].contact_id : "";
   
    const drawTT = (_t) => {
        if(!_t){return <></>}
        return(<Tooltip title={<small>{_t}</small>}><a><InfoCircleOutlined /></a></Tooltip>)
    }


    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Create Excel</Typography.Title>
                <Divider style={{ "marginBottom": "10px" }} />
                <p>To export this title list to Excel format, choose from the display option(s) below and click "Export"</p>
                <div className="shim" />
                <Form onFinish={(f) => onFormFinish(f)}>
                    <Space direction="vertical">
                        {options.map((item, index) => {
                            return (
                                <Form.Item valuePropName="checked" initialValue={item.checked} name={item.value} noStyle >
                                    <Checkbox onChange={(e) => onChange(!item.checked, index)}  >&nbsp; {item.label} {drawTT(item?.tool_tip)}</Checkbox>
                                </Form.Item>
                            )
                        })}

                        {(store?.checked &&
                            <Space>
                                <div>Select store:</div>
                                <Form.Item required={true} valuePropName="checked" initialValue={default_store} name={"store_data_id"} noStyle >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder={"Select store..."}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        className='ant-select-with-custom-arrow'
                                        defaultValue={default_store}
                                        style={{ width: 250 }}>
                                        {sharedStores.map(item => {
                                            return (
                                                <Select.Option
                                                    key={item.contact_id}
                                                    value={item.contact_id}
                                                    label={item.name}> {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Space>
                        )}
                    </Space>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Export</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    )
}