import { Button, Col, Divider, Layout, message, Row, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { apiCall } from "../utils/Api";

export default function PickListPage(props) {

    const { ee_def_id = "" } = useParams();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true)

    const fetchList = () => {
        setLoading(true);
        apiCall("titlelist/getPicklist", { list_id: ee_def_id }, (_status, _result) => {
            if (_status) {
                _result.tl_misc.forEach((element, ind) => {
                    
                    if (!element.data) {
                        element.data = {}
                    }
                    element.data.binding = _result.isbns[ind].binding; 
                });
                setResults(_result.tl_misc)
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }

    useEffect(fetchList, [])

    const updateCurrent = (_isbn, _amt) => {
        let _res = [...results];
        let _index = _res.findIndex(item => item.isbn === _isbn);
        if (_index > -1) {
            // found
            _res[_index].data.pl_current = _amt;
            setResults(_res);
        }
        return;
    }

    const drawRow = (_row = {}, _index) => {
        let {
            isbn = "",
            eisbn = "",
            data = {}
        } = _row;
        let {
            pl_current = 0,
            pl_class = "",
            pl_title = "",
            pl_author = "",
            pl_availoh = "",
            pl_pickqty = 0,
            pl_price = "",
            binding = ""
        } = data;

        return (
            <div key={_index}>
                <Row gutter={20} key={isbn}>
                    <Col flex={"100px"}>
                        <Space direction="vertical">
                            <img className="shadow-large" style={{ "maxWidth": "100px" }} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/m?b=" + eisbn} />
                            <div style={{ "textAlign": "center" }}>{isbn}</div>
                        </Space>
                    </Col>
                    <Col flex={"230px"}>
                        <Space size={0} direction="vertical">
                            <Space size={6}>
                                <Button disabled={(pl_current >= pl_pickqty)} onClick={() => updateCurrent(isbn, pl_pickqty)}>All</Button>
                                <Button disabled={(pl_current >= pl_pickqty)} onClick={() => updateCurrent(isbn, pl_current + 1)}>+</Button>
                                <Button disabled={(pl_current < pl_pickqty || pl_pickqty === 0)} onClick={() => updateCurrent(isbn, pl_current - 1)}>-</Button>
                                <Button disabled={(pl_current < pl_pickqty || pl_pickqty === 0)} onClick={() => updateCurrent(isbn, 0)}>None</Button>
                            </Space>
                            <div className="shim" />
                            
                            <div>{pl_class}</div>
                            <div style={{ "fontWeight": "600", "lineHeight" : "16px" }}>
                                {pl_title}
                                <div><small><strong>{binding}</strong></small></div>
                            </div>
                            
                            <div><em>{pl_author}</em></div>
                            <div>{pl_availoh} onhand ${pl_price}</div>
                            <div style={{ "fontSize": "24px" }}>
                                <div className="shim" />
                                <span style={{ "color": (pl_current === 0) ? "red" : "inherit" }}>{pl_current}</span> of {pl_pickqty}
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider dashed />
            </div>
        )
    }

    const drawFilters = () => {

        return (
            <>
                <div style={{ "fontSize": "20px", "letterSpacing": "1px" }}>Titlelist Viewer</div>
                <Divider style={{ "marginTop": "15px" }} />
            </>
        )
    }

    const [max, setMax] = useState(20);

    return (
        <Spin spinning={loading}>
            <Layout style={{ "minHeight": "calc(100vh)" }}>
                <div style={{ "padding": "20px" }}>
                    {drawFilters()}
                    {results.slice(0, max).map((item, index) => {
                        return drawRow(item, index);
                    })}
                    {(max < results.length && <Button onClick={() => setMax(max + 20)}>Show more</Button>)}
                </div>
            </Layout>
        </Spin>
    );
}