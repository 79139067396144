/* eslint-disable jsx-a11y/alt-text */
import { UploadOutlined } from '@ant-design/icons';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, Modal, Radio, Spin, Table, Tabs, Tooltip, Typography, Upload } from 'antd';
import React, { useState } from "react";

import { apiCall } from "../../utils/Api";
import DragTable from "../DragTable";
import { getDragIcon } from '../../utils/Utils';

export default function ListUpload(props) {
    const {
        create = false,
        candelete = false,
        drag = false,
        resetSort = () => { },
        setCanSave = () => { },
        canSave = true,
        list_id,
        isbns = [],
        setIsbns = () => { },
        updateList = () => { },
    } = props;

    const [position, setPosition] = useState((create && isbns.length == 0) ? "import" : "list");
    const [uploading, setUploading] = useState(false);
    const [warning, setWarning] = useState(false);
    const [unknown, setUnknown] = useState(1);
    const [file, setFile] = useState({});
    const limit = 100;
    const [form] = Form.useForm();

    const uploadFile = (_file, show_list = true) => {
        setUploading(true);
        setFile(_file);
        apiCall("titlelist/importFile", { file: _file.file, list_id: list_id, only_unique : true }, (_status, _result) => {
            if (_status) {
                if (_result.result.titles.filter(item => !item.success).length > 0) {
                    setWarning(true);
                }
                form.setFieldsValue({ txt: drawText(_result.result.titles) })
                if (show_list) {
                    setPosition("list");
                }
                setIsbns(_result.result.titles)
                updateList(_result.result.titles.map(item => { return item.isbn }));
            } else {
                setPosition("error");
            }
            setUploading(false);
            setCanSave(true);
        }, "customer");
    }

    const placeholder = "Type or paste ISBNs, EANs, or UPCs here. By default the contents will be listed in the order they are inputed, unless otherwise specified in the 'sort by' section below. \n\nexample: \n9780062351357 \n9780062304087 \n9780062063625";

    const removeItem = (f) => {
        let m = [...isbns];
        let _index = m.findIndex(item => item.isbn === f.isbn);
        if (_index > -1) {
            // found
            m.splice(_index, 1);
            setIsbns(m);
            updateList(m.map(item => { return item.isbn }));
        }
    }

    function updateHighlights(_isbn, _checked) {
        const new_highlights = isbns.map(item => {
            if (item.isbn === _isbn) {
                return {
                    ...item,
                    highlight: _checked
                }
            } else {
                return item
            }
        })
        setIsbns(new_highlights);
    }

    const columns = [
        {
            title: <small>Cover</small>,
            dataIndex: 'eisbn',
            key: 'eisbn',
            width: 50,
            render: (e) => {
                return <div
                    style={{ "height": "34px" }}>
                    <img
                        className="lightshadow"
                        onError={(e) => e.target.src = "/no-cover.png"}
                        style={{ "maxHeight": "34px", "maxWidth": "30px" }}
                        src={"https://bookmanager.com/i/slider_im.php?b=" + e} />
                </div>
            }
        },
        {
            title: <small>ISBN</small>,
            dataIndex: 'isbn',
            key: 'isbn',
            width: 150
        },
        {
            title: <small>Title</small>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: <small>Highlight</small>,
            dataIndex: 'highlight',
            key: 'highlight',
            width: 75,
            render: (e, f) => {
                return (
                    <Checkbox
                        defaultChecked={f.highlight}
                        onClick={(e) => updateHighlights(f.isbn, e.target.checked)}
                        checked={e} />)
            }
        },
        {
            title: <small></small>,
            dataIndex: 'success',
            key: 'success',
            render: (e) => {
                return (e === false) ? <span style={{ "color": "#f00" }}>* Title not found</span> : ""
            }
        },
    ];

    if (drag) {
        columns.unshift(
            {
                title: <Tooltip title="Click and drag to move a title around"><small>Drag</small></Tooltip>,
                dataIndex: 'success',
                width: "50px",
                key: 'success',
                render: (e) => {
                    return <span className='dragme' style={{"cursor" : "grab"}}>&nbsp;{getDragIcon()}</span>
                }
            }
        )
    }

    if (candelete) {
        columns.push(
            {
                title: <small></small>,
                dataIndex: 'success',
                width: "80px",
                key: 'success',
                render: (e, f) => {
                    return <div><Button onClick={() => removeItem(f)} danger size="small"><small>Remove</small></Button></div>
                }
            }
        )
    }

    const drawText = (_arr) => {
        let _txt = "";
        _arr.map((item, index) => {
            return { key: index, title: item.title, eisbn: item.eisbn, isbn: item.isbn, status: item.success }
        }).forEach(itm => {
            let title = (itm.status !== false) ? itm.title : "* Title not found";
            _txt += itm.isbn + "       " + title + "\n";
        });
        return _txt;
    }

    const drawTable = () => {
        if (drag) {
            return <DragTable
                callback={(arr) => {
                    resetSort();
                    setIsbns(arr);
                    updateList(arr.map(item => { return item.isbn }));
                }}
                rowKey={"isbn"}
                className="bc"
                scroll={{ y: 255 }}
                pagination={true}
                size="small"
                style={{ "border": "1px solid" }}
                data={isbns}
                setData={setIsbns}
                columns={columns}
            />
        }
        return (
            <Table
                rowKey={"isbn"}
                pagination={
                    (isbns.length <= limit) ? false : { pageSize: limit, showSizeChanger: false }}
                className="bc"
                scroll={{ y: 255 }}
                style={{ "border": "1px solid" }}
                columns={columns}
                dataSource={isbns}
                size="small">
            </Table>
        )
    }

    const generateFile = (_text) => {
        var filename = 'pasted.txt';
        let _file = new File([new Blob([_text])], filename)
        let f = {}
        f.file = _file;
        uploadFile(f, false);
    }


    const moveTitles = () => {
        switch (unknown) {
            case 1:
                let _a =  isbns.filter(item => item.success);
                let _b = isbns.filter(item => !item.success);
                let _both = _b.concat(_a);
                setIsbns(_both)
                updateList(_both.map(item => { return item.isbn }));
                form.setFieldsValue({ txt: drawText(_both) })
                break;
            case 2:
                break;
            case 3:
                let _ret =  isbns.filter(item => item.success);
                setIsbns(_ret)
                updateList(_ret.map(item => { return item.isbn }));
                form.setFieldsValue({ txt: drawText(_ret) })
                break;
            default:
                break;
        }
        setWarning(false);
    }

    const drawWarning = () => {
        return (
            <>
                <Typography.Title level={5}>Unknown titles</Typography.Title>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <div>
                    Your catalogue contains some items that do not exist in our database.
                    <div className='shim' /><div className='shim' /><div className='shim' />
                    <Radio.Group onChange={(e) => setUnknown(e.target.value)} value={unknown}>
                            <Radio value={1}>Place unknown titles at the top</Radio>
                            <Radio value={2}>Leave unknown titles in sequence</Radio>
                            <Radio value={3}>Remove unknown titles</Radio>
                    </Radio.Group>
                    <br /><br />
                    <div className='float-flex'>
                        <div>&nbsp;</div>
                        <Button onClick={() => moveTitles()} type='primary'>Continue</Button>
                    </div>
                </div>
            </>
        )
    }

    const drawUpload = () => {
        return (
            <>
                <Modal width={400} closeIcon={<></>} footer={false} visible={warning}>{drawWarning()}</Modal>
                <div style={{ "height": "296px" }}>
                    <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e)} style={{ "padding": "0px 20px", "height": "300px" }}>
                        <div >
                            {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                        </div>
                        <p className="ant-upload-text">Click or drag to upload</p>
                        <p className="ant-upload-hint">
                            Upload an Excel file (.xls or .xlsx) or tab delimited (.txt) with the header "isbn"
                        </p>
                    </Upload.Dragger>
                </div>
            </>
        )
    }

    const drawProcess = () => {
        return (
            <>
                {drawTable()}
            </>
        )
    }

    const handlePaste = (e) => {
        setTimeout(() => {
            generateFile(e.target.value)
        }, 0);
    };

    return (
        <>
            <Tabs className="itemTabs cattabs" onChange={(e) => setPosition(e)}  activeKey={position} type="card" >
                <Tabs.TabPane tab={"Import ISBNs"} key="import">
                    {drawUpload()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Enter ISBNs Manually" key="text">
                    <Spin spinning={(uploading)}>
                        <Form form={form}>
                            <Form.Item name="txt" noStyle>
                                <Input.TextArea onChange={() => { if (canSave) { setCanSave(false) } }} style={{ "fontFamily": "ApercuMono" }} onPaste={handlePaste} onFocus={() => setCanSave(false)} onBlur={(e) => generateFile(e.target.value)} placeholder={placeholder} defaultValue={drawText(isbns)} rows={13} />
                            </Form.Item>
                        </Form>
                        <div className="shim" />
                        <div><Button disabled={(canSave)} danger type="primary" size="small"><small>Update</small></Button></div>
                        <br clear="all" />
                    </Spin>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"View List (" + isbns.length + " items)"} key="list">
                    {drawProcess()}
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}