/*
    ---- Preferences ----
    Acts like a global varable, accessible from any component
*/

import React from 'react';
let PrefsContext;
let { Provider } = (PrefsContext = React.createContext())
export const usePrefs = () => React.useContext(PrefsContext)


export default function Prefs(props) { 
    // wrap component in a provider. 
    return <Provider value={props.value}>{props.children}</Provider>
}


