import { CloseOutlined, MenuOutlined, PicLeftOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Input, Layout, Radio, Select, Space, Spin, Table, Tooltip } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Bookmark from "../components/Bookmark";
import BrowseFilters from "../components/BrowseFilters";
import ListArchive from "../components/lists/ListArchive";
import ListFav from "../components/lists/ListFav";
import ListFolder from "../components/lists/ListFolder";
import ListItem from "../components/lists/ListItem";
import ListTrash from "../components/lists/ListTrash";
import Paginate from "../components/Paginate";
import Renderer from "../components/Renderer";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import { disabledColor, objectToPath, pathToObject } from "../utils/Utils";

export default function CataloguesPage(props) {

    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({ filters: {}, rows: [], toolboxes: {} });
    const [expanded, setExpanded] = useState([]);
    const [showDetails, setShowDetails] = useState((Cookies.get("categories_show_list") === "true") ? false : true);
    const path = "/catalogues/public-catalogues";
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname, "catalogs_limit"))
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);
    const [filters, setFilters] = useState({});
    const [initLoaded, setInitLoaded] = useState(false);
    const [listLabel, setListLabel] = useState({ key: "", value: "" })
    const [suppliers, setSuppliers] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [effectiveDates, setEffectiveDates] = useState({ seasons: [], years: [] });
    const [cats, setCats] = useState({ categories: [], subcategories: [] })
    const [presetSANLists, setPresetSANLists] = useState([]);

    const getLists = () => { }

    function getBeginningValues() {
        apiCall("titlelist/getEffectiveDates", {}, (_status, _result) => {
            if (_status) {
                setEffectiveDates(_result)
            }
        })
        apiCall("titlelist/getSalesAgencies", {}, (_status, _result) => {
            if (_status) {
                setAgencies(_result)
            }
        })
        apiCall("titlelist/getSuppliers", {}, (_status, _result) => {
            if (_status) {
                setSuppliers(_result)
            }
        })
        apiCall("titlelist/getCurrentCategories", {}, (_status, _result) => {
            if (_status) {
                setCats(_result);
            }
        })
        apiCall("contact_list/getLists", {}, (_status, _result) => {
            if (_status) {
                setPresetSANLists(_result);
            }
        })
    }

    useEffect(getBeginningValues, [])

    const getCatalogues = (_change = false, _obj = searchFilters) => {
        setLoading(true);
        let _filters = { ..._obj };
        _filters.filters = JSON.stringify(_filters.filters);
        apiCall("titlelist/getPublicCatalogues", _filters, (_status, _result) => {
            if (_status) {
                if (_result.row_count === 1) {
                    if (_obj.hasOwnProperty("t")) {
                        if (!isNaN(_obj.t)) {
                            setListLabel({ key: _result.rows[0].actual_id, value: _result.rows[0].name })
                        }
                    }
                }
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                setFilters(_result.filter_info);
                setResults(_result);
                setLoading(false);
                setInitLoaded(true);
            }
        })
    }

    const headerSearch = () => {
        setLoading(true);
        let obj = pathToObject(location.pathname, "catalogs_limit");
        setSearchFilters(obj);
        getCatalogues(false, obj);
    }
    useEffect(headerSearch, [])

    const deleteList = (_list_id) => {
        apiCall("titlelist/delete", { list_id: _list_id }, (_status, _result) => {
            if (_status) {
                // getLists();
                //let res = { ...results };
                //setResults({ ...results, "rows": res.rows.filter(item => item.list_id !== _list_id) })
            }
        })
    }

    const expandCell = (_data) => {
        if (!expanded.includes(_data.list_id)) {
            setExpanded([_data.list_id]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "marginTop": "-4px", "marginLeft": "-8px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <Renderer key={_record.list_id}>
                            <ListItem scroll is_catalogue={true} getLists={getLists} setResults={setResults} results={results} data={_record} />
                        </Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const columns = [
        {
            title: <small>Supplier</small>, width: "55px", dataIndex: "vendor", key: "vendor", render: (e,f) => {
                if(f.vendor !== null){
                    return (<>
                        {f.vendor.acct}
                    </>)
                }
            }
        },
        {
            title: <small>Catalogue name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => expandCell(f)}>{e}</a> : "";
            }
        },
        {
            title: <small>Titles</small>, width: "90px", dataIndex: 'isbns_count', key: 'isbns_count'
        },
        {
            title: <small>Posted</small>, width: "150px", dataIndex: 'date_posted', key: 'date_posted', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div><div style={{ "color": disabledColor }}>
                        </div>
                    </>
                )
            }
        },
        {
            title: <small>Category</small>, dataIndex: 'category', key: 'category', render: (e, f) => {
                return <><div style={{ "color": disabledColor }}><em>{e}</em></div><div style={{ "fontWeight": "500", "maxWidth": "180px", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}>{f.owner_name}</div></>
            }
        },
        {
            title: <small>Season</small>, width:"150px", dataIndex: 'effective_string', key: 'effective_string', render: (e,f) => {
                if(f.effective_type === "custom"){
                    return ""
                }
                return (<nobr>{(e) ? e : ""}</nobr>);
            }
        },
        {
            title: <small>Folder</small>,  width:"90px", dataIndex: 'folder', key: 'folder', render: (e, f) => {
                return (
                    <>
                        {(f.can_edit && 
                            <>
                                <ListFolder getLists={getLists} results={results.rows} setResults={setResults} folder={e} list_id={f.list_id} />
                            </>
                        )}
                        
                    </>
                )
            }
        },
        {
            title: <small><nobr>Bkmrk, Trash, Archive, Fav</nobr></small>, width: "160px", dataIndex: 'folder', key: 'folder', render: (e, f) => {
                return (
                    <Space>
                        <Bookmark link list_id={f.list_id} />
                        {(f.can_edit &&
                            <>
                                <ListTrash list={f} results={results} setResults={setResults} />
                                <ListArchive list={f} results={results} setResults={setResults} />
                                <ListFav list={f} results={results} setResults={setResults} getLists={getCatalogues} />
                            </>
                        )}

                    </Space>
                )
            }
        }
    ]

    const displayGroup = () => {
        return (
            <>
                <Radio.Group size="small" value={showDetails.toString()} onChange={(e) => {
                    Cookies.set("categories_show_list", showDetails);
                    setShowDetails(!showDetails);
                }} buttonStyle="solid">
                    <Radio.Button value="true">
                        <Tooltip title={"Detailed View"}>
                            <small><PicLeftOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                    <Radio.Button value="false">
                        <Tooltip title={"Brief View"}>
                            <small><MenuOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                </Radio.Group>
            </>
        )
    }

    const SearchWithin = () => {

        const [value, setValue] = useState(searchFilters.t)
        return (
            <Input.Search size="small"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onSearch={(e) => {
                    let sf = { ...searchFilters, "t": e, "o": 0 }
                    setSearchFilters(sf);
                    getCatalogues(false, sf);
                    setCurrent(1);
                    history.push({ pathname: path + objectToPath(sf) });
                }} allowClear enterButton placeholder="Enter ISBN, catalogue id or keyword within a catalogue's name" style={{ "width": "407px" }} />

        )
    }

    const drawFilters = () => {
        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "10px 20px", "paddingTop": "0px" }}>
                <Form layout="vertical">
                    <Space wrap size={[5, 0]}>
                        <Form.Item noStyle initialValue={(searchFilters.hasOwnProperty("t") ? searchFilters.t : "")} label={<small>Find a list</small>} name="customer_search">
                            <SearchWithin />
                        </Form.Item>
                        <Form.Item noStyle label={<small>Sort</small>}>
                            <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' size="small" defaultValue={searchFilters.v} onChange={(e) => {
                                let sf = { ...searchFilters, "v": e }
                                getCatalogues(false, sf);
                                history.push({
                                    pathname: path + objectToPath(sf)
                                })
                            }} style={{ width: 130 }}>
                                <Select.Option value=""><small>Date posted</small></Select.Option>
                                <Select.Option value="name"><small>Alphabetical</small></Select.Option>
                                <Select.Option value="date_updated" ><small>Last updated</small></Select.Option>
                            </Select>
                        </Form.Item>
                    </Space>
                    <br clear="both" />
                </Form>
            </div>)
    }

    return (
        <>
            <Layout className="layout" style={{ "minHeight": "500px" }}>
                <LayoutHeader
                    title={<>
                        <div style={{ "float": "right", "marginTop": "-7px" }}>{displayGroup()}</div>
                        Public Catalogues
                    </>}
                    filters={
                        <div style={{ "margin": "0px -20px", "marginTop": "-15px" }}>
                            {(initLoaded && <>
                                <BrowseFilters listLabel={listLabel} getBrowse={getCatalogues} loading={loading} toolboxes={results.toolboxes} header="listcat" path={path} setCurrent={setCurrent} filters={filters} setFilters={setFilters} searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
                                {drawFilters()}
                            </>)}
                        </div>
                    }
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <Paginate save="catalogs_limit" loading={loading} paginate={{ current: current, offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                        setSearchFilters(sf);
                        getCatalogues(false, sf);
                        history.push({ pathname: path + objectToPath(sf) });
                    }} count={results.row_count} />
                    <div className="bc bcg2">
                        <conditional.true value={(!showDetails)}>
                            <Table
                                rowKey="list_id"
                                size="small"
                                columns={columns}
                                loading={loading}
                                className={"fullTable"}
                                dataSource={results.rows}
                                pagination={false}
                                expandable
                                expandedRowKeys={expanded}
                                expandIconColumnIndex={-1}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </conditional.true>
                        <conditional.true value={(showDetails)}>
                            <Spin spinning={loading}>
                                <div style={{ "padding": "20px" }}>
                                    <conditional.true value={(results.rows.length < 1)}>
                                        <Empty description="No results." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </conditional.true>
                                    {results.rows.map((item, index) => {
                                        return (
                                            <div key={item.list_id}>
                                                <Renderer>
                                                    <ListItem
                                                        is_catalogue={true}
                                                        getLists={getCatalogues}
                                                        setResults={setResults}
                                                        results={results}
                                                        data={item}
                                                        effectiveDates={effectiveDates}
                                                        suppliers={suppliers}
                                                        agencies={agencies}
                                                        categories={cats}
                                                        presetSANLists={presetSANLists}
                                                    />
                                                </Renderer>
                                                {(results.rows.length !== index + 1 && <div className="borderBottom shim" style={{ "marginRight": "-20px", "marginLeft": "-20px", "borderBottomWidth": "6px" }} />)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Spin>
                        </conditional.true>
                    </div>
                    <Paginate save="catalogs_limit" loading={loading} paginate={{ current: current, offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                        setSearchFilters(sf);
                        getCatalogues(false, sf);
                        history.push({ pathname: path + objectToPath(sf) });
                    }} count={results.row_count} />
                </Layout.Content>
            </Layout>
        </>
    )

}