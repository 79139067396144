import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Input, Layout, Radio, Result, Select, Space, Spin, Switch, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LockOutlined } from '@ant-design/icons';
import EDILog from "../../components/EDILog.js";
import Paginate from "../../components/Paginate.js";
import { apiCall, apiDownload } from "../../utils/Api";
import conditional from '../../utils/conditional.js';
import { useSession } from '../../utils/Session.js';
import { addCommas, addKeys, bytesToSize, nameSort } from "../../utils/Utils";
import { useHistory } from 'react-router-dom';

export default function EdiActivityPage() {
    const [session, setSession] = useSession();
    const history = useHistory(); 
    const [results, setResults] = useState({
        total_rows: 0,
        rows: [],
        filters: {
            contacts: [], // {name : "", san : "", id : ""}
            file_types: []
        },
        edi_users: [] // {name : "", san : "", id : ""}
    })

    const initFilters = {
        show_which: "all",
        start_date: moment().startOf("day").subtract(7, "day").unix(),
        end_date: moment().startOf("day").add(1, "day").unix(),
        offset: 0,
        limit: 20,
        filename: "",
        trading_with: 0,
        trading_as: 0,
        file_type: "",
        hide_env: false,
        display_details: true,
    };

    const [filters, setFilters] = useState(initFilters);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [initLoaded, setInitLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [ediUsers, setEdiUsers] = useState([]);
    const [filterContacts, setFilterContacts] = useState([]);
    const [defaultUser, setDefaultUser] = useState("all");
    const [error, setError] = useState(false);

    const downloadFile = (_doc_id) => {
        let obj = {
            doc_id: _doc_id
        }
        apiDownload(obj, "edi/download");
    }

    const getActivity = () => {
        setLoading(true);
        apiCall("edi/getActivity", filters, (_status, _results) => {
            if (_status) {
                setEdiUsers(_results.edi_users);
                setFilterContacts(_results.filters.contacts.sort((a, b) => nameSort(a.name, b.name)))
                addKeys(_results.rows);
                setResults(_results);
                setLoading(false);
                defaultEDIUser();
            } else {
                setError(true);
            }
        })
    }

    useEffect(getActivity, [filters]);

    const defaultEDIUser = () => {
        setDefaultUser(session.corp_id);
        setInitLoaded(true);
    }

    // useEffect(defaultEDIUser, [])

    const pageChange = (_page, _limit) => {
        setPageSize(_limit);
        setCurrent(_page);
        let _offset = (_page - 1) * _limit;
        setFilters({ ...filters, "offset": _offset, "limit": _limit });
    }

    const drawShowing = () => {
        let end = current * pageSize;
        let start = (current * pageSize) - (pageSize - 1);
        if (end > results.total_rows) {
            end = results.total_rows;
        }
        if (results.total_rows === 0) {
            return "0 of ";
        }
        return addCommas(start) + " to " + addCommas(end);
    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "margin": "-7px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <div style={{"marginLeft" : "20px", "position" : "absolute", "top" : "3px"}}><small>Log</small></div>
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <EDILog doc_id={_record.doc_id} />
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const expandCell = (_data, _type) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const setTrading = (_san) => {
        let obj = results.edi_users.find(item => item.san === _san);
        if (obj) {
            // change edi user
            setFilters({ ...filters, "trading_as": obj.id, "offset" : 0 });
        } else {
            obj = results.filters.contacts.find(item => item.san === _san);
            if (obj) {
                // change trading with
                setFilters({ ...filters, "trading_with": obj.id, "offset" : 0 });
            } else {
                // gracefully fail
            }
        }
    }

    const columns = [
        { key: "blank", width: "10px" },
        {
            title: <small>To</small>, dataIndex: 'to_name', key: 'to_name', render: (e, f) => {
                return (
                    <>
                        <div>{e}</div>
                        <a onClick={() => setTrading(f.to_san)}>{f.to_san}</a>
                    </>
                )
            }
        },
        {
            title: <small>From</small>, dataIndex: 'from_name', key: 'from_name', render: (e, f) => {
                return (
                    <>
                        <div>{e}</div>
                        <a onClick={() => setTrading(f.from_san)} >{f.from_san}</a>
                    </>
                )
            }
        },
    ]

    //type column
    // in detailed mode:
    // format  = X12 or something that looks like that 
    // doctype = 856 or something
    // together, they make = X12 856, which would be something you'd see in the "File Type" dropdown

    //in summary mode:
    // format  = amount of formats
    // doctype = amount of doctypes
    // So, if you had a list of 3 in detailed, with two different types, X12 856 and X12 810,
    // you would see a format of "1" and doctype of "2", due to this breakdown:
    // 1 format = X12
    // 2 doctypes = 856 and 810

    if (filters.display_details) {
        columns.push({
            title: <small>Type</small>, dataIndex: 'doctype', key: 'doctype', render: (e, f) => {
                return (<nobr>{f.format} {e}</nobr>)
            }
        })
    } else {
        columns.push({
            title: <small>Type</small>, dataIndex: 'doctype', key: 'doctype', render: (e, f) => {
                const return_doctype_string = e > 1 ? e + " types" : e + " type";
                return (<nobr>{return_doctype_string}</nobr>)
            }
        })
    }

    //sent and received columns:
    if (filters.display_details) {
        columns.push({
            title: <small>Sent</small>, dataIndex: 'sent', key: 'sent', render: (e) => {
                return (e) ? <nobr>{moment(e).format("MMM DD, YYYY, h:mm:ss a")}</nobr> : ""
            }
        });
        columns.push({
            title: <small>Received</small>, dataIndex: 'received', key: 'received', render: (e,f) => {
                return (e) ? <nobr>{moment(e).format("MMM DD, YYYY, h:mm:ss a")}</nobr> : (f.error) ? "Error" : "Waiting for download"
            }
        });
    }

    //bytes column:
    if (filters.display_details) {
        columns.push({
            title: <small>Bytes</small>, dataIndex: 'size', key: 'size', render: (e) => {
                return bytesToSize(e);
            }
        })
    } else {
        //need a bytesToSizeNonTruncated(e);
        columns.push({
            title: <small>Bytes</small>, dataIndex: 'size', key: 'size', render: (e) => {
                return bytesToSize(e);
            }
        })
    }

    //filename column:
    if (filters.display_details) {
        columns.push({
            title: <small>Filename</small>, dataIndex: 'filename', key: 'filename', render: (e, f) => {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                return <a onClick={() => downloadFile(f.doc_id)}>{e}</a>;
            }
        })
    } else {
        columns.push({
            title: <small>Filename</small>, dataIndex: 'filename', key: 'filename', render: (e, f) => {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                return <>{e} files</>;
            }
        })
    }

    //ISA column:
    columns.push({
        title: <small>ISA</small>, dataIndex: 'isa', key: 'isa', render: (e, f) => {

            if (filters.display_details) {
                return e;
            }

            let return_string = ""
            if (e > 1) {
                return_string = e + " ISAs";
            } else {
                return_string = e + " ISA";
            }
            return return_string;
        }
    })

    //Log column:
    if (filters.display_details) {
        columns.push({
            title: <small>Log</small>, dataIndex: 'doc_id', key: 'doc_id', render: (e, f) => {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                return <a onClick={() => expandCell(f)}>[Log]</a>;
            }
        })
    }

    const resetSelections = () => {
        setFilters(initFilters)
    }


    const singleUser = (results?.edi_users?.length === 1);



    const drawFilters = () => {
        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "20px 20px 0px 20px" }}>
                <Form size="small" layout="vertical">
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div className="float-flex">
                            <div>EDI Activity</div>
                            <Button onClick={() => getActivity()} size="small" type="primary"><small>Refresh</small></Button>
                        </div>
                    </Typography.Title>
                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                    <Space wrap size={[10, 0]}>
                        <Form.Item label={<small>Date range</small>} >
                            <DatePicker.RangePicker
                                defaultValue={[
                                    moment(filters.start_date * 1000),
                                    moment(filters.end_date * 1000).subtract(1, 'days')
                                ]}
                                disabledDate={(current) => current.isAfter(moment().subtract(1, "day"))}
                                style={{ "width": "250px" }}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters, "start_date": e[0].startOf('day').unix(), "end_date": e[1].endOf('day').unix(), "offset" : 0
                                    })}
                                format="MMM DD, YYYY" />
                        </Form.Item>
                        <Form.Item label={<small>Display</small>}>
                            <Radio.Group value={filters.display_details} onChange={(e) => setFilters({ ...filters, "display_details": e.target.value, "offset" : 0 })} buttonStyle="solid">
                                <Radio.Button value={true}><small>Detail</small></Radio.Button>
                                <Radio.Button value={false}><small>Summary</small></Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={<small>Status</small>} >
                            <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' value={filters.show_which} onChange={(e) => setFilters({ ...filters, "show_which": e, "offset" : 0 })} style={{ width: 90 }}>
                                <Select.Option value="all"><small>Any</small></Select.Option>
                                <Select.Option value="waiting"><small>Waiting</small></Select.Option>
                                <Select.Option value="sent" ><small>Sent</small></Select.Option>
                                <Select.Option value="incoming" ><small>Incoming</small></Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={<small>File type</small>}>
                            <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' value={filters.file_type} onChange={(e) => setFilters({ ...filters, "file_type": e, "offset" : 0 })} style={{ width: 90 }}>
                                <Select.Option key="any" value=""><small>Any</small></Select.Option>
                                {results.filters.file_types.map((item, index) => {
                                    return (<Select.Option key={index} value={item}><small>{item}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={<small>Filename Search</small>}>
                            <Input.Search onSearch={(e) => setFilters({ ...filters, "filename": e, "offset" : 0 })} allowClear enterButton placeholder="Search filename..." style={{ "width": "260px", "fontSize": "12px" }} />
                        </Form.Item>
                        <Form.Item label={<small>Hide 997s / Inv Reqs</small>}>
                            <Switch checked={filters.hide_env} onChange={(e) => setFilters({ ...filters, "hide_env": e, "offset" : 0 })} size="small" />
                        </Form.Item>
                        <Form.Item label={<small>Show only ERRORs</small>}>
                            <Switch checked={filters.only_errors} onChange={(e) => { setFilters({ ...filters, "only_errors": e, "offset" : 0 }) }} size="small" />
                        </Form.Item>
                        <div>
                            <Space size={0}>
                                <Space size={0} style={{ "marginRight": (singleUser) ? "0" : "112px" }}>
                                    <Form.Item hidden={(results.edi_users.length === 1)} label={<small>EDI User (Name)</small>}>
                                        <Select
                                            defaultValue={defaultUser}
                                            getPopupContainer={trigger => trigger.parentElement}
                                            className='ant-select-with-custom-arrow'
                                            // value={filters.trading_as} 
                                            onChange={(e) => setFilters({ ...filters, "trading_as": e, "offset" : 0 })}
                                            style={{ width: 253 }}>
                                            <Select.Option key="all" value={0}><small>All</small></Select.Option>
                                            {results.edi_users.map((item, index) => {
                                                return (<Select.Option key={index} value={item.id}><small>{item.name}</small></Select.Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                    {(!singleUser && <div style={{ "width": "10px" }}>&nbsp;</div>)}
                                    <Form.Item hidden={(results.edi_users.length === 1)} label={<small>EDI User (SAN)</small>}>
                                        <Select
                                            defaultValue={defaultUser}
                                            showSearch
                                            getPopupContainer={trigger => trigger.parentElement}
                                            suffixIcon={<DownOutlined className='ant-select-custom-arrow' />}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            // value={filters.trading_as} 
                                            onChange={(e) => setFilters({ ...filters, "trading_as": e, "offset" : 0 })}
                                            style={{ width: 110 }}>
                                            <Select.Option key="all" value={0}><small>All</small></Select.Option>
                                            {results.edi_users.map((item, index) => {
                                                return (<Select.Option key={index} value={item.id} label={item.san}><small>{item.san}</small></Select.Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Space>
                                <Space>
                                    <Form.Item label={<small>Trading with (Name): </small>}>
                                        <Select
                                            showSearch
                                            getPopupContainer={trigger => trigger.parentElement}
                                            suffixIcon={<DownOutlined className='ant-select-custom-arrow' />}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={filters.trading_with}
                                            onChange={(e) => setFilters({ ...filters, "trading_with": e, "offset" : 0 })}
                                            style={{ width: 238 }}>
                                            <Select.Option key="any" value={0}><small>Any</small></Select.Option>
                                            {filterContacts.map((item, index) => {
                                                return (<Select.Option key={index} value={item.id} label={item.name}><small>{item.name}</small></Select.Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={<small>Trading with (SAN): </small>} >
                                        <Select
                                            showSearch
                                            getPopupContainer={trigger => trigger.parentElement}
                                            suffixIcon={<DownOutlined className='ant-select-custom-arrow' />}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').includes(input)
                                            }
                                            value={filters.trading_with}
                                            onChange={(e) => setFilters({ ...filters, "trading_with": e, "offset" : 0 })}
                                            style={{ width: 110 }}>
                                            <Select.Option key="any" value={0}><small>Any</small></Select.Option>
                                            {[...filterContacts].sort((a, b) => a.san.localeCompare(b.san)).map((item, index) => {
                                                return (<Select.Option key={index} value={item.id} label={item.san}><small>{item.san}</small></Select.Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Space>
                                <div style={{ "width": "10px" }}>&nbsp;</div>
                                <div style={{ "marginTop": "7px" }}><Button type='primary' size='small' onClick={() => { resetSelections() }}><small>Reset Selections</small></Button></div>
                            </Space>
                        </div>
                    </Space>
                </Form>
            </div>
        )
    }

    const setPaginate = (_obj) => {
        setCurrent(_obj.current);
        setFilters({ ...filters, "offset": _obj.start, "limit": _obj.limit });
    }

    const rowClasses = (record) => {

        if (record.error) {
            return "rowHighlightHover defaultCursor errorFile"
        }
        if (record.received === "") {
            return "rowHighlightHover defaultCursor notReceivedFile"
        }
        return "rowHover defaultCursor"
    }

    if (error) {

        return(
            <Layout className="layout">
                    <Layout.Content className="layoutHeader" style={{ "padding": "0px", "borderBottom": "0px" }} >
          
            <>
                <Result
                    icon={<LockOutlined />}
                    title="Sorry, you are not authorized to access this page."
                    extra={
                        <Button onClick={() => history.push("/")} type="primary" key="console">
                            Home
                        </Button>
                    }
                />
            </>
        </Layout.Content>
        </Layout>
        )
    }

    return (
        <>
            <conditional.true value={!initLoaded}>
                <Layout className="layout">
                    <Layout.Content className="layoutHeader" style={{ "paddingTop": "150px", "textAlign": "center" }} >
                        <Spin
                            size="large"
                            tip="Retrieving EDI Activity... please wait"
                            style={{ "minHeight": "200px" }}
                            spinning={!initLoaded}></Spin>
                    </Layout.Content>
                </Layout>
            </conditional.true>
            <conditional.true value={initLoaded}>
                <Layout className="layout">
                    <Layout.Content className="layoutHeader" style={{ "padding": "0px", "borderBottom": "0px" }} >
                        <div style={{ "padding": "0px 0px" }}>
                            {drawFilters()}
                        </div></Layout.Content>
                    <Divider style={{ "margin": "0px" }} />
                    <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                        <div style={{ "padding": "0px 0px" }}>
                            <Paginate
                                bytes_count={results.total_size}
                                setPaginate={(e) =>
                                    setPaginate({ current: e.current, start: e.offset, limit: e.pagesize })
                                }
                                count={results.total_rows}
                                paginate={{ current: (filters.offset===0) ? 1 : current, pagesize: filters.limit, offset: filters.offset }} />
                            <div style={{ "margin": "0 0px" }}>
                                <Table
                                    size="small"
                                    pagination={false}
                                    loading={loading}
                                    columns={columns}
                                    dataSource={results.rows}
                                    expandable
                                    rowClassName={(record, index) => rowClasses(record)}
                                    expandedRowKeys={expanded}
                                    expandIconColumnIndex={-1}
                                    onExpand={(e) => { return false; }}
                                    expandIcon={() => { return (<></>) }}
                                    expandedRowRender={((record) => drawChildTable(record))}
                                />
                            </div>
                            <Paginate
                                setPaginate={(e) =>
                                    setPaginate({ current: e.current, start: e.offset, limit: e.pagesize })
                                }
                                count={results.total_rows}
                                paginate={{ current: (filters.offset===0) ? 1 : current, pagesize: filters.limit, offset: filters.offset }} />                    </div>
                    </Layout.Content>
                </Layout>
            </conditional.true>
        </>
    )
}